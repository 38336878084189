import {
  Button as MDBBtn,
  Col as MDBCol,
  Row as MDBRow,
} from "react-bootstrap";
import React from "react";
import ReactTooltip from "react-tooltip";

const CountSection = ({
  setLearnState,
  learnState,
  checkInputColor,
  handleAgain,
}) => {
  return (
    <div
      style={
        learnState.hideAll || learnState.hideReady
          ? { position: "fixed", top: 10, left: 0, right: 0 }
          : {}
      }
    >
      <MDBRow>
        <MDBCol xs={"12"}>
          <div className="left_count">
            <div
              className={
                learnState.hideAll || learnState.hideReady
                  ? "opa_hide custom-control custom-switch"
                  : "opa_show custom-control custom-switch"
              }
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitches"
                checked={learnState.enableColor}
                onChange={() => {
                  setLearnState({
                    ...learnState,
                    enableColor: !learnState.enableColor,
                  });
                  checkInputColor(!learnState.enableColor);
                }}
                readOnly
              />
              <label className="custom-control-label" htmlFor="customSwitches">
                COLORS
              </label>
            </div>
          </div>
        </MDBCol>
        <MDBCol
          xs={"12"}
          className="d-flex align-items-center justify-content-center flex-wrap"
        >
          <ReactTooltip place="bottom" type="dark" effect="solid" />
          <MDBBtn
            variant="outline-dark"
            className="border-0 text-dark shadow-0 bg-transparent px-4"
            onClick={(e) => {
              handleAgain();
            }}
            disabled={learnState.perfect && learnState.hideReady}
            data-tip={`Flashing Time: ${(learnState.countsec / 1000).toFixed(1)}
            second.`}
          >
            Flash {learnState.count}
          </MDBBtn>

          <MDBBtn
            variant="outline-dark"
            className="border-0 text-dark shadow-0 bg-transparent px-4"
            href="/"
            data-tip="Quit & Go to the Main Screen"
          >
            Quit
          </MDBBtn>
        </MDBCol>
        <MDBCol xs={"3"}>
          <div className="float-right right_count">
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
export default CountSection;
