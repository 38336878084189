const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA';
const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';

const ADD_DASHBOARD_DATA_REQUEST = 'ADD_DASHBOARD_DATA';
const ADD_DASHBOARD_DATA_SUCCESS = 'ADD_DASHBOARD_DATA_SUCCESS';
const ADD_DASHBOARD_DATA_ERROR = 'ADD_DASHBOARD_DATA_ERROR';

const UPDATE_DASHBOARD_DATA_REQUEST = 'UPDATE_DASHBOARD_DATA';
const UPDATE_DASHBOARD_DATA_SUCCESS = 'UPDATE_DASHBOARD_DATA_SUCCESS';
const UPDATE_DASHBOARD_DATA_ERROR = 'UPDATE_DASHBOARD_DATA_ERROR';

const DELETE_DASHBOARD_DATA_REQUEST = 'DELETE_DASHBOARD_DATA';
const DELETE_DASHBOARD_DATA_SUCCESS = 'DELETE_DASHBOARD_DATA_SUCCESS';
const DELETE_DASHBOARD_DATA_ERROR = 'DELETE_DASHBOARD_DATA_ERROR';

const GET_SINGLE_DASHBOARD_DATA_REQUEST = 'GET_SINGLE_DASHBOARD_DATA';
const GET_SINGLE_DASHBOARD_DATA_SUCCESS = 'GET_SINGLE_DASHBOARD_DATA_SUCCESS';
const GET_SINGLE_DASHBOARD_DATA_ERROR = 'GET_SINGLE_DASHBOARD_DATA_ERROR';

export {
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,

  ADD_DASHBOARD_DATA_REQUEST,
  ADD_DASHBOARD_DATA_SUCCESS,
  ADD_DASHBOARD_DATA_ERROR,

  UPDATE_DASHBOARD_DATA_REQUEST,
  UPDATE_DASHBOARD_DATA_SUCCESS,
  UPDATE_DASHBOARD_DATA_ERROR,

  DELETE_DASHBOARD_DATA_REQUEST,
  DELETE_DASHBOARD_DATA_SUCCESS,
  DELETE_DASHBOARD_DATA_ERROR,

  GET_SINGLE_DASHBOARD_DATA_REQUEST,
  GET_SINGLE_DASHBOARD_DATA_SUCCESS,
  GET_SINGLE_DASHBOARD_DATA_ERROR,
};