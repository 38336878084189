import React, { useEffect, useRef, useState } from "react";

const QuestionSection = ({ sentences, sentenceIndex, fontSize, bigSize }) => {
  const textLength = (
    (sentences[sentenceIndex] && sentences[sentenceIndex].sentence) ||
    ""
  ).length;
  const initialFontSize = 6.666666666666667 - textLength / 16;

  const [textSize, setTextSize] = useState(initialFontSize);

  const textRef = useRef();

  const adjustFontSize = () => {
    const container = textRef.current;

    setTextSize(initialFontSize);

    const containerRect = container.getBoundingClientRect();
    const scaleFactor = Math.min(
      window.innerWidth / containerRect.width,
      window.innerHeight / containerRect.height
    );

    setTextSize(initialFontSize * scaleFactor);
  };

  useEffect(() => {
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [sentenceIndex, sentences]);
  return (
    <h5
      ref={textRef}
      className="text-center m-0 text-break mb-15 font-weight-normal"
      style={{
        fontSize: bigSize
          ? `${textSize}vw`
          : `max(min(${fontSize}rem, ${6.67}rem), ${3.5}rem)`,
        // `max(min(${
        //   bigSize ? fontSize * window.innerWidth : fontSize
        // }rem, ${bigSize ? window.innerWidth / 100 : 6.67}rem), ${
        //   bigSize ? 7 : 3.5
        // }rem)`,
        // `max(min(${
        //   bigSize ? fontSize * 2 : fontSize
        // }rem, 6.67rem), 3.5rem)`,
        transform: "translateY(-27px)",
      }}
    >
      {sentences[sentenceIndex] && sentences[sentenceIndex].sentence}
    </h5>
  );
};

export default QuestionSection;
