import { FormControl as MDBInput } from "react-bootstrap";
import React from "react";

export default function InputBox({ handleChange, inputValue, ...props }) {
  return (
    <MDBInput
      as="textarea"
      rows={5}
      style={{ resize: "none", overflowY: "auto" }}
      placeholder="Paste any text that you want to learn"
      className="text-center bodyColor rounded-3 border border-dark shadow-none text-dark placeholder-text p-4"
      value={inputValue}
      onChange={handleChange}
    />
  );
}
