import Logo from "@utils/Logo";
import React from "react";
import { Link } from "react-router-dom";

const MenuButton = ({ size, isMain, ...props }) => {
  return (
    <div>
      <Link to="/">
        <div
          className={"bg-black  mx-auto"}
          style={{
            width: size || "100px",
            height: size || "100px",
            borderRadius: "50%",
          }}
        >
          <Logo size={size || 100} color={"var(--bgColor)"} isMain={isMain} />
        </div>
      </Link>
    </div>
  );
};

export default MenuButton;
