const FlashSection = ({ opacity }) => {
  return (
    <>
      <div
        className="animate position-fixed top-0 start-0 w-100 vh-100 bodyColor d-flex justify-content-center align-items-center fadeAnimation"
        style={{
          zIndex: 10,
          transition: "all .3s ease-in-out",
          opacity,
          visibility: opacity === 0 ? "hidden" : "visible",
        }}
      >
        <div
          className="dotLoader reversed blinkAnimation"
          style={{
            "--size": "300px",
          }}
        >
          <h1 className="text-dark flash-logo-text">
            do
            <br />
            not
            <br />
            blink
          </h1>
        </div>
      </div>
    </>
  );
};
export default FlashSection;
