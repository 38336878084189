import { SET_SENTENCES, SET_MODE, SET_VISIBILITY, SET_ID, SET_AUTHOR } from './actionTypes'

export const setSentences = sentences => ({
  type: SET_SENTENCES,
  payload: {
    sentences
  }
})
export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode
  }
})
export const setVisibility = visible => ({
  type: SET_VISIBILITY,
  payload: {
    visible
  }
});

export const setId = id => ({
  type: SET_ID,
  payload: {
    id
  }
});
export const setIsAuthor = isAuthor => ({
  type: SET_AUTHOR,
  payload: {
    isAuthor
  }
});