import React, { useState, useEffect } from "react";
import {
  Navbar as MDBNavbar,
  NavbarBrand as MDBNavbarBrand,
} from "react-bootstrap"
import { Link } from "react-router-dom";

export const Navbar = (props) => {
  let { hideAll, quit } = props;
  let [openNav] = useState(false);
  let [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (quit && !hideAll) {
      setShowNav(true);
    }
  }, [hideAll, quit]);

  return (
    <>
      <MDBNavbar
        variant="dark"
        expand="md"
        fixed="top"
        className={hideAll || showNav ? "opa_hide" : "opa_show"}
        style={{ position: "relative", display: "flex", justifyContent: "center" }}
      >
        <MDBNavbarBrand>
          <Link to="/" style={{ color: "#333333", fontWeight: 600 }}>
            DO NOT BLINK <span style={{ fontSize: "0.6em" }}>v0.5</span>
          </Link>
        </MDBNavbarBrand>
      </MDBNavbar>
      {/* side navbar */}
      {openNav ? (
        <div className="container">
        </div>
      ) : null}
    </>
  );
};
