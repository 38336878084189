import { AlertMessage } from "@components/Alert";
import { setSentences } from "@store/sentences/action";

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const blinkAnimation = async (e) => {
  // console.log(e.currentTarget);
  let taregtBtn = e.currentTarget;
  taregtBtn.classList.add("blinkAnimation");
  await sleep(300);
  if (taregtBtn.classList.contains("blinkAnimation")) {
    taregtBtn.classList.remove("blinkAnimation");
  }
};

export const organizeAlphabetically = (array = []) => array.sort();

export const organizeByLength = (array = []) =>
  array.sort((a, b) => a.length - b.length);

export const shuffle = (array = []) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const debounce = (func, wait = 750) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(args);
    }, wait);
  };
};

export const tips = `
use your keyboard only
do it slowly
press F11 for fullscreen
do not blink
detail matters
repeat the same set more than once
think about your mistakes
try to memorize the text
use your mouth to learn speaking, too
press F11 to better focus on full screen
`
  .trim()
  .split("\n");

let prevTip = "";
export const getRandomElementFromArray = (array) => {
  let tip = array[Math.floor(Math.random() * array.length)];
  if (tip === prevTip) {
    return getRandomElementFromArray(array);
  }
  prevTip = tip;
  return tip;
};

// global hepler to split thesenteces
export const splitSentences = ({
  sentence,
  dispatch,
  id,
  setId,
  navigate,
  path,
}) => {
  let code = sentence;
  if (code.trim().length === 0) {
    AlertMessage({ message: "Please add some text or paste it" });
    return;
  }

  let sentences = [];
  let signs = ["\n", "?", ".", "!"];
  for (let j = 0; j < code.length; j++) {
    const letter = code[j];
    if (signs.includes(letter)) {
      const index = code.indexOf(letter) + 1;
      const newSentence = code.slice(0, index);
      let removeExtraSigns = newSentence;
      for (let i = index; i < code.length; i++) {
        if (signs.includes(code[i])) {
          removeExtraSigns += code[i];
        } else {
          break;
        }
      }
      j = 0;
      code = code.replace(removeExtraSigns, "");
      if (newSentence.trim()) {
        sentences.push({
          sentence: newSentence.replace(/\s{2,}/g, " ").trim(),
          mastered: null,
          tried: false,
        });
      }
    } else if (j === code.length - 1) {
      const newSentence = code.slice(0, code.length);
      if (newSentence.trim()) {
        sentences.push({
          sentence: newSentence.replace(/\s{2,}/g, " ").trim(),
          mastered: null,
          tried: false,
        });
      }
    }
  }
  dispatch(
    setSentences(
      sentences.map((e) => {
        let sentence = e.sentence;
        sentence = sentence
          .replaceAll("‘", "'")
          .replaceAll("’", "'")
          .replaceAll("‛", "'")
          .replaceAll("`", "'")
          .replaceAll("“", '"')
          .replaceAll("”", '"')
          .replaceAll("‟", '"')
          .replaceAll("„", '"');
        return { ...e, sentence };
      })
    )
  );

  if (id) {
    dispatch(setId(id));
  }
  navigate.push(path);
};
function generate(n) {
  var add = 1,
    max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

  if (n > max) {
    return generate(max) + generate(n - max);
  }

  max = Math.pow(10, n + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ("" + number).substring(add);
}

export const generateArrayOfNumbers = (n) =>
  Array(5)
    .fill()
    .map(() => {
      return { sentence: generate(n).toString() };
    });
export const generateArrayOfWords = (words) =>
  Array(5)
    .fill()
    .map(() => {
      return {
        sentence: words[Math.floor(Math.random() * (words.length - 0 + 1) + 0)],
      };
    });
