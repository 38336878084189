import React, { useEffect, useState } from "react";
import { Header } from "@components/Header";
import { MDBCol } from "mdb-react-ui-kit";
import { MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import userActions from "@store/auth/actions";
import { useNavigate } from "react-router-dom";
import { Loader } from "@components/loader";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import BlinkAnimatedButton from "@components/BlinkAnimatedButton";
import {
  Col,
  Row,
  Button as MDBBtn,
  Modal as MDBModal,
  ModalBody as MDBModalBody,
  ModalFooter as MDBModalFooter,
  ModalHeader as MDBModalHeader,
} from "react-bootstrap";
import { blinkAnimation } from "@helpers/index";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";

const socialPlatforms = [
  { name: "facebook", value: "facebook" },
  { name: "twitter", value: "twitter" },
  { name: "instagram", value: "instagram" },
  { name: "tiktok", value: "tiktok" },
  { name: "pinterest", value: "pinterest" },
  { name: "snapchat", value: "snapchat" },
  { name: "youtube", value: "youtube" },
];

const ProfilePage = () => {
  const { user, loading } = useSelector((s) => s.auth);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);

  const [profileData, setProfileData] = useState({
    name: "",
    description: "",
    socialLink: {
      platform: "",
      name: "",
    },
  });

  const toggleModel = () => {
    setShowModel(!showModel);
  };

  useEffect(() => {
    setProfileData({
      name: user?.name || "",
      description: user?.description || "",
      socialLink: {
        platform: user?.socialLink?.platform || socialPlatforms[0].value,
        name: user?.socialLink?.name || "",
      },
    });

    setIsLoading(false);
  }, [user]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleSocialLinkChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      socialLink: { ...profileData.socialLink, [name]: value },
    });
  };

  const handleSubmit = async () => {
    if (
      !profileData.name ||
      !profileData.description ||
      !profileData.socialLink.name ||
      !profileData.socialLink.platform
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    console.log("profileData", profileData);
    dispatch(userActions.updateUser(profileData));
  };

  const handleDeleteAccount = () => {
    toggleModel();
    dispatch(
      userActions.deleteAccount({
        callback: () => {
          navigate("/");
        },
      })
    );
  };
  return (
    <>
      {isLoading ? (
        <Loader isStatic />
      ) : (
        <HeaderFooterLayout>
          {/* <NavbarHeader /> */}
          <MDBRow className="justify-content-center mb-4 py-5">
            <MDBCol sm="6" md="8" lg={6}>
              <Header
                link={"/app"}
                title="EDIT YOUR ACCOUNT"
                tagline={"REGULAR ACCOUNT"}
              />

              <Row className="g-3">
                <Col xs={12}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="custom-input w-100 bg-transparent form-control border-dark text-dark shadow-none fst-italic text-center"
                    placeholder="name*"
                    required
                    value={profileData.name}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs={12}>
                  <div
                    data-tip="You can add your social link here"
                    className="d-flex align-items-center justify-content-center border border-dark rounded-3 px-3"
                  >
                    <span className="fst-italic">https://</span>
                    <select
                      id="socialPlatform"
                      className="custom-select bg-transparent border-0 text-dark shadow-none fst-italic py-0 "
                      style={{
                        width: "max-content",
                      }}
                      name="platform"
                      value={profileData.socialLink.platform}
                      onChange={handleSocialLinkChange}
                      required
                    >
                      {socialPlatforms.map((platform, index) => (
                        <option value={platform.value} key={index}>
                          {platform.name}
                        </option>
                      ))}
                    </select>
                    <span className="fst-italic">.com/</span>
                    <input
                      type="text"
                      className="custom-input form-control bg-transparent text-dark shadow-none fst-italic text-start px-0 border-0"
                      name="name"
                      id="socialLinkName"
                      placeholder="userName"
                      aria-label="socialLinkName"
                      value={profileData.socialLink.name}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s/g, "");
                        handleSocialLinkChange(e);
                      }}
                      required
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <textarea
                    id="description"
                    name="description"
                    className="custom-input w-100 bg-transparent form-control border-dark text-dark shadow-none fst-italic text-center"
                    rows={5}
                    placeholder="description"
                    required
                    value={profileData.description}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} className="text-center">
                  <div className="d-flex flex-column justify-content-center align-items-center gap-4">
                    <BlinkAnimatedButton
                      title={loading || isLoading ? "loading..." : "Update"}
                      pressed={handleSubmit}
                      disabled={loading || isLoading}
                    />

                    <button
                      className="btn btn-link text-decoration-none"
                      disabled={loading || isLoading}
                      onClick={() => {
                        dispatch(
                          userActions.signOut({
                            callback: () => {
                              navigate("/");
                            },
                          })
                        );
                      }}
                    >
                      logout
                    </button>
                    <button
                      className="btn btn-link text-decoration-none fst-italic"
                      disabled={loading || isLoading}
                      onClick={toggleModel}
                    >
                      delete your account
                    </button>
                  </div>
                </Col>
              </Row>
            </MDBCol>
          </MDBRow>
          {/* delete account model */}
          <MDBModal show={showModel} onHide={toggleModel} centered>
            <MDBModalHeader className="text-success">
              Account Delete Alert
            </MDBModalHeader>
            <MDBModalBody>
              <p className="mb-0">
                Are you sure you want to delete your account?
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <div className="d-flex justify-content-end align-items-center gap-3">
                <MDBBtn
                  variant="dark"
                  className="rounded-pill text-lowercase px-4 text-light"
                  data-mdb-ripple-duration="0"
                  data-title="Delete"
                  onClick={async (e) => {
                    await blinkAnimation(e);
                    handleDeleteAccount();
                  }}
                >
                  Delete
                </MDBBtn>

                <MDBBtn
                  variant="outline-dark"
                  className="rounded-pill text-lowercase px-4"
                  data-title="Cancel"
                  data-mdb-ripple-duration="0"
                  onClick={async (e) => {
                    await blinkAnimation(e);
                    toggleModel();
                  }}
                >
                  cancel
                </MDBBtn>
              </div>
            </MDBModalFooter>
          </MDBModal>
        </HeaderFooterLayout>
      )}
    </>
  );
};

export default ProfilePage;
