import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Sentence from "./Sentence";

export default function ScrollSentances({
  reqParams,
  getData,
  isPublicSet,
  isBigCollection,
}) {
  return (
    <div className="content">
      <div
        style={{
          maxHeight: `250px`,
          overflow: `auto`,
        }}
        id="scrollableDiv"
        className="container"
      >
        <InfiniteScroll
          hasMore={reqParams.hasMore}
          next={getData}
          scrollThreshold={1}
          dataLength={reqParams.sentences.length}
          hasChildren={reqParams.sentences.length > 0}
          scrollableTarget="scrollableDiv"
          loader={
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          }
          endMessage={
            <p className="text-center mt-5 font-monospace font-weight-bold">
              {reqParams.sentences.length === 0
                ? "No sets available"
                : "No more sets"}
            </p>
          }
          className="mx-auto overflow-hidden"
        >
          {reqParams.sentences.map((sentence, index) => (
            <Sentence
              key={index}
              publicSentence={isPublicSet}
              isBigCollection={isBigCollection}
              sentence={sentence}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}
