import React, { useCallback, useEffect, useState } from "react";
import firebase from "@config/firebaseConfig";
import { toast } from "react-hot-toast";
import ScrollSentances from "@components/InputComponent/publicSetsComponents/ScrollSentances";

// import { set } from "react-hook-form";
const LIMIT_OF_SENTENCES = 8;

export const PublicCollectionSetComponent = ({ collection, ...props }) => {
  const [reqParams, setReqParams] = useState({
    hasMore: true,
    lastItem: false,
    sentences: [],
  });
  const getData = useCallback(async () => {
    let query = firebase
      .firestore()
      .collection("sets")
      .where("collectionName", "==", collection.id);

    // query = query.orderBy("title");

    // query = query.orderBy("startCount", "desc");
    if (reqParams.lastItem) {
      query = query.startAfter(reqParams.lastItem);
    }

    try {
      const snapshot = await query.limit(LIMIT_OF_SENTENCES).get();
      const sentences = snapshot.docs.map((doc) => {
        console.log("Set doc", doc.data());
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      console.log("sentences", sentences);
      setReqParams((reqParams) => ({
        ...reqParams,
        sentences: [...reqParams.sentences, ...sentences].filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        ),
        lastItem: snapshot.docs.at(-1),
        hasMore: snapshot.docs.length === LIMIT_OF_SENTENCES,
      }));
    } catch (err) {
      toast.error(err.message);
      console.log("Error", err.message);
    }
  }, [collection.value]);

  // trying to handle useEffect
  useEffect(() => {
    setReqParams({
      ...reqParams,
      lastItem: false,
      sentences: [],
      hasMore: true,
    });
    const timerId = setTimeout(() => {
      getData();
    }, 750);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      <ScrollSentances
        isPublicSet={true}
        reqParams={reqParams}
        getData={getData}
      />
    </>
  );
};
