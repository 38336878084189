import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSentences } from "@store/sentences/action";

import { useNavigate } from "react-router-dom";
import InputBox from "./InputBox";
import BlinkAnimatedButton from "../BlinkAnimatedButton";
import "./input.css";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { splitSentanceFun } from "@utils/splitSentance";
const DEFAULT_TEXT = "";
export default function InputComponent() {
  const { sentences } = useSelector((state) => state.sentences);
  const { isAuth } = useSelector((state) => state.auth);

  // App url Qoute
  useEffect(() => {
    let urlSearchParams = new URLSearchParams(window.location.search).get(
      "sentances"
    );
    if (urlSearchParams) {
      const searchParamsArray = urlSearchParams.split("/n");
      searchParamsArray.forEach((data) => {
        if (data) {
          console.log(`Sentance => ${data}`);
          sentences.push({
            sentence: data,

            mastered: null,
            tried: false,
          });
        }
      });
    }
  }, [sentences]);
  // hooks
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState(DEFAULT_TEXT);
  // const [breakText, setBreakText] = useState(true);
  const [limitExceded, setLimitExceded] = useState(false);
  useEffect(() => {
    console.log("App Page Sentences", sentences);
    if (isAuth) {
      setInputValue(
        sentences.length > 0
          ? sentences.map((e) => e.sentence).join("\n")
          : DEFAULT_TEXT
      );

      if (sentences.length > 1000) {
        setLimitExceded(true);
        return;
      } else {
        setLimitExceded(false);
      }
    } else {
      setInputValue(DEFAULT_TEXT);
    }
  }, [sentences]);
  const dispatch = useDispatch();
  const splitSentences = (route = "/edit-set") => {
    if (inputValue.length === 0) {
      toast.error("Please enter some text to learn");
      return;
    }

    let sentences = [];
    // if (breakText) {
    //   sentences = splitSentanceFun(inputValue);
    // } else {
    //   sentences = inputValue.split("\n");
    // }
    sentences = inputValue.split("\n");

    // console.log("sentences", sentences);
    console.log("sentences length", sentences.length);
    if (sentences.length > 1000) {
      toast.error("Maximum 1000 sentences allowed");
      setLimitExceded(true);
      return;
    } else {
      setLimitExceded(false);
    }

    sentences = sentences
      .filter((el) => el.trim())
      .map((e) => {
        console.log("sentance", e);
        return {
          sentence: e.replace(/\s{2,}/g, " ").trim(),
          mastered: null,
          tried: false,
        };
      });
    dispatch(setSentences(sentences));
    console.log("sentences", sentences);
    navigate(route);
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  // const handleBreak = () => {
  //   setBreakText(!breakText);
  // };

  return (
    <>
      <div className="text-center position-relative">
        {limitExceded && (
          <p
            className="fw-semibold text-body m-0 position-absolute end-0"
            style={{
              top: "-2rem",
            }}
          >
            limit 1000 lines
          </p>
        )}
        <InputBox
          placeholder={DEFAULT_TEXT}
          handleChange={handleChange}
          inputValue={inputValue}
        />
        {/* 'learn it' button */}
        <BlinkAnimatedButton
          title="Continue"
          className="mt-4"
          pressed={() => splitSentences()}
        />

        <p
          className="mb-0 mt-3 cursor-pointer"
          onClick={() => {
            splitSentences("/slides");
          }}
        >
          present now
        </p>
        {/* <button
          className=" btn fst-italic outline-none border-0 fs-12px"
          onClick={handleBreak}
          style={{
            opacity: breakText ? 1 : 0.5,
            fontWeight: 500,
          }}
        >
          break the text
        </button> */}
      </div>
      {/* lower part of main screen */}
    </>
  );
}
