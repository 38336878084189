import { toast } from 'react-hot-toast';
import * as constants from './constants';

const initialState = {
  dashboard: {},
  loading: {},
  errors: {},
};

const dashboardReducer = (state = initialState, action) => {
  state.dashboard[action.name] = state.dashboard[action.name] || [];
  switch (action.type) {
    case constants.GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: true,
        },
      };
    case constants.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        dashboard: {
          ...state.dashboard,
          [action.name]: action.payload,
        },
      };
    case constants.GET_DASHBOARD_DATA_ERROR:
      toast.error(action.payload.message);
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        errors: {
          ...state.errors,
          [action.name]: action.payload,
        },
      };
    case constants.ADD_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: true,
        },
      };
    case constants.ADD_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        dashboard: {
          ...state.dashboard,
          [action.name]: [...state.dashboard[action.name], action.payload],

        },
      };
    case constants.ADD_DASHBOARD_DATA_ERROR:
      toast.error(action.payload.message);
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        errors: {
          ...state.errors,
          [action.name]: action.payload,
        },
      };
    case constants.UPDATE_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: true,
        },
      };
    case constants.UPDATE_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        dashboard: {
          ...state.dashboard,
          [action.name]: state.dashboard[action.name].map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
              }
              return item;
          }),

        },
      };
    case constants.UPDATE_DASHBOARD_DATA_ERROR:
      toast.error(action.payload.message);
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        errors: {
          ...state.errors,
          [action.name]: action.payload,
        },
      };
    case constants.DELETE_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: true,
        },
      };
    case constants.DELETE_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        dashboard: {
          ...state.dashboard,
          [action.name]: state.dashboard[action.name].filter(
            (item) => item.id !== action.payload.id
            ),
        },
      };
    case constants.DELETE_DASHBOARD_DATA_ERROR:
      toast.error(action.payload.message);
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.name]: false,
        },
        errors: {
          ...state.errors,
          [action.name]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;