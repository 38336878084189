import React, { useCallback, useEffect, useState } from "react";
import { Header } from "@components/Header";
import { MDBCol } from "mdb-react-ui-kit";
import { MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "@components/loader";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import BlinkAnimatedButton from "@components/BlinkAnimatedButton";
import { Col, Container, Row } from "react-bootstrap";
import { lanuageArr } from "@helpers/lanuages";
import toast from "react-hot-toast";
import firebase from "@config/firebaseConfig";
import collectionActions from "@store/collections/action";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";

const firestore = firebase.firestore;

const AddCollectionPage = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { uid, isAdmin } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [collectionId, setCollectionId] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { collections } = useSelector((state) => state.collections);

  const [collectionData, setCollectionData] = useState({
    title: "",
    description: "",
    language: "",
    type: "private",
    isEditAble: true,
    isBig: false,
  });

  const getCollection = useCallback(async () => {
    const selectedCollection = collections.filter(
      (collection) => collection.id === collectionId
    );
    console.log("selectedCollection", selectedCollection);

    setCollectionData({
      title: selectedCollection[0].name,
      description: selectedCollection[0].description,
      language: selectedCollection[0].language,
      type: selectedCollection[0].type,
      isEditAble: selectedCollection[0].isEditAble ?? true,
      isBig: selectedCollection[0].isBig ?? false,
    });

    setIsLoading(false);
    setIsDataLoading(false);
    // await firestore()
    //   .collection("collections")
    //   .doc(collectionId)
    //   .get()
    //   .then((res) => {
    //     console.log("Collection Data", res.data());
    //     setCollectionData({
    //       title: res.data().name,
    //       description: res.data().description,
    //       language: res.data().language,
    //       type: res.data().type,
    //       isEditAble: res.data().isEditAble ?? true,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("Error in get collection", err);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     setIsDataLoading(false);
    //   });
  }, [collectionId, collections]);

  useEffect(() => {
    const collectionId = location.pathname.split("/collections/edit/")[1];
    if (collectionId) {
      setCollectionId(collectionId);
      setIsEditing(true);
      setIsLoading(false);
      getCollection();
    } else {
      setIsEditing(false);
      setIsLoading(false);
      setIsDataLoading(false);
    }
  }, [getCollection, location.pathname]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setCollectionData({ ...collectionData, [name]: value });
  };

  const handleSubmit = async () => {
    const errors = [];
    Object.keys(collectionData).forEach(function (key) {
      if (key === "isBig") return;
      if (!collectionData[key]) {
        errors.push(key);
        return;
      }
    });
    if (errors.length > 0) {
      toast.error(`Please fill ${errors.join(", ")} fields`);
      return;
    } else {
      console.log("collection Data", collectionData);
      await addNewCollection();
    }
  };

  const addNewCollection = async () => {
    setIsLoading(true);
    let myCollection = {
      name: collectionData.title,
      value: collectionData?.title.replaceAll(" ", "-").toLowerCase(),
      createdBy: uid,
      createdAt: firestore.FieldValue.serverTimestamp(),
      description: collectionData.description,
      language: collectionData.language,
      type: collectionData.type,
      isEditAble: collectionData.isEditAble,
      isBig: collectionData.isBig,
    };
    console.log("My Initial Collection Data", myCollection);
    if (isEditing) {
      console.log("Editing Function Calling");
      dispatch(
        collectionActions.updateCollectionData(collectionId, myCollection)
      )
        .then(() => {
          navigate("/app");
        })
        .finally(() => {
          setIsLoading(false);
        });
      // await firestore()
      //   .collection("collections")
      //   .doc(collectionId)
      //   .set(myCollection)
      //   .then(() => {
      //     console.log("Collection Updated Successfully");
      //     toast.success("Collection Updated Successfully");
      //     navigate("/app");
      //   })
      //   .catch((err) => {
      //     console.log("Error in store collection", err);
      //     console.log("Error in store collection", err.message);
      //     setIsLoading(false);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    } else {
      console.log("Adding Function Calling");
      dispatch(collectionActions.addCollectionData(myCollection))
        .then(() => {
          navigate("/app");
        })
        .finally(() => {
          setIsLoading(false);
        });

      // await firestore()
      //   .collection("collections")
      //   .add(myCollection)
      //   .then((res) => {
      //     console.log("Collection Added Successfully", res);
      //     console.log("res.id", res.id);
      //     toast.success("Collection Added Successfully");
      //     navigate("/app");
      //     return res;
      //   })
      //   .catch((err) => {
      //     console.log("Error in store collection", err);
      //     console.log("Error in store collection", err.message);
      //     setIsLoading(false);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  };

  return (
    <>
      {isDataLoading ? (
        <Loader isStatic />
      ) : (
        <HeaderFooterLayout>
          {/* <NavbarHeader /> */}
          <Container>
            <MDBRow className="justify-content-center mb-4 py-5">
              <MDBCol sm="9" md="8" lg={6}>
                <Header
                  link={"/app"}
                  title={`${isEditing ? "Update this" : "Create a"} collection`}
                  tagline={" "}
                  smallHeading={true}
                />

                <Row className="g-3">
                  <Col xs={12}>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      className="custom-input w-100 bg-transparent form-control border-dark text-dark shadow-none fst-italic text-center"
                      placeholder="title"
                      required
                      value={collectionData.title}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  </Col>
                  <Col xs={12}>
                    <select
                      className="custom-select w-100 rounded-3 h-100 bg-transparent border-dark text-dark shadow-none fst-italic text-center form-select"
                      id="language"
                      name="language"
                      required
                      value={collectionData.language}
                      onChange={handleChange}
                      disabled={isLoading}
                    >
                      <option value="">language *</option>
                      {lanuageArr.map((language) => {
                        return (
                          <option value={language} key={language}>
                            {language}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col xs={12}>
                    <textarea
                      id="description"
                      name="description"
                      className="custom-input w-100 bg-transparent form-control border-dark text-dark shadow-none fst-italic text-center"
                      rows={5}
                      placeholder="description"
                      required
                      value={collectionData.description}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  </Col>
                  {isAdmin && (
                    <Col xs={12} className="text-center">
                      <button
                        className={`btn fst-italic outline-none border-0 ${
                          collectionData.isBig ? "fw-bold" : ""
                        }`}
                        onClick={() =>
                          handleChange({
                            target: {
                              name: "isBig",
                              value: !collectionData.isBig,
                            },
                          })
                        }
                        disabled={isLoading}
                      >
                        Big
                      </button>
                    </Col>
                  )}
                  <Col xs={12} className="text-center">
                    <button
                      className={`btn fw-bold fst-italic outline-none border-0`}
                      onClick={() =>
                        handleChange({
                          target: {
                            name: "type",
                            value:
                              collectionData.type === "private"
                                ? "public"
                                : "private",
                          },
                        })
                      }
                      disabled={isLoading}
                    >
                      {collectionData.type === "private" ? "Private" : "Public"}
                    </button>
                  </Col>

                  <Col xs={12} className="text-center">
                    <div className="d-flex flex-column justify-content-center align-items-center gap-4">
                      <BlinkAnimatedButton
                        title={
                          isLoading
                            ? "loading..."
                            : isEditing
                            ? "Update"
                            : "Create"
                        }
                        className="px-5 py-2"
                        pressed={handleSubmit}
                        disabled={isLoading}
                      />
                    </div>
                  </Col>
                </Row>
              </MDBCol>
            </MDBRow>
          </Container>
        </HeaderFooterLayout>
      )}
    </>
  );
};

export default AddCollectionPage;
