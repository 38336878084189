import React, { useState, Suspense } from "react";
import {
  useNavigate,
  useParams,
  defer,
  Await,
  useLoaderData,
} from "react-router-dom";
import {
  Collapse,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  ButtonGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { allInputs, disabled, individual, plural } from "../../helpers/inputs";
import { Create } from "@mui/icons-material";
import { store } from "@store";
import dashboardActions from "@store/dashboard/actions";
import { confirmAlert } from "react-confirm-alert";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";

const Row = ({ row, i }) => {
  // Controlling Accordion
  const [open, setOpen] = useState(false);
  const { collection: value } = useParams();
  const navigate = useNavigate();
  const active = allInputs[value];
  return (
    <>
      <TableRow>
        {active.longInputs && (
          <TableCell>
            {/* Button Handling the opening and closing of Accordion */}
            <IconButton size="small" onClick={() => setOpen((o) => !o)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          {i + 1}
        </TableCell>
        {/* Looping the Array From Props to Get the Exercises */}
        {active.inputs
          .filter(
            (el) =>
              !active.longInputs?.some?.((inp) => el.name === inp.name) &&
              !active?.ignoredInputs?.includes(el.name)
          )
          ?.map((el, i) => (
            <TableCell style={{ minWidth: 150 }} key={i}>
              {el.getValue?.(row) || el.renderValue?.(row) || row[el.name]}
            </TableCell>
          ))}
        {active?.selects?.map((el, i) => (
          <TableCell
            style={{ minWidth: 150 }}
            className="text-capitalize"
            key={i}
          >
            {el.getValue?.(row) || el.renderValue?.(row) || row[el.name]}
          </TableCell>
        ))}
        <TableCell>
          <ButtonGroup
            aria-label="outlined primary button group"
            variant="outlined"
          >
            {disabled[value].delete ? null : (
              <Button
                color="error"
                onClick={() => {
                  confirmAlert({
                    message: (
                      <>
                        Are you sure you want to delete {individual[value]} of{" "}
                        <b>ID:</b>
                        {row.id}?
                      </>
                    ),
                    title: "This action cannot be undone.",
                    customUI: ({ title, message, onClose }) => {
                      return (
                        <SweetAlert
                          warning
                          showCancel
                          confirmBtnText="Yes, delete it!"
                          confirmBtnBsStyle="danger"
                          cancelBtnBsStyle="default"
                          title={title}
                          onConfirm={() => {
                            store.dispatch(
                              dashboardActions.deleteDashboardData(
                                value,
                                row.id,
                                { onSuccess: onClose, onError: onClose }
                              )
                            );
                          }}
                          onCancel={onClose}
                        >
                          {message}
                        </SweetAlert>
                      );
                    },
                  });
                }}
              >
                <DeleteIcon />
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => navigate(`/dashboard/${value}/edit/${row.id}`)}
            >
              <Create />
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={
            (active?.inputs?.filter(
              (el) =>
                !active.longInputs?.some?.((inp) => el.name === inp.name) &&
                !active?.ignoredInputs?.includes(el.name)
            )?.length || 0) +
            (active.selects || []).length +
            (active?.longInputs ? 1 : 0) +
            2
          }
        >
          <Collapse in={open} unmountOnExit>
            <Box margin={1}>
              {active.nonEditable && (
                <div className="p-3">
                  <Typography variant="h6" gutterBottom component="div">
                    Statistics
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {active.nonEditable.map((el, i) => (
                          <TableCell key={i}>{el.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={i}>
                        {active.nonEditable?.map((historyRow, i) => (
                          <TableCell
                            component={i === 0 ? "th" : "td"}
                            scope="row"
                            key={i}
                          >
                            {historyRow.getValue?.(row) ||
                              row[historyRow.name] ||
                              historyRow.fallback}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              )}
              <div className="p-3">
                {active?.longInputs?.map((el) => (
                  <div key={Math.random()}>
                    <Typography variant="h2" className="text-capitalize">
                      {el.name}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        maxHeight: 350,
                        overflowY: "auto",
                        display: "inline-block",
                        minWidth: 300,
                      }}
                      gutterBottom
                      component="div"
                    >
                      {el.renderValue?.(row) || row[el.name]}
                    </Typography>
                  </div>
                ))}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const FnT = () => {
  const [pageNum, setPage] = useState(0);
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const { collection } = useParams();
  const { [collection]: data = [] } = useSelector((s) => s.dashboard.dashboard);
  const active = allInputs[collection];
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <div className="container py-5">
        <TableContainer component={Paper}>
          <div className="p-3">
            <IconButton onClick={() => navigate(-1)}>
              <KeyboardBackspaceIcon />
            </IconButton>
          </div>
          <Typography variant="h3" className="text-capitalize px-3">
            {" "}
            {plural[collection]}{" "}
          </Typography>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {active.longInputs && <TableCell />}
                <TableCell>Sr&nbsp;#</TableCell>
                {active?.inputs
                  .filter(
                    (el) =>
                      !active.longInputs?.some?.(
                        (inp) => el.name === inp.name
                      ) && !active?.ignoredInputs?.includes(el.name)
                  )
                  ?.map((el) => (
                    <TableCell key={Math.random()} className="text-capitalize">
                      {" "}
                      {el.label}{" "}
                    </TableCell>
                  ))}
                {active?.selects?.map((el) => (
                  <TableCell key={Math.random()} className="text-capitalize">
                    {" "}
                    {el.label}{" "}
                  </TableCell>
                ))}
                {active?.images?.map((el) => (
                  <TableCell key={Math.random()} className="text-capitalize">
                    {" "}
                    {el.name.split("_").join(" ")}{" "}
                  </TableCell>
                ))}
                <TableCell align="right"> Actions </TableCell>
              </TableRow>
            </TableHead>
            <Suspense
              fallback={
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={
                        (active?.inputs?.filter(
                          (el) =>
                            !active.longInputs?.some?.(
                              (inp) => el.name === inp.name
                            ) && !active?.ignoredInputs?.includes(el.name)
                        )?.length || 0) +
                        (active.selects || []).length +
                        (active?.longInputs?.length ? 1 : 0) +
                        2
                      }
                      align="center"
                      className="py-3"
                    >
                      <CircularProgress size={100} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              }
            >
              <Await resolve={loaderData.data}>
                {() => (
                  <>
                    <TableBody>
                      {data.length > 0 ? (
                        (rowsPerPage > 0
                          ? data?.slice(
                              pageNum * rowsPerPage,
                              pageNum * rowsPerPage + rowsPerPage
                            )
                          : data
                        )?.map((row, i) => {
                          return <Row key={i} {...{ row, i }} />;
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={
                              (active?.inputs?.filter(
                                (el) =>
                                  !active.longInputs?.some?.(
                                    (inp) => el.name === inp.name
                                  ) && !active?.ignoredInputs?.includes(el.name)
                              )?.length || 0) +
                              (active.selects || []).length +
                              (active?.longInputs?.length ? 1 : 0) +
                              2
                            }
                            align="center"
                            className="py-5"
                          >
                            <Typography
                              variant="h5"
                              className="text-capitalize"
                            >
                              No {plural[collection]} Found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={
                            (active?.inputs?.filter(
                              (el) =>
                                !active.longInputs?.some?.(
                                  (inp) => el.name === inp.name
                                ) && !active?.ignoredInputs?.includes(el.name)
                            )?.length || 0) +
                            (active.selects || []).length +
                            (active?.longInputs?.length ? 1 : 0) +
                            2
                          }
                          count={data.length}
                          rowsPerPage={rowsPerPage}
                          page={pageNum}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            // native: true,
                          }}
                          sx={{
                            "& p": {
                              marginBottom: 0,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </>
                )}
              </Await>
            </Suspense>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const getData = async (collection) => {
  return new Promise((resolve, reject) => {
    store.dispatch(
      dashboardActions.getDashboardData(
        collection,
        {
          onSuccess: (data) => resolve(data),
          onError: (err) => reject(err),
        },
        allInputs[collection].extra
      )
    );
  });
};

export const loader = async ({ params }) => {
  const { collection } = params;
  const data = getData(collection);
  return defer({
    data,
  });
};

export default FnT;
