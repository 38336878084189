import React from "react";

import {
  Col as MDBCol,
  Container as MDBContainer,
  Row as MDBRow,
} from "react-bootstrap";
import { Navbar } from "@components/navbar";
import FullScreenMode from "@components/FullScreen";
// import { useState, useEffect } from "react";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";

const About = () => {
  // const [openNav, setOpenNav] = useState(false);
  // const [desktop, setDesktop] = useState(false);

  // Function to toggle sidebar
  // const SideBar = () => {
  //   setOpenNav(!openNav);
  // };

  // Check desktop size on component mount and resize
  // useEffect(() => {
  //   const handleResize = () => {
  //     setDesktop(window.innerWidth >= 1024);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      {/* navbar */}
      <Navbar quit={false} />
      <HeaderFooterLayout>
        {/* <NavbarHeader /> */}

        <MDBContainer
          style={{ height: window.innerHeight - 200, marginTop: "20%" }}
        >
          <MDBRow>
            <MDBCol xs={2} md={4} lg={4}>
              <h1>About</h1>
            </MDBCol>
            <MDBCol sm={12} md={8} lg={8}>
              <h5>
                {" "}
                text <br />
                next line <br />
              </h5>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <FullScreenMode />
      </HeaderFooterLayout>
    </>
  );
};

export default About;
