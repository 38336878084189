import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Fade } from "react-reveal";

export const CookieAndPrivacy = ({}) => {
  const [cookies, setCookies] = useState(false);
  const isCookiesAccepted = useMemo(
    () => localStorage.getItem("isCookiesAccepted"),
    []
  );

  const handleCookies = () => {
    localStorage.setItem("isCookiesAccepted", true);
    setCookies(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCookies(true); // Hide the cookies banner 2 seconds
    }, 1000);

    return () => clearTimeout(timeout); // Clear the timeout if component unmounts
  }, []);

  return (
    <>
      {cookies && !isCookiesAccepted && (
        <Fade bottom>
          <div className="cookie">
            <p>
              <a
                href="https://thetime.pl/privacy-policy/#cookie-privacy"
                target="_blank"
              >
                <span>Cookie & Privacy</span>
              </a>{" "}
              {window.innerWidth < 600 && <br />} This website uses cookies to
              ensure you get the best experiance on our website.{" "}
              <button className="px-2" onClick={handleCookies}>
                Accept
              </button>
              {/* <i
                onClick={() => {
                  console.log("clicked ");
                  setCookies(false);
                }}
                className="fa fa-close"
              ></i> */}
            </p>
          </div>
        </Fade>
      )}
    </>
  );
};
