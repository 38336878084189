import FormForLanguagesAndSearch from "@components/InputComponent/publicSetsComponents/FormForLanguagesAndSearch";

import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
// import { CollectionSetComponent } from "./CollectionSetComponent";
import { useDispatch, useSelector } from "react-redux";
import collectionActions from "@store/collections/action";
// import { set } from "react-hook-form";
import { PublicCollectionSetComponent } from "./PublicCollectionSetComponent";

export const PublicCollectionComponent = () => {
  const [search, setSearch] = useState("");
  const [language, setLanguage] = useState("");
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const dispatch = useDispatch();
  const [showCollections, setShowCollections] = useState(2);

  const { loading, collections } = useSelector(
    (state) => state.collections.publicCollections
  );

  const getPublicCollections = useCallback(async () => {
    console.log("Get Collection Function Run");
    setShowCollections(2);
    dispatch(
      collectionActions.getPublicCollectionsData({
        language: language,
        search: search,
      })
    );
  }, [dispatch, language, search]);

  useEffect(() => {
    getPublicCollections();
  }, [getPublicCollections]);

  const loadMore = () => {
    setShowCollections(showCollections + 2);
  };

  return (
    <>
      <FormForLanguagesAndSearch
        language={language}
        search={search}
        setLanguage={setLanguage}
        setSearch={setSearch}
        hideHeader={true}
      />
      {/* <ScrollCollections reqParams={reqParams} getData={getData} /> */}
      {loading ? (
        <p className="text-center">loading...</p>
      ) : collections?.length === 0 ? (
        <p className="text-center">No Collections Found</p>
      ) : (
        collections.slice(0, showCollections).map((collection, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            style={{
              border: "1px solid black",
            }}
            className="rounded-5 overflow-hidden bg-transparent mb-3 text-center"
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography margin={"auto"}>{collection.name}</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                borderTop: "1px solid black",
              }}
            >
              <p>{collection.description}</p>

              <PublicCollectionSetComponent collection={collection} />
            </AccordionDetails>
          </Accordion>
        ))
      )}
      {showCollections < collections.length ? (
        <div className="text-center">
          <button
            className="btn btn-outline-dark rounded-pill my-2 py-2 px-5 "
            disabled={loading || collections.length === 0}
            onClick={loadMore}
          >
            Load More
          </button>
        </div>
      ) : (
        collections.length > 0 && (
          <p className="text-center">End of Collections</p>
        )
      )}
    </>
  );
};
