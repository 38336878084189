import React from "react";

export const Loader = ({isStatic}) => {
  return (
    <div
      className={`w-100 vh-100 d-flex justify-content-center align-items-center ${isStatic ? "" : "position-fixed top-0 start-0 "}bodyColor`}
      style={{
        zIndex: "999",
      }}
    >
      <div
        className="dotLoader blinkAnimation"
        data-title="loading..."
        style={{ "--size": "250px" }}
      ></div>
    </div>
  );
};
