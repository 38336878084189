import {
  Modal as MDBModal,
  ModalBody as MDBModalBody,
  ModalHeader as MDBModalHeader,
} from "react-bootstrap";
import "./vediomodal.css";

export const VideoModal = ({ showModal, setShowModal }) => {
  const handleClose = () => setShowModal(false);

  return (
    <>
      <MDBModal show={showModal} onHide={handleClose} centered size="lg">
        <MDBModalHeader>Do Not Blink</MDBModalHeader>
        <MDBModalBody>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/GN5HIM5sCCE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ aspectRatio: "16/9" }}
            loading="lazy"
          ></iframe>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};
