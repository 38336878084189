import React from "react";
import { useEffect } from "react";
import { Loader } from "../loader";
const TooltipSection = ({ learnState, nextSentence, props }) => {
  useEffect(() => {
    if (
      learnState.sentences[learnState.sentenceIndex] ||
      (learnState.skippedSentence &&
        learnState.skippedSentence !==
          props.sentences[props.sentences.length - 1]?.sentence)
    ) {
      nextSentence();
    }
  }, [learnState]);
  return (
    <div className="text-center">
      <Loader />
    </div>
  );
};
export default TooltipSection;
