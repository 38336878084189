import MenuButton from "@components/MenuButton";
import { Loader } from "@components/loader";
import collectionActions from "@store/collections/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "@config/firebaseConfig";
import { Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setId, setSentences } from "@store/sentences/action";

export const BigCollections = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { loading, collections } = useSelector(
    (state) => state.collections.bigCollections
  );
  const [setsOFCollection, setsSetOFCollection] = useState([]);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(0);
  const [selectedSetID, setSelectedSetID] = useState(null);

  useEffect(() => {
    const getBigCollections = () => {
      dispatch(
        collectionActions.getBigCollectionsData({
          onCallBack: (myCollections) => {
            const getBigCollectionData =
              localStorage.getItem("bigCollectionData");
            console.log("getBigCollectionData", getBigCollectionData);
            const bigCollectionData = JSON.parse(getBigCollectionData);
            myCollections.forEach((data, ind) => {
              if (bigCollectionData) {
                if (data.id === bigCollectionData.collectionId) {
                  setCurrentCollection(ind);
                  getSetOfCollections({
                    collectionID: data.id,
                    matchSetId: bigCollectionData.setId,
                  });
                }
              } else {
                if (data.name === "Simple English") {
                  setCurrentCollection(ind);
                  getSetOfCollections({
                    collectionID: data.id,
                    matchSetId: "2BS8EykJxPT2uEVgqGaF",
                  });
                }
              }
            });
          },
        })
      );
    };
    getBigCollections();
  }, [dispatch]);

  const getSetOfCollections = ({ collectionID, matchSetId }) => {
    setIsSetLoading(true);
    setsSetOFCollection([]);
    console.log("matchSetId", matchSetId);
    console.log("collectionID", collectionID);
    firebase
      .firestore()
      .collection("sets")
      .where("collectionName", "==", collectionID)
      .get()
      .then((snapshot) => {
        const sets = snapshot.docs.map((doc) => {
          console.log("Set doc", { ...doc.data(), id: doc.id });

          if (matchSetId === doc.id) {
            setSelectedSetID(doc.id);
          }

          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (!matchSetId) {
          setSelectedSetID(null);
        }
        setsSetOFCollection(sets);
      })
      .catch((err) => {
        console.log("Error in fetching set", err.message);
        toast.error("Error fetching sets");
      })
      .finally(() => {
        setIsSetLoading(false);
      });
  };

  const handleSubmit = () => {
    if (!selectedSetID) {
      toast.error("Please select a set");
      return;
    } else {
      localStorage.setItem(
        "bigCollectionData",
        JSON.stringify({
          collectionId: collections[currentCollection].id,
          setId: selectedSetID,
        })
      );
      const selectedSet = setsOFCollection.filter(
        (e) => e.id === selectedSetID
      )[0];
      const sentencesData = selectedSet["sentences"];

      sentencesData.forEach((e) => {
        e.master = false;
        e.tried = false;
      });

      dispatch(
        setSentences(
          sentencesData.map((e) => {
            let sentence = e.sentence;
            sentence = sentence
              .replaceAll("‘", "'")
              .replaceAll("’", "'")
              .replaceAll("‛", "'")
              .replaceAll("`", "'")
              .replaceAll("“", '"')
              .replaceAll("”", '"')
              .replaceAll("‟", '"')
              .replaceAll("„", '"');
            return { ...e, sentence };
          })
        )
      );
      if (selectedSetID) {
        dispatch(setId(selectedSetID));
      }
      navigator("/learning-session");
    }
  };

  const handleMoveCollection = (direction) => {
    if (direction === "next") {
      if (currentCollection === collections.length - 1) {
        // toast.error("You are already at the last collection");
        setCurrentCollection(0);
        getSetOfCollections({
          collectionID: collections[0].id,
        });
      } else {
        setCurrentCollection(currentCollection + 1);
        getSetOfCollections({
          collectionID: collections[currentCollection + 1].id,
        });
      }
    } else {
      if (currentCollection === 0) {
        toast.error("You are already at the first collection");
      } else {
        setCurrentCollection(currentCollection - 1);
        getSetOfCollections({
          collectionID: collections[currentCollection - 1].id,
        });
      }
    }
  };

  if (loading) {
    return <Loader isStatic />;
  }

  return (
    <main
      className="dark-bg text-bodyColor py-5"
      style={{ minHeight: "100vh" }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            {collections.length === 0 ? (
              <p>No Big Collection Found</p>
            ) : (
              <div className="text-center">
                <section className="text-center">
                  <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
                    <button
                      className="btn text-bodyColor"
                      onClick={() => handleMoveCollection("back")}
                    >
                      {"<"}
                    </button>
                    <h4 className="m-0">
                      {collections[currentCollection].name}
                    </h4>
                    <button
                      className="btn text-bodyColor"
                      onClick={() => handleMoveCollection("next")}
                    >
                      {">"}
                    </button>
                  </div>
                  <p className="m-0 max-lines-3">
                    {collections[currentCollection].description}
                  </p>
                </section>
                <div className="my-5 d-inline-block">
                  <MenuButton size={"200px"} isMain={true} />
                </div>

                {isSetLoading ? (
                  <p>loading...</p>
                ) : setsOFCollection.length === 0 ? (
                  <p>No Collection set found</p>
                ) : (
                  <>
                    <div className="d-flex flex-column gap-3 align-items-center justify-content-center mb-4">
                      {setsOFCollection.map((set, index) => (
                        <p
                          key={index}
                          onClick={() => setSelectedSetID(set.id)}
                          className={`pointer-cursor text-uppercase text-center m-0 ${
                            selectedSetID === set.id && "fw-bold"
                          }`}
                        >
                          {set.title}
                        </p>
                      ))}
                    </div>
                    <div className="text-center">
                      <button
                        className="text-uppercase btn text-bodyColor"
                        onClick={() => handleSubmit()}
                      >
                        go
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};
