import React from "react";
import InputComponent from "@components/InputComponent";
import { Row as MDBRow, Col as MDBCol } from "react-bootstrap";
import { Header } from "@components/Header";
// import MenuButton from "@components/MenuButton";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CollectionComponent } from "@components/CollectionComponent";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import ReactTooltip from "react-tooltip";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";

export default function App() {
  const { isAuth, user } = useSelector((s) => s.auth);
  return (
    <HeaderFooterLayout>
      {/* <NavbarHeader /> */}
      <div className="px-3 container">
        <MDBRow className="justify-content-center">
          <MDBCol md="10" lg="8" xl="7">
            {/* <MenuButton /> */}

            <Header
              showUserInfo={true}
              title={"Your Stuff"}
              // title={"Your Content"}
              // tagline={
              //   // <>
              //   //   You can learn or present <b>your text</b> here. You <br />{" "}
              //   //   can also save it and create a public or private <br /> set.
              //   // </>
              //   "watch instructions"
              // }
            />
            <div className="mt-4">
              <InputComponent />
            </div>

            {!isAuth ? (
              <Link
                to="/login"
                className="btn btn-outline-dark w-100 rounded-pill my-5 py-2"
              >
                Log in to see your saved sets
              </Link>
            ) : (
              user.name && <CollectionComponent />
            )}
            <ReactTooltip
              place="bottom"
              id="my-tooltip"
              type="dark"
              effect="solid"
            />
          </MDBCol>
        </MDBRow>
      </div>
    </HeaderFooterLayout>
  );
}
