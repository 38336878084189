import { Link, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { Button, IconButton, Tooltip } from "@mui/material";
import {
  closeSVG,
  fullScreenSVG,
  translateLanguageSVG,
  translateTooltipSVG,
} from "@components/svgComponents/Svgs";
import { Button as MDBBtn } from "react-bootstrap";
import { blinkAnimation } from "@helpers/index";
import { useDispatch, useSelector } from "react-redux";
import { setId, setSentences } from "@store/sentences/action";
import { useEffect, useState } from "react";
import { ProgressBarComponent } from "@components/ProgressBarComponent";
import { getFontSize } from "@pages/LearningSession/LearningSession";
export const BoxIcon = ({
  color = "var(--text-color)",
  size = 20,
  ...props
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: 2,
        backgroundColor: "currentcolor",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: size / 2,
      }}
      {...props}
    />
  );
};

export const CircleIcon = ({
  color = "var(--text-color)",
  size = 20,
  ...props
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: "currentcolor",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: size / 2,
      }}
      {...props}
    />
  );
};

export const PlayArrowIcon = ({
  color = "var(--text-color)",
  size = 40,
  rounded = true,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color === "inherit" ? "currentColor" : color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 5V19L19 12L8 5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke="black"
      />
    </svg>
  );
};

const CustomTooltip = (props) => (
  <Tooltip
    componentsProps={{
      tooltip: { className: "bodyColor", style: { color: `black` } },
    }}
    {...props}
    title={<div className="h6 mb-0 p-1">{props.title}</div>}
  />
);

const TooltipSection = ({
  learnState,
  sentenceIndex,
  inputWord,
  nextSentence,
  props,
  fontSize,
  setSvgSkipCick,
  iterateTried,
  handleFullScreenToggle,
  collectionData,
  collectionSetList,
  setData,
}) => {
  // navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuth } = useSelector((s) => s.auth);
  const [showCollectionDropdown, setShowCollectionDropdown] = useState(false);

  const handleChangeSet = ({ selectedSetID }) => {
    const selectedSet = collectionSetList.filter(
      (e) => e.id === selectedSetID
    )[0];
    console.log("selectedSet", selectedSet);
    const sentencesData = selectedSet["sentences"];

    sentencesData.forEach((e) => {
      e.master = false;
      e.tried = false;
    });

    dispatch(
      setSentences(
        sentencesData.map((e) => {
          let sentence = e.sentence;
          sentence = sentence
            .replaceAll("‘", "'")
            .replaceAll("’", "'")
            .replaceAll("‛", "'")
            .replaceAll("`", "'")
            .replaceAll("“", '"')
            .replaceAll("”", '"')
            .replaceAll("‟", '"')
            .replaceAll("„", '"');
          return { ...e, sentence };
        })
      )
    );
    if (selectedSetID) {
      dispatch(setId(selectedSetID));
    }
    localStorage.setItem(
      "bigCollectionData",
      JSON.stringify({
        collectionId: collectionData?.id,
        setId: selectedSetID,
      })
    );

    navigate(`/learning-session/${selectedSetID}`);
  };

  // const [showExtraSection, setShowExtraSection] = useState(false);

  // useEffect(() => {
  //   // get url params
  //   const urlParams = new URLSearchParams(window.location.search);
  //   console.log("urlParams", urlParams);
  //   const myParam = urlParams.get("big");
  //   if (myParam === "true") {
  //     setShowCollectionDropdown(true);
  //   } else {
  //     setShowCollectionDropdown(false);
  //   }
  //   console.log("myParam", myParam);
  // }, []);
  return (
    <section
    // onMouseEnter={() => setShowExtraSection(true)}
    // onMouseLeave={() => setShowExtraSection(false)}
    >
      <section
        className="position-fixed w-100 d-flex justify-content-center align-items-start py-2"
        // style={{
        //   opacity: showExtraSection ? "1" : ".1",
        //   transition: ".5s all ease-in",
        // }}
      >
        <div className="hover-fade-amination">
          {isAuth ? (
            <div className="text-center">
              <h5 className="fw-bold">
                {collectionData.isBig
                  ? collectionData?.name || "Collection Name"
                  : setData?.title || "Set Name"}
              </h5>
              {collectionData?.isBig && (
                <select
                  className="bg-dark text-uppercase border-0 outline-none mb-4"
                  defaultValue={setData?.id || collectionSetList[0]?.id}
                  onChange={(e) =>
                    handleChangeSet({ selectedSetID: e.target.value })
                  }
                >
                  {collectionSetList.map((set, index) => (
                    <option value={set.id} key={index}>
                      {set.title}
                    </option>
                  ))}
                </select>
              )}
              {/* <p className="text-uppercase mb-3">{setData?.title || "Set Name"}</p> */}

              {sentenceIndex === learnState.sentences.length ? null : (
                <div className="cursor-pointer text-center">
                  {learnState.skippedSentence
                    ? "skipped"
                    : learnState.sentences[sentenceIndex - 1].mastered
                    ? "perfect!"
                    : "correct"}
                </div>
              )}
            </div>
          ) : (
            <button
              className="btn bodyColor text-uppercase fw-bold rounded-pill px-5 text-dark"
              onClick={async (e) => {
                await blinkAnimation(e);
                navigate("/signup");
              }}
            >
              SIGN UP TO KEEP PROGRESS
            </button>
          )}
        </div>
      </section>

      <div className="d-flex px-4 justify-content-center align-items-center flex-column min-vh-100">
        {/* {sentenceIndex === learnState.sentences.length ? null : (
          <div className="cursor-pointer text-center mb-4">
            {learnState.skippedSentence ? (
              <p className="d-inline-block">skipped</p>
            ) : learnState.sentences[sentenceIndex - 1].mastered ? (
              <p className="d-inline-block">perfect!</p>
            ) : (
              <p className="d-inline-block">correct</p>
            )}
          </div>
        )} */}
        <div className="text-center w-100">
          {sentenceIndex === learnState.sentences.length ? (
            <div>
              <h5 className="mb-3">THE END</h5>
              <h5 className="mb-5">
                {learnState.sentences.length} / {learnState.sentences.length}
              </h5>
              {learnState.sentences.map((sentence, index) => (
                <h4
                  key={index}
                  className={`mb-3 my-2 text-break ${
                    !sentence.mastered ? "fw-bold" : ""
                  }`}
                  style={{
                    fontSize: `max(min(${fontSize}rem, 4.67rem), 1.5rem)`,
                  }}
                >
                  {sentence.sentence}
                </h4>
              ))}
            </div>
          ) : (
            <>
              <h4
                className="mb-4 text-break"
                style={{
                  //  fontSize: `max(min(${fontSize}rem, 4.67rem), 1.5rem)`,
                  fontSize: `max(min(${getFontSize(
                    learnState.skippedSentence || inputWord
                  )}rem, 4.67rem), 1.5rem)`,
                }}
              >
                {learnState.skippedSentence || inputWord}
              </h4>
              <div className="mb-4 hover-fade-amination opacity-20">
                <ProgressBarComponent
                  currentLength={sentenceIndex}
                  totalLength={learnState.sentences.length}
                />
              </div>
              {learnState.sentences[sentenceIndex - 1].subtitle && (
                <div
                  // style={{
                  //   opacity: showExtraSection ? "1" : ".1",
                  //   transition: ".5s all ease-in",
                  // }}
                  className="hover-fade-amination"
                >
                  {learnState.sentences[sentenceIndex - 1]?.link ? (
                    <a
                      href={learnState.sentences[sentenceIndex - 1].link}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        fontSize: `max(3rem, 1.5rem)`,
                      }}
                      className="mb-5 text-break fw-normal text-decoration-none"
                    >
                      {learnState.sentences[sentenceIndex - 1].subtitle}
                    </a>
                  ) : (
                    <h5
                      style={{
                        fontSize: `max(3rem, 1.5rem)`,
                      }}
                      className="mb-5 text-break fw-normal"
                    >
                      {learnState.sentences[sentenceIndex - 1].subtitle}
                    </h5>
                  )}
                </div>
              )}
            </>
          )}
          {/* <Fade cascade>
            <div>
              <div className="w-100 mb-1"> */}
          {/* <CustomTooltip
                  title={`Help Count ${learnState.helpcount}`}
                  className="me-4"
                >
                  <IconButton
                    className="text-dark"
                    style={{
                      fontSize: `2.5rem`,
                    }}
                  >
                    <BoxIcon color={"inherit"} size={25}>
                      <span className="text-dark">{learnState.helpcount}</span>
                    </BoxIcon>
                  </IconButton>
                </CustomTooltip> */}

          {/* <CustomTooltip
                  title={`Flash Count: ${learnState.count}`}
                  className="me-4"
                >
                  <IconButton
                    color="primary"
                    className="text-dark"
                    style={{
                      fontSize: `2.5rem`,
                    }}
                  >
                    <CircleIcon size={50} color="inherit" fontSize="inherit">
                      <span className="text-dark">{learnState.count}</span>
                    </CircleIcon>
                  </IconButton>
                </CustomTooltip> */}

          {/* <CustomTooltip title={"Cmd/Ctrl + G to TRANSLATE"}>
                  <IconButton
                    className="text-dark"
                    href={`http://translate.google.com?text=${inputWord}`}
                    target="_blank"
                  >
                    {translateTooltipSVG()}
                  </IconButton>
                </CustomTooltip> */}
          {/* </div> */}

          {/* <h4 className="same_font d-inline-block">you skipped it</h4> */}
          {/* </div>
          </Fade> */}
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center gap-4 start-0 position-fixed bottom-0 w-100 mb-3 hover-fade-amination"
        // style={{
        //   opacity: showExtraSection ? "1" : ".1",
        //   transition: ".5s all ease-in",
        // }}
      >
        <CustomTooltip title="Esc to QUIT">
          <MDBBtn
            style={{
              cursor: "pointer",
              border: "none",
              margin: 0,
              padding: 0,
              width: "2rem",
              height: "2rem",
            }}
            className="bg-transparent shadow-none text-dark"
            // data-tip="Esc to QUIT"
            onClick={() => {
              navigate(-1);
              // navigate("/");
            }}
          >
            {closeSVG()}
          </MDBBtn>
        </CustomTooltip>
        <CustomTooltip title="Full Screen">
          <MDBBtn
            style={{ cursor: "pointer", border: "none", margin: 0, padding: 0 }}
            className="bg-transparent shadow-none text-dark"
            onClick={handleFullScreenToggle}
          >
            {fullScreenSVG()}
          </MDBBtn>
        </CustomTooltip>
        <div>
          {learnState.sentences[sentenceIndex] ||
          (learnState.skippedSentence &&
            learnState.skippedSentence !==
              props.sentences[props.sentences.length - 1]?.sentence) ? (
            <>
              <CustomTooltip title="Enter/Return to continue">
                <IconButton
                  className="text-dark bodyColor"
                  style={{
                    // fontSize: `2.5rem`,
                    width: "3rem",
                    height: "3rem",
                  }}
                  onClick={() => {
                    setSvgSkipCick(true);
                    nextSentence();
                  }}
                >
                  {/* <PlayArrowIcon color="inherit" fontSize="inherit" /> */}
                </IconButton>
              </CustomTooltip>
            </>
          ) : (
            <>
              <CustomTooltip title="Press Enter to Restart.">
                <IconButton
                  color="primary"
                  className="text-dark bodyColor"
                  style={{
                    // fontSize: `2.5rem`,
                    width: "3rem",
                    height: "3rem",
                  }}
                  onClick={() => {
                    // if (perfectCount == learnState.sentences.length) {
                    navigate(-1);
                    // }
                    // iterateTried();
                  }}
                >
                  {/* <PlayArrowIcon color="inherit" fontSize="inherit" /> */}
                </IconButton>
              </CustomTooltip>
            </>
          )}
        </div>
        <CustomTooltip title={"Cmd/Ctrl + G to TRANSLATE"}>
          <MDBBtn
            style={{
              cursor: "pointer",
              border: "none",
              margin: 0,
              padding: 0,
              width: "2rem",
              height: "2rem",
            }}
            className="shadow-none text-dark bodyColor rounded-circle overflow-hidden"
            as="a"
            href={`http://translate.google.com?text=${
              inputWord || learnState.sentences[sentenceIndex - 1]?.sentence
            }`}
            target="_blank"
          >
            {translateLanguageSVG({
              width: "2rem",
              height: "2rem",
            })}
          </MDBBtn>
        </CustomTooltip>

        <CustomTooltip title={`Flash Count: ${learnState.count}`}>
          <IconButton color="primary" className="text-dark p-0 m-0">
            <CircleIcon size={32} color="inherit" fontSize="inherit">
              <span className="text-dark fw-bold">{learnState.count}</span>
            </CircleIcon>
          </IconButton>
        </CustomTooltip>
      </div>
    </section>
  );
};
export default TooltipSection;
