import React from "react";
import { toast } from "react-hot-toast";

export const AlertMessage = (props) => {
  if (props.message && props.message.length > 0) {
    toast.success(
      props.href ? (
        <>
          Shared Link:
          <a href={props.href} target="_blank" rel="noopener noreferrer">
            {props.href}
          </a>
        </>
      ) : (
        props.message
      ),
      {
        position: "bottom-left",
        duration: 2000,
      }
    );
  }
};
