import * as constants from "./constants";
import firebase from "@config/firebaseConfig";
import { toast } from "react-hot-toast";
import { individual } from "@helpers/inputs";
const db = firebase.firestore();
const getDashboardData = (name, functions, extra) => {
  return async (dispatch) => {
    dispatch({
      type: constants.GET_DASHBOARD_DATA_REQUEST,
    });
    try {
      functions?.onRequest?.();
      let query = db.collection(name);
      query = extra ? extra(query) : query;
      const data = await query.get();
      const response = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      functions?.onSuccess?.(response);
      dispatch({
        type: constants.GET_DASHBOARD_DATA_SUCCESS,
        payload: response,
        name,
      });
    } catch (error) {
      console.log(error);
      functions?.onError?.(error);
      dispatch({
        type: constants.GET_DASHBOARD_DATA_ERROR,
        payload: error,
        name,
      });
    }
  };
};

const addDashboardData = (name, data, functions) => {
  return async (dispatch) => {
    dispatch({
      type: constants.ADD_DASHBOARD_DATA_REQUEST,
    });
    const loading = toast.loading(`Adding ${individual[name]}...`);
    try {
      functions?.onRequest?.();
      const response = await db
        .collection(name)
        .add({
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      functions?.onSuccess?.({ id: response.id, ...data });
      toast.dismiss(loading);
      toast.success(`${individual[name]} Added.`);
      dispatch({
        type: constants.ADD_DASHBOARD_DATA_SUCCESS,
        payload: { id: response.id, ...data },
        name,
      });
    } catch (error) {
      console.log(error);
      functions?.onError?.(error);
      dispatch({
        type: constants.ADD_DASHBOARD_DATA_ERROR,
        payload: error,
        name,
      });
    }
  };
};

const updateDashboardData = (name, data, functions) => {
  return async (dispatch) => {
    const loading = toast.loading(`Saving ${individual[name]}...`);
    dispatch({
      type: constants.UPDATE_DASHBOARD_DATA_REQUEST,
    });
    try {
      functions?.onRequest?.();
      await db
        .collection(name)
        .doc(data.id)
        .update({
          ...data,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      toast.dismiss(loading);
      toast.success(`${individual[name]} Saved.`);
      dispatch({
        type: constants.UPDATE_DASHBOARD_DATA_SUCCESS,
        payload: { id: data.id, ...data },
        name,
      });
      functions?.onSuccess?.(data);
    } catch (error) {
      console.log(error);
      functions?.onError?.(error);
      dispatch({
        type: constants.UPDATE_DASHBOARD_DATA_ERROR,
        payload: error,
        name,
      });
    }
  };
};

const deleteDashboardData = (name, id, functions) => {
  return async (dispatch) => {
    dispatch({
      type: constants.DELETE_DASHBOARD_DATA_REQUEST,
    });
    const loading = toast.loading(`Deleting ${individual[name]}...`);
    try {
      functions?.onRequest?.();
      await db.collection(name).doc(id).delete();
      toast.dismiss(loading);
      toast.success(`${individual[name]} Deleted.`);
      dispatch({
        type: constants.DELETE_DASHBOARD_DATA_SUCCESS,
        payload: { id },
        name,
      });
      functions?.onSuccess?.({ id });
    } catch (error) {
      console.log(error);
      functions?.onError?.(error);
      dispatch({
        type: constants.DELETE_DASHBOARD_DATA_ERROR,
        payload: error,
        name,
      });
    }
  };
};

const getSingleDashboardData = (name, id, functions) => {
  console.log("getSingleDashboardData Name", name);
  console.log("getSingleDashboardData Id", id);

  return async (dispatch) => {
    dispatch({
      type: constants.GET_SINGLE_DASHBOARD_DATA_REQUEST,
    });
    // const loading = toast.loading(`Loading ${individual[name]}...`)
    try {
      functions?.onRequest?.();
      const data = await db.collection(name).doc(id).get();
      // toast.dismiss(loading);
      const response = data.data();
      functions?.onSuccess?.(response);
      dispatch({
        type: constants.GET_SINGLE_DASHBOARD_DATA_SUCCESS,
        payload: response,
        name,
      });
    } catch (error) {
      console.log(error);
      functions?.onError?.();
      dispatch({
        type: constants.GET_SINGLE_DASHBOARD_DATA_ERROR,
        payload: error,
        name,
      });
    }
  };
};
const dashboardActions = {
  getDashboardData,
  addDashboardData,
  updateDashboardData,
  deleteDashboardData,
  getSingleDashboardData,
};
export default dashboardActions;
