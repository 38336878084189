import React, { useEffect, useState } from "react";
import ScrollSentances from "./ScrollSentances";
import firebase from "@config/firebaseConfig";
import { toast } from "react-hot-toast";
import FormForLanguagesAndSearch from "./FormForLanguagesAndSearch";
// import MenuButton from "@components/MenuButton";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";
const LIMIT_OF_SENTENCES = 8;
export default function PublicSets() {
  const [language, setLanguage] = useState("");
  const [search, setSearch] = useState("");

  const [reqParams, setReqParams] = useState({
    hasMore: true,
    lastItem: false,
    sentences: [],
  });
  const getData = async () => {
    console.log("get data");
    let query = firebase
      .firestore()
      .collection("sets")
      .where("isPrivate", "==", false)
      .orderBy("title");
    query = query.orderBy("startCount", "desc");
    if (reqParams.lastItem) {
      query = query.startAfter(reqParams.lastItem);
    }
    if (language) {
      query = query.where("language", "==", language);
    }
    if (search) {
      query = query
        .startAt(search.toLowerCase())
        .endAt(search.toLowerCase() + "\uf8ff");
    }
    try {
      const snapshot = await query.limit(LIMIT_OF_SENTENCES).get();
      const sentences = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReqParams((reqParams) => ({
        ...reqParams,
        sentences: [...reqParams.sentences, ...sentences].filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        ),
        lastItem: snapshot.docs.at(-1),
        hasMore: snapshot.docs.length === LIMIT_OF_SENTENCES,
      }));
    } catch (err) {
      toast.error(err.message);
    }
  };
  // trying to handle useEffect
  useEffect(() => {
    setReqParams({
      ...reqParams,
      lastItem: false,
      sentences: [],
      hasMore: true,
    });
    const timerId = setTimeout(() => {
      getData();
    }, 750);

    return () => {
      clearTimeout(timerId);
    };
  }, [language, search]);
  return (
    <HeaderFooterLayout>
      {/* <NavbarHeader /> */}

      <div className="text-center my-5">
        {/* <MenuButton /> */}
        <FormForLanguagesAndSearch
          language={language}
          search={search}
          setLanguage={setLanguage}
          setSearch={setSearch}
        />
        <div className="row">
          <div className="col-sm-6 mx-auto col-12">
            <ScrollSentances reqParams={reqParams} getData={getData} />
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  );
}
