import firebase from "@config/firebaseConfig";
import {
  ADD_COLLECTION_ERROR,
  ADD_COLLECTION_REQUEST,
  ADD_COLLECTION_SUCCESS,
  GET_COLLECTIONS_DATA_ERROR,
  GET_COLLECTIONS_DATA_REQUEST,
  GET_COLLECTIONS_DATA_SUCCESS,
  GET_PUBLIC_COLLECTIONS_DATA_ERROR,
  GET_PUBLIC_COLLECTIONS_DATA_REQUEST,
  GET_PUBLIC_COLLECTIONS_DATA_SUCCESS,
  GET_BIG_COLLECTIONS_DATA_ERROR,
  GET_BIG_COLLECTIONS_DATA_REQUEST,
  GET_BIG_COLLECTIONS_DATA_SUCCESS,
  // My_TARGET_COLLECTION,
  My_TARGET_COLLECTION_SETS_ERROR,
  My_TARGET_COLLECTION_SETS_REQUEST,
  My_TARGET_COLLECTION_SETS_SUCCESS,
  UPDATA_TARGET_COLLECTION_SUCCESS,
  UPDATE_COLLECTIONS_ERROR,
  UPDATE_COLLECTIONS_REQUEST,
  UPDATE_COLLECTIONS_SUCCESS,
  UPDATE_SET_OF_COLLECTION_DATA,
} from "./actionTypes";
import toast from "react-hot-toast";

const getCollectionsData = (id) => {
  console.log("Get Collection Id", id);
  return async (dispatch) => {
    dispatch({
      type: GET_COLLECTIONS_DATA_REQUEST,
    });

    await firebase
      .firestore()
      .collection("collections")
      .where("createdBy", "==", id)

      .get()
      .then((res) => {
        console.log("res", res);
        const collections = [];
        res.docs.map((doc, index) => {
          console.log("Get Collection Data" + index + ": ", {
            ...doc.data(),
            id: doc.id,
          });
          return collections.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        // order by created at ascending
        // collections.sort((a, b) => {
        //   return a.createdAt - b.createdAt;
        // });
        // order by alphabetically
        collections.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        dispatch({
          type: GET_COLLECTIONS_DATA_SUCCESS,
          payload: collections,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_COLLECTIONS_DATA_ERROR,
          payload: error,
        });
      });
  };
};

const getPublicCollectionsData = ({ startFrom, search, language }) => {
  console.log("getPublicCollectionsData", { startFrom, search, language });
  return async (dispatch) => {
    dispatch({
      type: GET_PUBLIC_COLLECTIONS_DATA_REQUEST,
    });

    // Get data from firebase with limit and search and language

    let query = firebase
      .firestore()
      .collection("collections")
      .where("type", "==", "public");
    // query = query.orderBy("startCount", "desc");
    // if (startFrom) {
    //   query = query.startAfter(startFrom);
    // }
    if (language) {
      query = query.where("language", "==", language);
    }
    if (search) {
      // query = query
      //   .where("name", ">=", search)
      //   .where("name", "<=", search + "\uf8ff");
      query = query
        .orderBy("name")
        .orderBy("createdAt")
        .startAt(search.toLowerCase())
        .endAt(search.toLowerCase() + "\uf8ff");
    }

    await query
      // .limit(startFrom)
      .get()
      .then((res) => {
        console.log("res", res);
        const collections = [];
        res.docs.map((doc, index) => {
          console.log("Get Collection Data" + index + ": ", {
            ...doc.data(),
            id: doc.id,
          });
          return collections.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        // order by created at ascending
        collections.sort((a, b) => {
          return a.createdAt - b.createdAt;
        });
        dispatch({
          type: GET_PUBLIC_COLLECTIONS_DATA_SUCCESS,
          payload: collections,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message || "Error in getting public collections");
        dispatch({
          type: GET_PUBLIC_COLLECTIONS_DATA_ERROR,
          payload: error,
        });
      });
  };
};

const getBigCollectionsData = ({ onCallBack }) => {
  return async (dispatch) => {
    dispatch({
      type: GET_BIG_COLLECTIONS_DATA_REQUEST,
    });

    await firebase
      .firestore()
      .collection("collections")
      .where("isBig", "==", true)
      .get()
      .then((res) => {
        console.log("big collection res", res);
        const collections = [];
        res.docs.map((doc, index) => {
          console.log("Get big Collection Data" + index + ": ", {
            ...doc.data(),
            id: doc.id,
          });
          return collections.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        // order by created at ascending
        collections.sort((a, b) => {
          return a.createdAt - b.createdAt;
        });
        dispatch({
          type: GET_BIG_COLLECTIONS_DATA_SUCCESS,
          payload: collections,
        });

        if (onCallBack) {
          onCallBack(collections);
        }
      })
      .catch((error) => {
        console.log("big collection error", error);
        dispatch({
          type: GET_BIG_COLLECTIONS_DATA_ERROR,
          payload: error,
        });
      });
  };
};

const addCollectionData = (data) => {
  console.log("Add Collection Data", data);
  return async (dispatch) => {
    dispatch({
      type: ADD_COLLECTION_REQUEST,
    });

    await firebase
      .firestore()
      .collection("collections")
      .add(data)
      .then((res) => {
        console.log("res.id", res.id);
        console.log("New Collection Data", {
          id: res.id,
          ...data,
        });
        toast.success("Collection created successfully");
        dispatch({
          type: ADD_COLLECTION_SUCCESS,
          payload: {
            id: res.id,
            ...data,
          },
        });
      })
      .catch((err) => {
        console.log("Error in creating collection", err);
        console.log("Error in creating collection", err.message);
        toast.error("Error in creating collection");
        dispatch({
          type: ADD_COLLECTION_ERROR,
          payload: err,
        });
      });
  };
};

const updateCollectionData = (id, data) => {
  console.log("Before Update Collection Data", { ...data, id });
  return async (dispatch) => {
    dispatch({
      type: UPDATE_COLLECTIONS_REQUEST,
    });
    await firebase
      .firestore()
      .collection("collections")
      .doc(id)
      .update(data)
      .then((res) => {
        toast.success("Collection updated successfully");
        dispatch({
          type: UPDATE_COLLECTIONS_SUCCESS,
          payload: { ...data, id },
        });
      })
      .catch((err) => {
        console.log("Error in Update Collection", err);
        console.log("Error in Update Collection", err.message);
        toast.error("Error in updating collection");
        dispatch({
          type: UPDATE_COLLECTIONS_ERROR,
          payload: err,
        });
      });
  };
};

const getCollectionSetsData = (collectionName) => {
  return async (dispatch) => {
    if (!collectionName) {
      dispatch({
        type: My_TARGET_COLLECTION_SETS_SUCCESS,
        payload: [],
      });
      return;
    }
    dispatch({
      type: My_TARGET_COLLECTION_SETS_REQUEST,
    });
    await firebase
      .firestore()
      .collection("sets")
      .where("collectionName", "==", collectionName)
      .orderBy("createdAt", "desc")
      .get()
      .then((res) => {
        const sets = [];
        res.docs.map((doc, index) => {
          return sets.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        console.log("Get Collection Sets Data", sets);
        dispatch({
          type: My_TARGET_COLLECTION_SETS_SUCCESS,
          payload: sets,
        });
      })
      .catch((err) => {
        console.log("Error in getting set", err);
        console.log("Error in getting set", err.message);
        dispatch({
          type: My_TARGET_COLLECTION_SETS_ERROR,
          payload: err,
        });
      });
  };
};

const updateSetOfCollection = (data) => ({
  type: UPDATA_TARGET_COLLECTION_SUCCESS,
  payload: data,
});

const collectionActions = {
  getCollectionsData,
  getPublicCollectionsData,
  getBigCollectionsData,
  addCollectionData,
  updateCollectionData,
  getCollectionSetsData,
  updateSetOfCollection,
};

export default collectionActions;
