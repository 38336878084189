import { defer, useLoaderData, useParams, Await } from "react-router-dom";
import { store } from "@store";
import dashboardActions from "@store/dashboard/actions";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  Paper,
  styled,
  TextField,
} from "@mui/material";
import React, {
  useMemo,
  useState,
  Suspense,
  useCallback,
  useEffect,
} from "react";
import { Controller, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { allInputs, individual } from "@helpers/inputs";
import { useDispatch, useSelector } from "react-redux";
import collectionActions from "@store/collections/action";

const MyCustomInput = styled(TextField)(({ theme, error }) => ({
  "& .MuiFilledInput-root": {
    border: `1px solid ${error ? "red" : theme.palette.grey[400]} !important`,
    overflow: "hidden",
    borderRadius: 25,
    fontSize: "0.85rem",
    background: "none",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      background: "none",
    },
    "&.Mui-focused": {
      background: "none",
      borderColor: `${error ? "red" : theme.palette.primary.main} !important`,
    },
    "&.Mui-disabled": {
      background: "none",
    },
  },
}));

const Input = ({
  label: lab,
  control,
  labelProps,
  TextInput,
  prefix,
  ...p
}) => {
  const [type, setType] = useState(p.type);
  const isPassword = useMemo(() => p.type === "password", [p.type]);
  const show = useMemo(() => {
    if (type === "password") return true;
    return false;
  }, [type]);
  /**
   * @type {TextField}
   */
  const CustomInput = TextInput || MyCustomInput;
  return (
    <div>
      {control ? (
        <Controller
          control={control}
          name={p.name}
          defaultValue={p.defaultValue || ""}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              fullWidth
              {...p}
              {...field}
              variant="filled"
              type={type}
              label={lab}
              error={!!error?.message}
              helperText={error?.message}
              id={lab}
              SelectProps={{
                displayEmpty: true,
              }}
              InputProps={{
                ...p?.InputProps,
                disableUnderline: true,
                className: `border-0 ${p?.InputProps?.className}`,
                endAdornment: isPassword ? (
                  <InputAdornment
                    position="end"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={() => setType(show ? "text" : "password")}
                  >
                    {show ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
                ) : (
                  p?.InputProps?.endAdornment
                ),
                startAdornment: prefix,
              }}
            />
          )}
        />
      ) : (
        <CustomInput
          fullWidth
          {...p}
          type={type}
          className={`${p.className} shadow-sm shadow-gray-400 rounded-xl`}
          id={lab}
          label={lab}
          variant="filled"
          SelectProps={{
            className: "py-1",
            displayEmpty: true,
          }}
          InputProps={{
            ...p?.InputProps,
            className: `border-0 ${p?.InputProps?.className}`,
            endAdornment: isPassword ? (
              <InputAdornment
                position="end"
                className="cursor-pointer select-none"
                onClick={() => setType(show ? "text" : "password")}
              >
                <div className="text-xs">{show ? "Show" : "Hide"}</div>
              </InputAdornment>
            ) : (
              p?.InputProps?.endAdornment
            ),
            startAdornment: prefix,
          }}
        />
      )}
    </div>
  );
};

const Form = ({ item = {} }) => {
  const dispatch = useDispatch();
  const { collection, id = undefined } = useParams();

  console.log("Modify Page", collection, id);
  console.log("My Item", item);
  const { uid } = useSelector((state) => state.auth);
  const inputs = useMemo(() => {
    return allInputs[collection] || {};
  }, [collection]);

  const getCollections = useCallback(async () => {
    console.log("Get COllection Function Run");
    const userId = id ? item.createdBy : uid;
    console.log("item.createdBy", userId);
    dispatch(collectionActions.getCollectionsData(userId));
  }, [dispatch, id, item.createdBy, uid]);

  useEffect(() => {
    if (collection === "sets") {
      getCollections();
    }
  }, [collection, getCollections]);

  const onSubmit = (data) => {
    const modifiedData = inputs.onSubmited ? inputs.onSubmited(data) : data;
    if (id) {
      dispatch(
        dashboardActions.updateDashboardData(collection, {
          id,
          ...modifiedData,
        })
      );
    } else {
      dispatch(dashboardActions.addDashboardData(collection, modifiedData));
    }
  };
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      ...inputs.inputs.concat(inputs?.selects || [])?.reduce((acc, cur) => {
        acc[cur.name] = cur.getValue?.(item) || item[cur.name] || "";
        return acc;
      }, {}),
    },
  });
  const watchedInputs = inputs.inputs
    .concat(inputs?.selects || [])
    .reduce((acc, cur) => {
      if (cur.watch) acc[cur.name] = watch(cur.name);
      return acc;
    }, {});
  console.log(watchedInputs);
  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        {inputs?.selects?.map(({ getValue, ...input }, i) => (
          <div key={i} className="col-lg-6 py-3">
            <Input
              control={control}
              required
              {...input}
              TextInput={(params) => (
                <Autocomplete
                  {...params}
                  options={input.options}
                  value={params.value}
                  onChange={(e, v) => {
                    params.onChange(v);
                  }}
                  getOptionLabel={(option) => option.label || option}
                  renderInput={(params) => (
                    <MyCustomInput
                      variant="filled"
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps,
                      }}
                      label={input.label}
                    />
                  )}
                />
              )}
            />
          </div>
        ))}
        {inputs?.inputs?.map(({ getValue, prefix, ...input }, i) => (
          <div key={i} className="col-lg-6 py-3">
            {console.log(prefix?.(watchedInputs), "MY PREFIX")}
            <Input
              {...input}
              required
              prefix={prefix?.(watchedInputs)}
              control={control}
            />
          </div>
        ))}
        <div className="col-12">
          <Button variant="contained" color="primary" type="submit">
            {id ? "Edit" : "Add"} {individual[collection]}
          </Button>
        </div>
      </form>
    </div>
  );
};

const Modify = () => {
  const { collection, id = undefined } = useParams();
  const loaderData = useLoaderData();

  return (
    <div>
      <Container maxWidth="lg" component={Paper} className="p-4">
        <h1 className="text-2xl font-bold">
          {id ? "Edit" : "Add"} {individual[collection]}
        </h1>
        <Suspense
          fallback={
            <div className="py-4 w-100">
              <CircularProgress size={100} className="mx-auto" />
            </div>
          }
        >
          <Await resolve={loaderData?.dataItem}>
            {(data) => {
              return <Form item={data} />;
            }}
          </Await>
        </Suspense>
      </Container>
    </div>
  );
};

const getItem = (collection, id) => {
  return new Promise((resolve, reject) => {
    if (!id) return resolve({});
    store.dispatch(
      dashboardActions.getSingleDashboardData(collection, id, {
        onSuccess: resolve,
        onError: reject,
      })
    );
  });
};
/**
 * @type {import("react-router-dom").LoaderFunction}
 */
export const loader = ({ params }) => {
  return defer({
    dataItem: getItem(params.collection, params.id),
  });
};

export default Modify;
