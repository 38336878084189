import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./routes/routes";
import { Toaster } from "react-hot-toast";
import { persistor, store } from "@store";
import { PersistGate } from "redux-persist/integration/react";
import { CircularProgress } from "@mui/material";
import { Provider } from "react-redux";
import { ClearCacheProvider } from "react-clear-cache";
import "./App.scss";
import "./App.css";
const root = createRoot(document.getElementById("root"));
root.render(
  <ClearCacheProvider duration={5000}>
    <PersistGate
      loading={
        <div className="h-screen flex w-100 items-center justify-center">
          <CircularProgress size={80} />
        </div>
      }
      persistor={persistor}
    >
      <Provider store={store}>
        <Routes />
        <Toaster position="top-center" />
      </Provider>
    </PersistGate>
  </ClearCacheProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
