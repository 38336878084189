import React, { useEffect, useRef, useState } from "react";
import "./LearningSession.css";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSentences } from "@store/sentences/action";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import firebase from "@config/firebaseConfig";
import FlashSection from "@components/LearnPageComponents/FlashSection";
import QuestionSection from "@components/LearnPageComponents/QuestionSection";
import StarterTextSection from "@components/LearnPageComponents/StarterTextSection";
import CountSection from "@components/reversedComponents/CountSection";
import AnswerSection from "@components/LearnPageComponents/AnswerSection";
import TooltipSection from "@components/reversedComponents/TooltipSection";
import { generateArrayOfNumbers, generateArrayOfWords } from "@helpers";
import { numbers } from "@components/InputComponent/ReversedTest";
import { getFontSize } from "../LearningSession/LearningSession";

let timeoutx;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const LearningSession = () => {
  const location = useLocation();
  const params = useParams();
  const blinker = useRef(null);
  const fadeScreen = useRef(null);
  // navigation
  const navigate = useNavigate();
  // redux hooks
  const sentencesReducer = useSelector((state) => state.sentences.sentences);
  const mode = useSelector((state) => state.sentences.mode);
  const id = useSelector((state) => state.sentences.id);
  const dispatch = useDispatch();
  const props = {
    sentences: sentencesReducer,
    mode,
    id,
  };

  //   state
  const [timer, setTimer] = useState(null);
  const [inputWord, setInputWord] = useState("");
  const [showSection, setShowSection] = useState(1);
  const [isUpdateBestScore, setIsUpdateBestScore] = useState(false);
  const [startTestTime, setStartTestTime] = useState(null);

  const [learnState, setLearnState] = useState({
    FullScreen: false,

    sentences: [], // separated sentences
    showSection: 1, // show respective containers
    hideReady: false, // Show Ready text
    sentenceIndex: 0, // Sentence index shows the current sentence
    inputWord: "", // the input which user is answering while learning
    showHelp: false,
    help: [],
    per: ["perfect", "amazing", "flawless"],
    selectedNote: "",
    FSwidth: 0,
    //Here is the counter of flashes and length of the first flash states
    count: 1,
    countsec: 0,
    perfect: false,
    inputHidden: true,
    againHidden: true,
    showWord: false,
    color: "white",
    in: 0,
    te: false,
    flashCount: 1,
    helpcount: 0,
    skipCount: 0,
    againSentenceMessage: false,
    flashBannerTiming: 1500, // Flash one don't blink text banner timing
    inputStyle: {
      color: "black",
    },
    textToLearn: "",
    enableColor: false,
    enableSound: false,
    startLearning: true,
    playSound: false,
    sentenceSkipped: 0,
    HideAll: true,
    spellCheck: false,
    flash: false,
    skippedSentence: false,
    doneCoundIncrement: false,
  });
  // preview values
  // const prevProps = usePrevious(props);
  const prevState = usePrevious(learnState);

  const addkeyListner = () => {
    window.addEventListener("keydown", helpEvent);
    if (window.innerWidth > 600) {
      window.addEventListener("keydown", StartSession);
    } else {
      window.addEventListener("touchstart", MobileStartSession);
    }
    setLearnState((state) => {
      return { ...state, startLearning: false };
    });
  };
  const fetchNumbers = async (length) => {
    setLearnState((state) => {
      return {
        ...state,
        sentences: generateArrayOfNumbers(length),
      };
    });

    addkeyListner();
  };
  function loadJSON(fileUrl) {
    var request = new Request(fileUrl);

    fetch(request, {
      mode: "cors",
    })
      .then(function (response) {
        // Convert to JSON
        return response.json();
      })
      .then(function (j) {
        // Yay, `j` is a JavaScript object
        setLearnState((state) => {
          return {
            ...state,
            sentences: generateArrayOfWords(j),
          };
        });

        addkeyListner();
      })
      .catch(function (error) {
        console.log("Request failed", error);
        console.log("response failed", error.message);
      });
  }
  const fetchWords = async (length) => {
    var storageRef = firebase.storage().ref(`words/${length}.json`);
    const datais = await storageRef.getDownloadURL();
    //
    loadJSON(datais);
  };
  // useEffect
  useEffect(() => {
    const length = numbers.includes(+params.id) ? +params.id : 3;
    // fetch array word or numbers
    if (location.pathname.includes("numbers")) {
      fetchNumbers(length);
    } else {
      fetchWords(length);
    }

    return () => {
      clearTimeout(window.timeout);
      clearTimeout(window.timeout2);
      clearTimeout(timeoutx);
      clearInterval(timer);
      window.removeEventListener("keydown", windowKeyboardListener);
      window.removeEventListener("touchstart", MobileStartSession);
      window.removeEventListener("keydown", helpEvent);
    };
  }, []);
  useEffect(() => {
    if (prevState?.showSection !== showSection) {
      if (prevState?.showSection === 2) {
        if (
          fadeScreen.current &&
          fadeScreen.current.classList.contains("animate")
        ) {
          fadeScreen.current.classList.remove("animate");
        }
      }
    }
    if (learnState.sentenceIndex == 5 && learnState.perfect) {
      clearInterval(timer);
      if (!isUpdateBestScore) {
        const type = location.pathname.includes("numbers")
          ? "numbers"
          : "words";
        const length = numbers.includes(+params.id) ? +params.id : 3;
        const timeEnd = new Date();
        var timeTaken = timeEnd - startTestTime; //in ms

        // strip the ms
        timeTaken /= 1000;

        const dataToUpdate = {
          type,
          length,
          timeTaken,
        };
        firebase
          .firestore()
          .collection("bestScore")
          .add(dataToUpdate)
          .then((res) => {
            console.log("updated:", dataToUpdate);
            console.log("rse:", res);
            console.log("rse:", res.id);
            const docId = res.id;
            // store in local storage
            const personalBestFromLocal =
              JSON.parse(localStorage.getItem("personalBest")) ?? {};
            if (
              personalBestFromLocal &&
              personalBestFromLocal?.[type]?.[length]
            ) {
              if (
                +personalBestFromLocal?.[type]?.[length]?.timeTaken >
                +dataToUpdate.timeTaken
              ) {
                personalBestFromLocal[type] = personalBestFromLocal[type] ?? {};
                personalBestFromLocal[type][length] = {
                  ...dataToUpdate,
                  id: docId,
                };
                localStorage.setItem(
                  "personalBest",
                  JSON.stringify(personalBestFromLocal)
                );
              }
            } else {
              personalBestFromLocal[type] = personalBestFromLocal[type] ?? {};
              personalBestFromLocal[type][length] = {
                ...dataToUpdate,
                id: docId,
              };
              localStorage.setItem(
                "personalBest",
                JSON.stringify(personalBestFromLocal)
              );
            }
            dispatch(setSentences([]));
            navigate(`/reversed?type=${type}&length=${length}`);
          })
          .catch((error) => {
            console.log("error:", error);
          });

        setIsUpdateBestScore(true);
      }
    }
  }, [learnState, props]);

  const readyLearningSession = () => {
    if (!learnState.hideReady) {
      setLearnState((state) => {
        return { ...state, hideReady: true };
      });
    }
    fadeScreen.current && fadeScreen.current.classList.remove("animate");
    if (learnState.sentenceIndex == 0) {
      setTimeout(() => {
        fadeScreen.current && fadeScreen.current.classList.add("animate");
        setTimeout(() => {
          setShowSection(2);
          setLearnState((state) => {
            return { ...state, showSection: 2 };
          });
          calculateFlashTiming();
        }, 400);
      }, learnState.flashBannerTiming - 400);
    } else {
      fadeScreen.current && fadeScreen.current.classList.add("animate");
      setShowSection(2);
      setLearnState((state) => {
        return { ...state, showSection: 2 };
      });
      calculateFlashTiming();
    }
    if (window.innerWidth > 600) {
      window.removeEventListener("keypress", StartSession);
    } else {
      window.removeEventListener("touchstart", MobileStartSession);
    }
  };
  const helpEvent = (zEvent) => {
    if (zEvent.altKey && (zEvent.key === "h" || zEvent.key === "H")) {
      getHELP();
    }
  };

  const getHELP = async () => {
    let originalInput = [];
    for (let i = 0; i < inputWord.length; i++)
      originalInput.push({
        value: inputWord[i],
        color: "black",
      });
    setLearnState((state) => {
      return {
        ...state,
        showHelp: true,
        help: originalInput,
        helpcount: state.helpcount + 1,
      };
    });
    const wait = (timeout) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, timeout);
      });
    let i = 0;
    let mistake = false;
    while (i < inputWord.length) {
      if (learnState.selectedNote[i] === inputWord[i]) {
        originalInput[i] = {
          value: inputWord[i],
          color: "#5675cc",
        };
        setLearnState((state) => {
          return { ...state, showHelp: true, help: originalInput };
        });
        await wait(200);
      } else if (
        learnState.selectedNote[i] === inputWord[i].toLowerCase() ||
        learnState.selectedNote[i] === inputWord[i].toUpperCase()
      ) {
        mistake = true;
        originalInput[i] = {
          value: inputWord[i],
          color: "#cc566f",
        };
        setLearnState((state) => {
          return (
            { ...state, showHelp: true, help: originalInput },
            async () => {
              await wait(4000);
              setLearnState((state) => {
                return {
                  ...state,
                  showHelp: false,
                  help: [],
                };
              });
            }
          );
        });
        await wait(200);
        break;
      } else {
        mistake = true;
        originalInput[i] = {
          value: inputWord[i],
          color: "#cc566f",
        };
        setLearnState((state) => {
          return (
            { ...state, showHelp: true, help: originalInput },
            async () => {
              await wait(4000);
              setLearnState((state) => {
                return {
                  ...state,
                  showHelp: false,
                  help: [],
                };
              });
            }
          );
        });
        await wait(200);
        break;
      }
      i++;
    }
    if (!mistake) {
      setLearnState((state) => {
        return {
          ...state,
          showHelp: true,
          help: [
            {
              value: `No mistake, just finish writing! :)`,
              // color: "",
            },
          ],
        };
      });
      await wait(4000);
      setLearnState((state) => {
        return {
          ...state,
          showHelp: false,
          help: [],
        };
      });
    }
  };
  const checkInputColor = (enableColor) => {
    const { selectedNote } = learnState;
    let inputStyle = {
      color: "black",
    };
    if (enableColor && selectedNote.startsWith(inputWord)) {
      inputStyle.color = "blue";
    }
    setLearnState({ ...learnState, inputStyle });
  };

  const StartSession = (e) => {
    if (e.keyCode === 13) {
      blinker.current && blinker.current.classList.add("blinkAnimation");
      timeoutx = setTimeout(() => {
        if (
          blinker.current &&
          blinker.current.classList.contains("blinkAnimation")
        ) {
          blinker.current && blinker.current.classList.remove("blinkAnimation");
        }
        readyLearningSession();
        setStartTestTime(new Date());
      }, 1200);
    }
  };
  const MobileStartSession = () => {
    readyLearningSession();
    setStartTestTime(new Date());
  };
  const calculateFlashTiming = () => {
    const { sentences, sentenceIndex } = learnState;
    let time = sentences?.[sentenceIndex]?.sentence.length * 30;
    let flash_time = time > 300 ? time : 300;
    // const timeoutx =
    setTimeout(() => {
      if (showSection != 3) {
        setShowSection(3);
      }
      setInputWord("");

      setLearnState((state) => {
        return {
          ...state,
          showSection: 3,
          clickedReady: true,
          inputHidden: false,
          hideReady: true,
          perfect: false,
          inputWord: "",
          showWord: false,
          countsec: flash_time,
          in: state.in + 1,
          selectedNote: state.sentences?.[sentenceIndex]?.sentence,
        };
      });
      clearInterval(timer);
      // setTimer(setInterval(() => {
      //     setLearnState(state => {
      //         return {
      //             ...state,
      //         }
      //     });
      // }, 15))
    }, flash_time + 300); // milliseconds on each word in flash
  };

  const handleInputWord = (e) => {
    const { sentenceIndex, selectedNote, count, enableColor } = JSON.parse(
      JSON.stringify(learnState)
    );

    const sentences = [...learnState.sentences];
    //Here is the color validaiton(don't touch anything here)
    let inputStyle = {
      color: "black",
    };
    let inputText = e.target.value;
    setInputWord(inputText);
    if (
      enableColor &&
      selectedNote
        .replaceAll(/\s{2,}/g, " ")
        .trim()
        .startsWith(inputText.trim())
    ) {
      inputStyle = {
        color: "blue",
      };
    } else {
      inputStyle = {
        color: "black",
      };
    }

    if (inputStyle.color === "blue") {
      // play("PurrrSound");
    } else {
      // play("CatScreaming");
    }
    console.log("ïnputText:", inputText);
    console.log("selectedNote:", selectedNote);

    if (
      inputText.trim().split("").reverse().join("") ===
      sentences[learnState.sentenceIndex].sentence
        .replaceAll(/\s{2,}/g, " ")
        .trim()
    ) {
      let index = sentenceIndex + 1;
      let isFlashUsed = count > 1;
      if (isFlashUsed && props.mode === "memory") {
        const repeatSentence = sentences.splice(sentenceIndex, 1)[0];
        repeatSentence.mastered = false;
        repeatSentence.tried = true;
        sentences.push(repeatSentence);
        index -= 1;
        // play("GoodSound");
      } else if (sentences[sentenceIndex]) {
        sentences[sentenceIndex].mastered = true;
        sentences[sentenceIndex].tried = true;
        // play("MasteredSound");
      }
      if (showSection != 4) {
        setShowSection(4);
      }

      setLearnState((state) => {
        return {
          ...state,
          perfect: true,
          inputWord: inputText,
          showSection: 4,
          sentences,
          sentenceIndex: index,
          againSentenceMessage: isFlashUsed,
          inputStyle,
          skippedSentence: false,
        };
      });

      clearInterval(timer);
      enableWindowKeyboardListener();
      // next sentence here
      if (sentenceIndex < 4) {
        // e.currentTarget.classList.add("quickBlink");
        // setTimeout(() => {
        // nextSentence();
        // }, 300);
      }
    } else {
      setLearnState((state) => {
        return {
          ...state,
          againHidden: false,
          inputWord: inputText,
          inputStyle,
        };
      });
    }
  };
  const SkipSentence = () => {};
  const handleAgain = () => {
    fadeScreen.current && fadeScreen.current.classList.add("animate");
    var inc = learnState.countsec + 300;
    timeoutx = setTimeout(() => {
      setShowSection(3);
      setLearnState((state) => {
        return {
          ...state,
          showSection: 3,
        };
      });
    }, inc);
    setLearnState((state) => {
      setShowSection(2);

      return {
        ...state,
        count: state.count + 1,
        countsec: inc,
        showSection: 2,
      };
    });
  };

  const resetState = () => {
    setShowSection(1);
    setInputWord("");
    setLearnState({
      ...learnState,
      selectedNote: "",
      clickedReady: false,
      inputWord: "",
      count: 1,
      perfect: false,
      inputHidden: true,
      againHidden: true,
      showWord: false,

      countsec: 0,
      spans: [],
      showSection: 1,
      flashCount: 1,
      againSentenceMessage: false,
      flash: false,
    });
    readyLearningSession();
  };

  const enableWindowKeyboardListener = () => {
    window.addEventListener("keydown", windowKeyboardListener);
  };
  const windowKeyboardListener = (event) => {
    return;
  };

  const disableWindowKeyboardListener = () => {
    window.removeEventListener("keydown", windowKeyboardListener);
  };

  const handleKeyDown = (e) => {
    var input = e.target;
    var val = input.value;
    var end = input.selectionEnd;
    if (e.keyCode == 32 && (val[end - 1] == " " || val[end] == " ")) {
      e.preventDefault();
      return false;
    }
  };
  const nextSentence = (e) => {
    resetState();
    disableWindowKeyboardListener();
    clearInterval(timer);
  };
  const youLearnItButtonPressed = () => {
    navigate(-1);
  };

  let masteredCount = 0;
  learnState.sentences.forEach((obj) => {
    if (obj.mastered) ++masteredCount;
  });
  console.log(learnState.sentences[learnState.sentenceIndex]);
  return (
    <div
      className="min-vh-100 animate bodyColor"
      style={{ transition: "all .3s ease-in-out" }}
      ref={fadeScreen}
    >
      <div
        className="d-flex container justify-content-center align-items-center flex-column min-vh-100"
        onMouseMove={() => {
          if (learnState.hideAll) {
            setLearnState({ ...learnState, hideAll: false });
          }
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column gap-5">
          {!learnState.startLearning && (
            <StarterTextSection StartSession={StartSession} {...learnState} />
          )}
        </div>

        {/* First flash section */}
        <FlashSection
          opacity={
            showSection === 1 &&
            learnState.hideReady &&
            learnState.flashCount === 1
              ? 1
              : 0
          }
        />
        {/* Question section */}
        {showSection === 2 && learnState.hideReady && (
          <QuestionSection
            fontSize={getFontSize(
              learnState.sentences[learnState.sentenceIndex].sentence
            )}
            {...learnState}
          />
        )}
        {/* Count Section */}
        {learnState.hideReady && (
          <CountSection
            learnState={learnState}
            checkInputColor={checkInputColor}
            setLearnState={setLearnState}
            handleAgain={handleAgain}
            getHELP={getHELP}
          />
        )}
        {/* Answering section */}
        {showSection === 3 && learnState.hideReady && (
          <AnswerSection
            fontSize={getFontSize(inputWord)}
            SkipSentence={SkipSentence}
            handleInputWord={handleInputWord}
            handleKeyDown={handleKeyDown}
            inputWord={inputWord}
            learnState={learnState}
            checkInputColor={checkInputColor}
            setLearnState={setLearnState}
            handleAgain={handleAgain}
            sentenceIndex={learnState.sentenceIndex}
          />
        )}
        {learnState.perfect && learnState.hideReady && (
          <TooltipSection
            masteredCount={masteredCount}
            learnState={learnState}
            blinker={blinker}
            inputWord={inputWord}
            nextSentence={nextSentence}
            props={props}
          />
        )}
      </div>
    </div>
  );
};
export default LearningSession;
