import * as constants from "./constants";
import firebase from "@config/firebaseConfig";
import { toast } from "react-hot-toast";
const auth = firebase.auth();
const db = firebase.firestore();
const login =
  ({ email, password, callback }) =>
  async (dispatch) => {
    try {
      dispatch({ type: constants.SIGN_IN_REQUEST });
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      const userRef = await db.collection("users").doc(user.uid).get();

      dispatch({
        type: constants.SIGN_IN_SUCCESS,
        payload: { ...user, ...userRef.data() },
      });
      const lastLogin = firebase.firestore.FieldValue.serverTimestamp();
      await db
        .collection("users")
        .doc(user.uid)
        .set({ lastLogin }, { merge: true });
      toast.success("Logged in successfully");
      if (callback) callback();
    } catch (error) {
      dispatch({ type: constants.SIGN_IN_FAILURE, payload: error });
      console.log("login failed");
    }
  };

const signUp =
  ({ email, password, name, callback }) =>
  async (dispatch) => {
    try {
      dispatch({ type: constants.SIGN_UP_REQUEST });
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await db
        .collection("users")
        .doc(user.uid)
        .set({
          name,
          role: "user",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          email,
          description: "",
          socialLink: {
            platform: "",
            name: "",
          },
        });
      await user.updateProfile({ displayName: name });

      const collectionName = name + "'s Collection";
      const collectionData = {
        name: collectionName,
        value: collectionName.replaceAll(" ", "-").toLowerCase(),
        createdBy: user.uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        description: "",
        language: "English",
        type: "private",
        isEditAble: false,
        isDefault: true,
      };
      console.log("Add Collection Data Register", collectionData);
      await db
        .collection("collections")
        .add(collectionData)
        .then((res) => {
          console.log("res.id", res.id);
          console.log("New Collection Data", {
            id: res.id,
            ...collectionData,
          });
        })
        .catch((err) => {
          console.log("Error in creating collection", err);
          console.log("Error in creating collection", err.message);
          toast.error("Error in creating collection");
        });
      toast.success("Account created successfully");

      dispatch({
        type: constants.SIGN_UP_SUCCESS,
        payload: { ...user, name, role: "user" },
      });
      if (callback) callback();
    } catch (error) {
      dispatch({ type: constants.SIGN_UP_FAILURE, payload: error });
    }
  };

const signOut =
  ({ callback }) =>
  async (dispatch) => {
    try {
      await auth.signOut();
      toast.success("Logged out successfully");
      dispatch({ type: constants.SIGN_OUT });
      if (callback) callback();
    } catch (error) {
      console.log(error);
    }
  };

const getUser = () => async (dispatch) => {
  try {
    dispatch({ type: constants.GET_USER_REQUEST });
    const user = auth.currentUser;
    const userRef = await db.collection("users").doc(user.uid).get();
    toast.success("User fetched successfully");
    console.log("User Data", userRef.data());
    dispatch({
      type: constants.GET_USER_SUCCESS,
      payload: { ...user, ...userRef.data() },
    });
  } catch (error) {
    dispatch({ type: constants.GET_USER_FAILURE, payload: error });
  }
};

const attachAuthListener = () => async (dispatch) => {
  try {
    dispatch({ type: constants.AUTH_LISTENER_REQUEST });
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = await db.collection("users").doc(user.uid).get();
        dispatch({
          type: constants.AUTH_LISTENER_SUCCESS,
          payload: { ...user, ...userRef.data() },
        });
        const lastSeen = firebase.firestore.FieldValue.serverTimestamp();
        await db
          .collection("users")
          .doc(user.uid)
          .set({ lastSeen }, { merge: true });
      } else {
        dispatch({ type: constants.SIGN_OUT });
      }
    });
  } catch (error) {
    dispatch({ type: constants.AUTH_LISTENER_FAILURE, payload: error });
    dispatch({ type: constants.SIGN_OUT });
  }
};

const updateUser = (profileData) => async (dispatch) => {
  try {
    dispatch({ type: constants.UPDATE_USER_REQUEST });
    const user = auth.currentUser;
    await db
      .collection("users")
      .doc(user.uid)
      .set({ ...profileData });
    await user.updateProfile({ displayName: profileData.name });
    dispatch({
      type: constants.UPDATE_USER_SUCCESS,
      payload: { ...user, ...profileData },
    });
    toast.success("Profile updated successfully");
  } catch (error) {
    dispatch({ type: constants.UPDATE_USER_FAILURE, payload: error });
  }
};

const deleteAccount =
  ({ callback }) =>
  async (dispatch) => {
    dispatch({ type: constants.DELETE_ACCOUNT_REQUEST });
    const user = auth.currentUser;
    const userId = user.uid;
    let accountDeleted = false;

    await user
      .delete()
      .then(() => {
        console.log("Account deleted successfully");
        toast.success("Account deleted successfully");
        dispatch({ type: constants.DELETE_ACCOUNT_SUCCESS });
        accountDeleted = true;
        if (callback) callback();
      })
      .catch((error) => {
        console.log("Error in deleting account", error);
        toast.error("Error deleting account");
        dispatch({
          type: constants.DELETE_ACCOUNT_FAILURE,
          payload: error,
        });
      })
      .finally(async () => {
        console.log("Account deleted finally");
        if (accountDeleted) {
          await db
            .collection("users")
            .doc(userId)
            .delete()
            .then(() => {
              console.log("Profile Deleted Successfully");
            })
            .catch((error) => {
              console.log("Error in deleting profile", error);
            });
        }
      });
  };

const userActions = {
  login,
  signUp,
  signOut,
  getUser,
  attachAuthListener,
  updateUser,
  deleteAccount,
};

export default userActions;
