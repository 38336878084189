import { Col as MDBCol, Row as MDBRow } from "react-bootstrap";
import React from "react";
import { lanuageArr } from "../../../helpers/lanuages";
import { Header } from "../../Header";

export default function FormForLanguagesAndSearch({
  language,
  search,
  setSearch,
  setLanguage,
  hideHeader,
  hideLanguage,
}) {
  return (
    <MDBRow className="justify-content-center mb-4">
      <MDBCol sm={6} md={6}>
        {hideHeader === true ? null : (
          <Header
            title={"Our Content"}
            tagline={
              <>
                Here are shorter sets created by other people. <br /> Choose
                what you like and keep training.
              </>
            }
          />
        )}
        <div className={"mt-4"}>
          {/* language selection */}
          <form>
            <div className="mb-3">
              <input
                type="text"
                id="search"
                name="search"
                className="custom-input w-100 p bg-transparent form-control border-dark text-dark shadow-none text-center rounded-pill"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {hideLanguage === true ? null : (
              <div className="d-flex justify-content-center align-items-center gap-2">
                <label className="m-0">Filter:</label>
                <select
                  className="custom-select bg-transparent border-0 text-black shadow-none"
                  style={{ width: "fit-content" }}
                  id="language"
                  name="language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="">All languages</option>
                  {lanuageArr.map((language) => {
                    return (
                      <option value={language} key={language}>
                        {language}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </form>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
