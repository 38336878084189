import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./auth/reducer";
import sentencesReducer from "./sentences/reducer";
import dashboardReducer from "./dashboard/reducers";
import collectionsReducer from "./collections/reducer";
import { colorReducer } from "./color";
const auth = persistReducer(
  {
    key: "auth",
    storage,
  },
  userReducer
);
const middleware = [thunkMiddleware];
const store = configureStore({
  reducer: {
    sentences: sentencesReducer,
    auth,
    dashboard: dashboardReducer,
    color: colorReducer,
    collections: collectionsReducer,
  },
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };
