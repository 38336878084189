import { styled } from "@mui/material";
import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Heading = styled("h1")(({ theme, smallHeading }) => ({
  fontSize: smallHeading ? "1.5rem" : "2.5rem",
  letterSpacing: "0.4rem",
  fontWeight: smallHeading ? 500 : 700,
  margin: "0",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: smallHeading ? "1.5rem" : "1.75rem",
    letterSpacing: "0.3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: smallHeading ? "1.25rem" : "1.5rem",
    letterSpacing: "0.2rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: smallHeading ? "1.25rem" : "1.5rem",
    letterSpacing: "0.1rem",
  },
  // "@media (min-width: 650px)": {
  //   paddingTop: `1rem`,
  // },
  // "@media (min-height: 780px)": {
  //   paddingTop: `6rem`,
  // },
}));

export const Header = ({
  link,
  showUserInfo,
  title,
  tagline,
  smallHeading,
}) => {
  const route = window.location.pathname;
  const { isAuth, user } = useSelector((s) => s.auth);

  return (
    <>
      {/* {showUserInfo && (
        <div className="text-center mt-4">
          <Link
            to={isAuth ? "/profile" : "/login"}
            className="text-dark text-decoration-none text-uppercase"
          >
            {isAuth ? user?.name || "no username" : "Guest"}
          </Link>
        </div>
      )} */}

      <div className="position-relative d-flex justify-content-center align-items-center mt-4">
        {link && (
          <Link to={link} className="position-absolute start-0 bottom-0">
            <FiChevronLeft
              className="h1 text-dark m-0"
              size={smallHeading ? 32 : null}
            />
          </Link>
        )}

        <Heading smallHeading={smallHeading}>{title}</Heading>
      </div>

      {/* add a condition if route is /collections or /public only then show this */}
      {
        // route === "/collections" ||
        route === "/public" ? (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="mx-2">
              <Link to="/collections">
                <button
                  className={
                    route === "/collections" ? "small_button" : "big_button"
                  }
                >
                  BIG
                </button>
              </Link>
            </div>
            <div className="mx-2">
              <Link to="/public">
                <button
                  className={
                    route === "/public" ? "small_button" : "big_button"
                  }
                >
                  SMALL
                </button>
              </Link>
            </div>
          </div>
        ) : null
      }

      {tagline && (
        <div
          key={Math.random()}
          style={{
            letterSpacing: "1px",
          }}
          className="text-center mb-3 mt-4"
        >
          {tagline}
        </div>
      )}
    </>
  );
};
