import "./LearningSession.css";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSentences, setMode } from "@store/sentences/action";
import { useNavigate } from "react-router-dom";
import firebase from "@config/firebaseConfig";
import FlashSection from "@components/LearnPageComponents/FlashSection";
import QuestionSection from "@components/LearnPageComponents/QuestionSection";
import StarterTextSection from "@components/LearnPageComponents/StarterTextSection";
import CountSection from "@components/LearnPageComponents/CountSection";
import AnswerSection from "@components/LearnPageComponents/AnswerSection";
import TooltipSection from "@components/LearnPageComponents/TooltipSection";
import { closeSVG, fullScreenSVG } from "@components/svgComponents/Svgs";
import ReactTooltip from "react-tooltip";
import { Button as MDBBtn } from "react-bootstrap";
import { set } from "react-hook-form";

let timeoutx;
let prevTip = "";

const wait = (timeout) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, timeout);
  });

const getRandomElementFromArray = (array) => {
  let tip = array[Math.floor(Math.random() * array.length)];
  if (tip === prevTip) {
    return getRandomElementFromArray(array);
  }
  prevTip = tip;
  return tip;
};

export const getFontSize = (text) => {
  const f = 6.66666666666666666667 - text?.length / 16;
  return f > 1.5 ? f : 1.5;
};

const LearningSession = () => {
  // refs
  const blinker = useRef(null);
  const fadeScreen = useRef(null);
  const incRef = useRef(null);
  // navigation
  const navigate = useNavigate();
  // redux hooks
  const sentencesReducer = useSelector((state) => state.sentences.sentences);
  const mode = useSelector((state) => state.sentences.mode);
  const id = useSelector((state) => state.sentences.id);
  const { uid } = useSelector((state) => state.auth);
  const [collectionData, setCollectionData] = useState({});
  const [setData, setSetData] = useState({});
  const [collectionSetList, setCollectionSetList] = useState([]);
  const [bigSize, setBigSize] = useState(false);
  const [helpCount, setHelpCount] = useState(0);

  const dispatch = useDispatch();
  const props = {
    sentences: sentencesReducer,
    mode,
    id,
    // setMode: dispatchSetMode,
    // setSentences: dispatchSetSentences
  };

  //   state
  const [timer, setTimer] = useState(null);
  const [inputWord, setInputWord] = useState("");
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSection, setShowSection] = useState(1);
  const [hideReady, setHideReady] = useState(false);
  const [perfect, setPerfect] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const [updateCount, setUpdateCount] = useState(false);
  const [skipCount, setSkipCount] = useState(0);
  const [showCountSection, setShowCountSection] = useState(false);
  const [startLearning, setStartLearning] = useState(true);
  const [hideSvgOnFlash, setHideSvgOnFlash] = useState(true);
  const [svgSkipCick, setSvgSkipCick] = useState(true);
  const [tooltip, showTooltip] = useState(true);

  const [learnState, setLearnState] = useState({
    FullScreen: false,
    sentences: [], // separated sentences
    showHelp: false,
    help: [],
    per: ["perfect", "amazing", "flawless"],
    FSwidth: 0,
    //Here is the counter of flashes and length of the first flash states
    count: 1,
    countsec: 0,
    inputHidden: true,
    againHidden: true,
    showWord: false,
    timerTime: 0,
    color: "white",
    in: 0,
    te: false,
    flashCount: 1,
    helpcount: 0,
    // skipCount: 0,
    againSentenceMessage: false,
    flashBannerTiming: 1000, // Flash one don't blink text banner timing
    textToLearn: "",
    enableColor: false,
    enableSound: false,
    playSound: false,
    sentenceSkipped: 0,
    hideAll: true,
    spellCheck: false,
    flash: false,
    skippedSentence: false,
    doneCoundIncrement: false,
  });

  const [helpText, setHelpText] = useState("");
  const [revealedIndices, setRevealedIndices] = useState(new Set());
  function maskText(text = "") {
    const myHelpCount = learnState.count - 3;
    if (!text) return text;
    const length = text.length;
    const revealCount = Math.ceil(
      length * Math.min(0.2 * (myHelpCount + 1), 1)
    );

    const newRevealedIndices = new Set(revealedIndices);
    while (newRevealedIndices.size < revealCount) {
      const randomIndex = Math.floor(Math.random() * length);
      newRevealedIndices.add(randomIndex);
    }

    setRevealedIndices(newRevealedIndices);
    setHelpText(
      text
        .split("")
        .map((char, index) =>
          newRevealedIndices.has(index)
            ? char
            : char.replace(/[a-zA-Z0-9]/g, "_")
        )
        .join("")
    );
  }

  useEffect(() => {
    if (props.sentences.length && sentenceIndex == 0) {
      if (window.innerWidth > 600) {
        window.addEventListener("keydown", StartSession);
      } else {
        window.addEventListener("touchstart", MobileStartSession);
      }
    } else {
      window.removeEventListener("keydown", StartSession);
      window.removeEventListener("keydown", windowKeyboardListener);
      window.removeEventListener("touchstart", MobileStartSession);
    }
    return () => {
      window.removeEventListener("keydown", windowKeyboardListener);
      window.removeEventListener("touchstart", MobileStartSession);
      // window.removeEventListener("keydown", helpEvent);
    };
  }, []);

  useEffect(() => {
    const { sentences, id } = props;
    console.log("Use Effect Id, sentance", id, sentences);
    if (id) {
      firebase
        .firestore()
        .collection("sets")
        .doc(id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            console.log("Set Data", doc.data());
            setSetData({ ...doc.data(), id: doc.id });
            getSetsFromCollection(doc.data().collectionName);
            await firebase
              .firestore()
              .collection("collections")
              .doc(doc.data().collectionName)
              .get()
              .then((doc) => {
                console.log("Collection Data", doc.data());
                setCollectionData({ ...doc.data(), id: doc.id });
              })
              .catch((err) => {
                console.log("Error in getting collection", err);
              });
          }
        });
    }
    // window.addEventListener("keydown", helpEvent);
    if (sentences && sentences.length > 0) {
      const sets = JSON.parse(localStorage.getItem("sets")) || [];

      const currentSet = sets.find((set) => set.id === id && set.id !== null);
      console.log("currentSet", currentSet);
      if (currentSet) {
        if (currentSet.complete < 100) {
          if (currentSet.sentences.length <= sentences.length) {
            setSentenceIndex(currentSet.sentenceIndex);
            setLearnState((state) => ({
              ...state,
              sentanceIndex: currentSet.sentenceIndex,
            }));
          }
        }
      }

      setLearnState((state) => ({
        ...state,
        sentences: removeTabsDoubleSpaces(sentences) || [],
      }));
      setStartLearning(false);
      // get sentences from local storage
    } else {
      navigate("/");
    }
    return () => {
      clearTimeout(window.timeout);
      clearTimeout(window.timeout2);
      clearTimeout(timeoutx);
      clearInterval(timer);
    };
  }, []);

  const getSetsFromCollection = async (collectionName) => {
    console.log("getSetsFromCollection");
    setCollectionSetList([]);
    await firebase
      .firestore()
      .collection("sets")
      .where("collectionName", "==", collectionName)

      .get()
      .then((querySnapshot) => {
        const mySet = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          mySet.push({ ...doc.data(), id: doc.id });
        });
        setCollectionSetList(mySet);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        setCollectionSetList([]);
      });
  };

  useEffect(() => {
    if (
      !updateCount &&
      +sentenceIndex == +props.sentences.length - 1 &&
      perfect &&
      props.id
    ) {
      const stats = {
        doneCount: firebase.firestore.FieldValue.increment(1),
        lastCompleted: firebase.firestore.FieldValue.serverTimestamp(),
      };
      if (uid) {
        stats.completedBy = firebase.firestore.FieldValue.arrayUnion(uid);
        stats.lastCompletedBy = uid;
      }
      firebase.firestore().collection("sets").doc(props.id).update(stats);
      setUpdateCount(true);
    }
  }, [learnState, sentenceIndex]);
  useEffect(() => {
    if (showSection === 3) {
      if (
        fadeScreen.current &&
        fadeScreen.current.classList.contains("animate")
      ) {
        fadeScreen.current.classList.remove("animate");
      }
    }
  }, [showSection]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // navigate("/");
        navigate(-1);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    if (!hideSvgOnFlash) {
      const hideTimeout = setTimeout(() => {
        setHideSvgOnFlash(true);
      }, incRef.current);

      return () => {
        clearTimeout(hideTimeout);
      };
    }
  }, [hideSvgOnFlash]);

  // methods
  const handleFullScreenToggle = () => {
    if (!isFullScreen) {
      // Enter fullscreen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // Edge
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullScreen(!isFullScreen);
  };
  const readyLearningSession = () => {
    if (!hideReady) {
      setHideReady(true);
    }
    fadeScreen.current && fadeScreen.current.classList.remove("animate");
    setTimeout(() => {
      fadeScreen.current && fadeScreen.current.classList.add("animate");
      setTimeout(() => {
        setShowSection(2);
        calculateFlashTiming();
      }, 400);
    }, learnState.flashBannerTiming - 400);
  };

  const removeTabsDoubleSpaces = (inputArray) => {
    return inputArray
      .reduce((acc, cur) => {
        const str = cur.sentence;
        const newStr = str
          .toString()
          .replace(/\s{2,}/g, " ")
          .replace(/\t/g, " ")
          .trim()
          .replace(/(\r\n|\n|\r)/g, "")
          .trim();
        acc.push({ ...cur, sentence: newStr });
        return acc;
      }, [])
      .filter((item) => item.sentence.length !== 0);
  };
  const getHELP = async () => {
    let originalInput = [];
    for (let i = 0; i < inputWord.length; i++)
      originalInput.push({
        value: inputWord[i],
        color: "black",
      });
    setLearnState((state) => {
      return {
        ...state,
        showHelp: true,
        help: originalInput,
        helpcount: state.helpcount + 1,
      };
    });
    let i = 0;
    let mistake = false;
    console.log("helpcount", learnState.helpcount, i, inputWord.length);
    while (i < inputWord.length) {
      if (selectedNote[i] === inputWord[i]) {
        originalInput[i] = {
          value: inputWord[i],
          color: "#5675cc",
        };
        setLearnState((state) => {
          return { ...state, showHelp: true, help: originalInput };
        });
        await wait(200);
      } else {
        mistake = true;
        originalInput[i] = {
          value: inputWord[i],
          color: "#cc566f",
        };
        setLearnState((state) => {
          return { ...state, showHelp: true, help: originalInput };
        });
        await wait(2500);
        setLearnState((state) => {
          return {
            ...state,
            showHelp: false,
            help: [],
          };
        });
        await wait(200);
        break;
      }
      i++;
    }
    if (!mistake) {
      setLearnState((state) => {
        return {
          ...state,
          showHelp: true,
          help: [
            {
              value: `No mistake, just finish writing! :)`,
              // color: "",
              fontSize: `2.5rem`,
            },
          ],
        };
      });
      await wait(2500);
      setLearnState((state) => {
        return {
          ...state,
          showHelp: false,
          help: [],
        };
      });
    }
  };

  var StartSession = useCallback((e) => {
    if (e.keyCode === 13) {
      window.removeEventListener("keydown", StartSession);
      readyLearningSession();
    }
  }, []);
  var MobileStartSession = useCallback((e) => {
    window.removeEventListener("touchstart", MobileStartSession);
    readyLearningSession();
  }, []);

  const calculateFlashTiming = () => {
    const { sentences } = learnState;
    let time = sentences?.[sentenceIndex]?.sentence.length * 30;
    let flash_time = time > 300 ? time : 300;
    // const timeoutx =
    setTimeout(() => {
      setShowSection(3);
      setInputWord("");
      setPerfect(false);
      setSelectedNote(props.sentences?.[sentenceIndex]?.sentence);
      setLearnState((state) => {
        return {
          ...state,
          clickedReady: true,
          selectedEnd: state.per[Math.floor(Math.random() * state.per.length)],
          inputHidden: false,
          hideReady: true,
          showWord: false,
          countsec: flash_time,
          in: state.in + 1,
        };
      });
      clearInterval(timer);
    }, flash_time + 300);
  };

  const handleInputWord = (e) => {
    const sentences = [...learnState.sentences];
    let inputText = e.target.value;
    setInputWord(inputText);
    if (
      inputText.trim() ===
      sentences[sentenceIndex].sentence.replaceAll(/\s{2,}/g, " ").trim()
    ) {
      let index = sentenceIndex + 1;
      const flashCount = learnState.count;
      const isFlashUsed = flashCount > 1;
      const isHelpUsed = learnState.helpcount > 0;
      if (isFlashUsed || isHelpUsed) {
        const repeatSentence = sentences.splice(sentenceIndex, 1)[0];
        repeatSentence.mastered = false;
        repeatSentence.tried = true;
        sentences.splice(sentenceIndex, 0, repeatSentence); // Insert at original index
        // index -= 1;
        // play("GoodSound");
      } else if (sentences[sentenceIndex]) {
        sentences[sentenceIndex].mastered = true;
        sentences[sentenceIndex].tried = false;
        // play("MasteredSound");
      }
      if (showSection != 4) {
        setShowSection(() => 4);
      }
      setSentenceIndex(() => index);
      setPerfect(() => true);
      setLearnState((state) => {
        return {
          ...state,
          sentences,
          sentenceIndex: index,
          againSentenceMessage: isFlashUsed,
          skippedSentence: false,
        };
      });

      clearInterval(timer);
      enableWindowKeyboardListener();

      handleSetLocalStorage();
    } else {
      setLearnState((state) => {
        return {
          ...state,
          againHidden: false,
        };
      });
    }
  };

  // Set Locat Storage
  const handleSetLocalStorage = () => {
    const index = sentenceIndex + 1;
    const sets = JSON.parse(localStorage.getItem("sets")) || [];
    const currentSet = sets.find((set) => set.id === props.id);
    console.log("currentSet", currentSet);
    if (currentSet) {
      currentSet.sentences = learnState.sentences;
      currentSet.sentenceIndex = index;
      currentSet.complete = parseFloat(
        ((index / learnState.sentences.length) * 100).toFixed(2)
      );
      sets[sets.indexOf(currentSet)] = currentSet;
      console.log("Updated Set", currentSet);
    } else {
      sets.push({
        id: props.id,
        sentences: learnState.sentences,
        sentenceIndex: index,
        complete: parseFloat(
          ((index / learnState.sentences.length) * 100).toFixed(2)
        ),
      });
    }

    localStorage.setItem("sets", JSON.stringify(sets));
  };
  const handleAgain = () => {
    setHideSvgOnFlash(false);
    fadeScreen.current && fadeScreen.current.classList.add("animate");
    incRef.current = learnState.countsec + 300;
    timeoutx = setTimeout(() => {
      setShowSection(() => 3);
    }, incRef.current);
    setLearnState((state) => {
      setShowSection(() => 2);
      return {
        ...state,
        count: state.count + 1,
        countsec: incRef.current,
      };
    });
    if (learnState.count > 2) {
      helpSentance();
    }
  };

  const resetState = () => {
    setShowSection(1);
    setInputWord("");
    setPerfect(false);
    setSelectedNote("");
    setHelpCount(0);
    setLearnState({
      ...learnState,
      clickedReady: false,
      count: 1,
      inputHidden: true,
      againHidden: true,
      showWord: false,
      timerTime: 0,
      countsec: 0,
      spans: [],
      flashCount: 1,
      helpcount: 0,
      againSentenceMessage: false,
      flash: false,
    });
    setHelpText("");
    setRevealedIndices(new Set());
    readyLearningSession();
  };

  const SkipSentence = () => {
    setSvgSkipCick(false);
    const repeatSentence = learnState.sentences.splice(sentenceIndex, 1)[0];
    repeatSentence.mastered = false;
    repeatSentence.tried = true;
    learnState.sentences.splice(sentenceIndex, 0, repeatSentence);

    if (showSection !== 3 && !perfect) return;
    let { sentences } = learnState;
    if (sentenceIndex < props.sentences.length) {
      setSentenceIndex(sentenceIndex + 1);
      setShowSection(4);
      setInputWord("");
      setPerfect(true);
      setSkipCount((skipCount) => skipCount + 1);
      setHelpCount(0);
      setLearnState({
        ...learnState,
        skippedSentence: sentences[sentenceIndex].sentence,
      });
      clearInterval(timer);
      disableWindowKeyboardListener();
      setTimeout(() => {
        enableWindowKeyboardListener();
      }, 100);
    }
  };

  const enableWindowKeyboardListener = () => {
    window.addEventListener("keydown", windowKeyboardListener);
  };
  const windowKeyboardListener = (event) => {
    if (event.keyCode === 13 && event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();
      // SkipSentence();
    } else if (event.keyCode === 13) {
      setSvgSkipCick(true);
      event.stopPropagation();
      event.preventDefault();
      blinker.current && blinker.current.classList.add("quickBlink");
      setTimeout(() => {
        if (
          props.sentences[sentenceIndex] &&
          sentenceIndex < props.sentences.length - 1
        ) {
          return nextSentence();
        } else {
          window.removeEventListener("keydown", windowKeyboardListener);
          dispatch(setSentences(props.sentences));
          youLearnItButtonPressed();
        }
      }, 300);
    }
  };

  const disableWindowKeyboardListener = () => {
    window.removeEventListener("keydown", windowKeyboardListener);
  };

  const handleKeyDown = (e) => {
    var input = e.target;
    var val = input.value;
    var end = input.selectionEnd;
    if (e.keyCode == 32 && (val[end - 1] == " " || val[end] == " ")) {
      e.preventDefault();
      return false;
    }
  };
  const nextSentence = (e) => {
    resetState();
    disableWindowKeyboardListener();
    clearInterval(timer);
  };
  const iterateTried = (e) => {
    resetState();
    disableWindowKeyboardListener();
    clearInterval(timer);
  };
  const youLearnItButtonPressed = () => {
    navigate(-1);
  };

  const masteredCount = useMemo(() => {
    return learnState.sentences?.reduce((acc, cur) => {
      if (cur.mastered) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [learnState.sentences]);

  const fontSize = useMemo(() => getFontSize(inputWord), [inputWord]);
  const wordFontSize = useMemo(
    () => getFontSize(learnState.sentences?.[sentenceIndex]?.sentence),
    [learnState.sentences, sentenceIndex]
  );
  useEffect(() => {
    if (
      (showSection !== 2 && showSection !== 3 && perfect && hideReady) ||
      (!startLearning && !hideReady) ||
      (showSection === 2 && hideReady)
    ) {
      fadeScreen.current && fadeScreen.current.classList.add("animate");
    } else {
      fadeScreen.current && fadeScreen.current.classList.remove("animate");
    }
  }, [showSection, perfect, hideReady, startLearning]);
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const helpSentance = () => {
    setHelpCount((perv) => perv + 1);
    maskText(props.sentences?.[sentenceIndex]?.sentence);
  };

  return (
    <>
      <div
        onKeyDown={(e) => {
          if (e.key === "ArrowDown") {
            e.preventDefault();
            SkipSentence();
          }
          if (e.key === "ArrowUp") {
            getHELP();
          }
        }}
        tabIndex="0"
        className="min-vh-100 bodyColor animate"
        ref={fadeScreen}
      >
        {/* <div
          className={`position-absolute  utility-button-wrapper ${
            showCountSection ? "fade-in" : "fade-out"
          }`}
        >
          {hideReady && showCountSection && hideSvgOnFlash && svgSkipCick && (
            <div className={`pt-3 ps-4`}>
              <MDBBtn
                style={{ cursor: "pointer", border: "none" }}
                className="bg-transparent shadow-none text-dark"
                onClick={() => {
                  navigate("/");
                }}
              >
                {closeSVG()}
              </MDBBtn>

              <MDBBtn
                style={{ cursor: "pointer", border: "none" }}
                className="bg-transparent shadow-none text-dark"
                onClick={handleFullScreenToggle}
              >
                {fullScreenSVG()}
              </MDBBtn>
            </div>
          )}
        </div> */}
        {
          // learnState?.sentences?.[sentenceIndex]?.tried &&
          showSection != 2 && showSection != 3 && perfect && hideReady ? (
            <TooltipSection
              sentenceIndex={sentenceIndex}
              masteredCount={masteredCount}
              learnState={learnState}
              blinker={blinker}
              inputWord={inputWord}
              nextSentence={nextSentence}
              skipCount={skipCount}
              props={props}
              fontSize={wordFontSize}
              setSvgSkipCick={setSvgSkipCick}
              iterateTried={iterateTried}
              handleFullScreenToggle={handleFullScreenToggle}
              collectionData={collectionData}
              setData={setData}
              collectionSetList={collectionSetList}
            />
          ) : (
            <div
              className="d-flex px-4 justify-content-center align-items-center flex-column min-vh-100"
              onMouseMove={() => {
                if (learnState.hideAll) {
                  setLearnState({ ...learnState, hideAll: false });
                }
              }}
            >
              <div className="d-flex justify-content-center align-items-center flex-column gap-5">
                {!startLearning && (
                  <StarterTextSection
                    startLearning={startLearning}
                    hint={true}
                    StartSession={StartSession}
                    bigSize={bigSize}
                    setBigSize={setBigSize}
                    hideReady={hideReady}
                    {...learnState}
                  />
                )}
              </div>
              {/* First flash section */}
              <FlashSection
                opacity={
                  showSection === 1 && hideReady && learnState.flashCount === 1
                    ? 1
                    : 0
                }
              />
              {/* Question section */}
              {showSection === 2 && hideReady && (
                <QuestionSection
                  sentenceIndex={sentenceIndex}
                  font={1.5}
                  sentences={learnState.sentences}
                  fontSize={wordFontSize}
                  bigSize={bigSize}
                />
              )}
              {/* Count Section */}

              {/* {hideReady && showCountSection && hideSvgOnFlash && (
            <CountSection
              hideReady={hideReady}
              learnState={learnState}
              setLearnState={setLearnState}
              handleAgain={handleAgain}
              getHELP={getHELP}
              SkipSentence={SkipSentence}
              masteredCount={masteredCount}
              skipCount={skipCount}
              perfect={perfect}
              sentenceIndex={sentenceIndex}
              inputWord={inputWord}
              sentence={learnState.sentences[sentenceIndex]}
              hideSvgOnFlash={hideSvgOnFlash}
            />
          )} */}
              {/* Answering section */}
              {showSection === 3 && hideReady && (
                <AnswerSection
                  SkipSentence={SkipSentence}
                  sentenceIndex={sentenceIndex}
                  handleInputWord={handleInputWord}
                  handleKeyDown={handleKeyDown}
                  inputWord={inputWord}
                  learnState={learnState}
                  setLearnState={setLearnState}
                  handleAgain={handleAgain}
                  getHELP={getHELP}
                  fontSize={fontSize}
                  showCountSection={showCountSection}
                  sentence={learnState.sentences[sentenceIndex]}
                  setShowCountSection={setShowCountSection}
                  showTooltip={showTooltip}
                  handleFullScreenToggle={handleFullScreenToggle}
                  helpCount={helpCount}
                  helpText={helpText}
                  // flashCount={flashCount}
                  // handleHelpSentance={helpSentance}
                />
              )}
            </div>
          )
        }
      </div>
      {tooltip && <ReactTooltip place="bottom" type="dark" effect="solid" />}
    </>
  );
};
export default LearningSession;
