import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import userActions from "@store/auth/actions";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Logo from "@utils/Logo";
import FlashSection from "@components/LearnPageComponents/FlashSection";
const SignUp = () => {
  const [showFlash, setShowFlash] = useState(true); // State to control flash screen visibility
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  const nameRef = React.useRef();
  const confirmPasswordRef = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowFlash(false); // Hide the flash screen after 2 seconds
    }, 1000);

    return () => clearTimeout(timeout); // Clear the timeout if component unmounts
  }, []);

  return (
    <div className="login-wrapper w-100 d-flex justify-content-center align-items-center">
      {/* {showFlash && <FlashSection opacity={1} />} */}
      <div className="login">
        <div className="background">
          <div className="shape" />
          <div className="shape" />
        </div>
        <form
          style={{
            width: "min(800px, 100%)",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            if (
              passwordRef.current.value !== confirmPasswordRef.current.value
            ) {
              toast.error("Passwords do not match");
              return;
            } else if (passwordRef.current.value.length < 6) {
              toast.error("Password must be at least 6 characters long");
              return;
            } else if (nameRef.current.value.length < 3) {
              toast.error("Name must be at least 3 characters long");
              return;
            } else if (/^[a-zA-Z ]*$/.test(nameRef.current.value) === false) {
              toast.error("Name must contain only letters");
              return;
            } else if (
              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                emailRef.current.value
              ) === false
            ) {
              toast.error("Email is not valid");
              return;
            }
            dispatch(
              userActions.signUp({
                email: emailRef.current.value,
                password: passwordRef.current.value,
                name: nameRef.current.value,
                callback: () => {
                  navigate("/");
                },
              })
            );
          }}
        >
          <div className="d-flex justify-content-evenly align-items-center mb-3">
            <div>
              <div
                className="bg-dark d-flex align-items-center justify-content-center rounded-pill"
                style={{
                  width: 120,
                  height: 120,
                }}
              >
                <Link to="/">
                  <Logo size={90} color="var(--bgColor)" />
                </Link>
              </div>
            </div>
            <div className="display-3 fw-normal px-3">Do Not Blink</div>
          </div>
          <h4>SignUp</h4>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="name">Name</label>
              <input ref={nameRef} type="text" placeholder="Name" id="name" />
            </div>
            <div className="col-md-6">
              <label htmlFor="email">Email</label>
              <input
                ref={emailRef}
                type="text"
                placeholder="Email"
                id="email"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="password">Password</label>
              <input
                ref={passwordRef}
                type="password"
                placeholder="Password"
                id="password"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                id="confirm-password"
                ref={confirmPasswordRef}
              />
            </div>
          </div>
          <button className="button">Sign Up</button>
          <div className="mt-3">
            Already Have an account?{" "}
            <Link to="/login" className="text-dark text-decoration-underline">
              Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
