import React from "react";
import {
  createBrowserRouter,
  Link,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Add as CreateIcon } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch } from "react-redux";
import userActions from "@store/auth/actions";
import { disabled, plural } from "@helpers/inputs";
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="container py-5">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Sr # </TableCell>
              <TableCell>Go To</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <h3>Values</h3>
              </TableCell>
            </TableRow>
            {Object.entries(plural).map(([key, value], i) => {
              return (
                <TableRow key={i}>
                  <TableCell component="th"> {i + 1} </TableCell>
                  <TableCell className="text-capitalize"> {value} </TableCell>
                  <TableCell>
                    <ButtonGroup className="btn-group">
                      {/* Button to Delete the Exercise */}
                      <Button
                        variant="contained"
                        component={Link}
                        to={`/dashboard/${key}/preview`}
                        color="primary"
                        className="text-white"
                        style={{
                          backgroundColor: `var(--bgColor)`,
                        }}
                      >
                        <VisibilityIcon />
                      </Button>
                      {disabled[key].add ? null : (
                        <Button
                          color="inherit"
                          variant="contained"
                          component={Link}
                          to={`/dashboard/${key}/create`}
                        >
                          <CreateIcon />
                        </Button>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="p-3">
          <Button
            onClick={() => {
              dispatch(
                userActions.signOut({
                  callback: () => {
                    navigate("/");
                  },
                })
              );
            }}
            color="error"
            variant="contained"
          >
            Logout
          </Button>
        </div>
      </TableContainer>
    </div>
  );
};

export default Dashboard;
