import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon } from "@components/svgComponents/Svgs";
import { Link } from "react-router-dom";
import collectionActions from "@store/collections/action";
import { CollectionSetComponent } from "./CollectionSetComponent";

export const CollectionComponent = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { collections, loading } = useSelector((state) => state.collections);
  const { uid, isCollector, isAdmin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getCollections = useCallback(async () => {
    console.log("Get COllection Function Run");

    dispatch(collectionActions.getCollectionsData(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  const [search, setSearch] = useState("");

  return (
    <div className="mt-5 text-center">
      {!loading && (
        <div className="mb-4  max-width-300px mx-auto">
          <input
            type="text"
            id="search"
            name="search"
            className="custom-input w-100 p bg-transparent form-control border-dark text-dark shadow-none text-center rounded-pill"
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}

      {loading ? (
        <p>loading...</p>
      ) : collections.length === 0 ||
        collections.filter((collection) => collection.name.includes(search))
          .length === 0 ? (
        <div className="text-center">
          <p>No Collections Found</p>
        </div>
      ) : (
        collections
          .filter((collection) => collection.name.includes(search))
          .map((collection, index) => (
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
              style={{
                border: "1px solid black",
              }}
              className="rounded-5 overflow-hidden bg-transparent mb-3 text-center"
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography margin={"auto"}>
                  {collection.name}{" "}
                  {collection.isBig
                    ? "(Big)"
                    : collection.isEditAble && <>({collection.type})</>}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  borderTop: "1px solid black",
                }}
              >
                <p>{collection.description}</p>
                {collection.isEditAble && (isCollector || isAdmin) && (
                  <Link
                    className="fst-italic text-decoration-none text-dark text-lowercase"
                    to={`/collections/edit/${collection.id}`}
                  >
                    Edit
                  </Link>
                )}
                <CollectionSetComponent collection={collection} />
              </AccordionDetails>
            </Accordion>
          ))
      )}

      {(isCollector || isAdmin) && (
        <div className="text-center mb-5">
          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Create Collection"
          >
            <Link to={"/collections/add"} className="text-decoration-none">
              <AddIcon />
            </Link>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
