// CONSTANTS

const colorArr = [
  {
    color: "yellow",
    value: "#F2C94C",
  },
  {
    color: "skyblue",
    value: "#56ccf2",
  },
  {
    color: "red",
    value: "#eb5757",
  },
  {
    color: "orange",
    value: "#f2994a",
  },
  {
    color: "green",
    value: "#27ae60",
  },
  {
    color: "blue",
    value: "#2f80ed",
  },
  {
    color: "lime",
    value: "#df9595",
  },
];

const COLOR_INIT = "COLOR_INIT";
const COLOR_CHANGE = "COLOR_CHANGE";

// ACTION CREATORS

const colorInit = () => ({
  type: COLOR_INIT,
  payload: JSON.parse(localStorage.getItem("color")) || colorArr[0],
});

const colorChange = (color) => ({
  type: COLOR_CHANGE,
  payload: color,
});

// THUNK CREATORS

const getColor = () => (dispatch) => {
  dispatch(colorInit());
};

const changeColor = (color) => (dispatch) => {
  localStorage.setItem("color", JSON.stringify(color));
  dispatch(colorChange(color));
};

// REDUCER

const initialState = {
  color: JSON.parse(localStorage.getItem("color")) || colorArr[0],
  colorArr: colorArr,
};

const colorReducer = (state = initialState, action) => {
  document.documentElement.style.setProperty(
    "--bgColor",
    action.payload?.value || state.color.value
  );
  switch (action.type) {
    case COLOR_INIT:
      return {
        ...state,
        color: action.payload,
      };
    case COLOR_CHANGE:
      return {
        ...state,
        color: action.payload,
      };
    default:
      return state;
  }
};

export { colorReducer, getColor, changeColor };
