import React from "react";
import { Header } from "@components/Header";
import { MDBCol } from "mdb-react-ui-kit";
import { MDBRow } from "mdb-react-ui-kit";
// import MenuButton from "@components/MenuButton";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import { PublicCollectionComponent } from "@components/CollectionComponent/PublicCollectionComponent";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";

const Collection = () => {
  return (
    <HeaderFooterLayout>
      {/* <NavbarHeader /> */}

      <MDBRow className="justify-content-center py-5">
        {/* <MenuButton /> */}
        <MDBCol sm="6" md="6">
          <Header
            title="Public Collections"
            tagline={
              <p>
                First, choose the collection <br />
                and then a set that you want to learn with.
              </p>
              // <div className="mt-5">
              //   These are <b>big sets</b> to keep learning and improving your
              //   skills.
              // </div>
            }
          />
          {/* <div className="mt-5 pt-5 d-flex justify-content-center align-items-center">
            <h5 className="fw-bold">work in progress</h5>
          </div> */}
          <PublicCollectionComponent />
        </MDBCol>
      </MDBRow>
    </HeaderFooterLayout>
  );
};

export default Collection;
