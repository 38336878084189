import { Tooltip } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import userActions from "@store/auth/actions";
import Logo from "@utils/Logo";

const Login = () => {
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="login login-wrapper">
        <div className="background">
          <div className="shape" />
          <div className="shape" />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            dispatch(
              userActions.login({
                email: emailRef.current.value,
                password: passwordRef.current.value,
                callback: () => {
                  navigate("/");
                },
              })
            );
          }}
        >
          <div className="d-flex justify-content-evenly align-items-center mb-3">
            <div>
              <div
                className="bg-dark d-flex align-items-center justify-content-center rounded-pill"
                style={{
                  width: 90,
                  height: 90,
                }}
              >
                <Link to="/">
                  <Logo size={60} color="var(--bgColor)" />
                </Link>
              </div>
            </div>
            <div className="h2 fw-normal px-3">Do Not Blink</div>
          </div>
          <h3>Login</h3>
          <label htmlFor="email">Email</label>
          <input ref={emailRef} type="text" placeholder="Email" id="email" />
          <label htmlFor="password">Password</label>
          <input
            ref={passwordRef}
            type="password"
            placeholder="Password"
            id="password"
          />
          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Login to Save your progress"
          >
            <button className="button">Log In</button>
          </Tooltip>
          <div className="mt-3">
            Don't have an account?{" "}
            <Link to="/signup" className="text-dark text-decoration-underline">
              Sign Up
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
