import React from "react";
import { Container, ProgressBar } from "react-bootstrap";

export const ProgressBarComponent = ({ totalLength, currentLength }) => {
  return (
    <section className="w-100">
      <Container>
        <div className="position-relative d-flex align-items-center justify-content-end progress-bar-container">
          <ProgressBar
            now={((currentLength / totalLength) * 100).toFixed(1)}
            // label={`${((currentLength / totalLength) * 100).toFixed(0)}%  `}
            className="progress-bar-custom flex-fill"
          />
          {/* {currentLength !== totalLength && (
            <span className="position-absolute progress-text">
              {currentLength}/ {totalLength}
            </span>
          )} */}
          {currentLength === totalLength && (
            <span className="position-absolute progress-text text-dark">
              100%
            </span>
          )}
        </div>
      </Container>
    </section>
  );
};
