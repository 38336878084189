import { NavbarHeader } from "@components/Header/NavbarHeader";
// import { NavbarFooter } from "@components/footer/NavbarFooter";
import React from "react";

export const HeaderFooterLayout = ({ children, hideNavbar }) => {
  return hideNavbar ? (
    children
  ) : (
    <>
      <NavbarHeader />
      <section
      // style={{ marginBottom: "80px" }}
      >
        {children}
      </section>
      {/* <NavbarFooter /> */}
    </>
  );
};
