import { store } from "@store";
// import { lanuageArr } from "./lanuages";

const SETS = "sets";
// const COLLECTIONS = "collections";
const PROJECTS = "projects";
const USERS = "users";

const collections = {
  SETS,
  // COLLECTIONS,
  PROJECTS,
  USERS,
};

const plural = {
  [collections.SETS]: "Sets",
  // [collections.COLLECTIONS]: "Collections",
  [collections.PROJECTS]: "Projects",
  [collections.USERS]: "Users",
};

const individual = {
  [collections.SETS]: "Set",
  // [collections.COLLECTIONS]: "Collection",
  [collections.PROJECTS]: "Project",
  [collections.USERS]: "User",
};

const disabled = {
  [collections.SETS]: {},
  // [collections.COLLECTIONS]: {},
  [collections.PROJECTS]: {},
  [collections.USERS]: { add: true, delete: true },
};

const allInputs = {
  [collections.SETS]: {
    inputs: [
      {
        name: "title",
        type: "text",
        label: "Title",
      },
      // {
      //   name: "userName",
      //   type: "text",
      //   label: "User Name",
      //   renderValue: (item) => {
      //     return (
      //       <a target={"_blank"} rel="noopener noreferrer" href={item.profile}>
      //         {item.userName}
      //       </a>
      //     );
      //   },
      // },
      // {
      //   label: "Profile",
      //   name: "profile",
      //   type: "text",
      //   getValue: (item) => {
      //     return item.profile?.split?.("/")[3];
      //   },
      //   prefix: (item) => {
      //     return (
      //       <div
      //         style={{
      //           color: "#999",
      //           marginTop: `1.12rem`,
      //         }}
      //       >
      //         https://{item.platform}.com/
      //         {item?.platform?.includes("tiktok")
      //           ? "@"
      //           : item?.platform?.includes("snapchat")
      //           ? "add/"
      //           : ""}
      //       </div>
      //     );
      //   },
      // },
      {
        name: "sentences",
        type: "array",
        getValue: (item) => {
          console.log("item", item);
          return item?.sentences?.map?.((s) => s.sentence).join("\n");
        },
        label: "Sentences",
        multiline: true,
        rows: 10,
      },
      {
        name: "subtitles",
        type: "array",
        getValue: (item) => {
          return item?.sentences?.map?.((s) => s.subtitle).join("\n");
        },
        label: "Subtitles",
        multiline: true,
        rows: 10,
      },
      {
        name: "links",
        type: "array",
        getValue: (item) => {
          return item?.sentences?.map?.((s) => s.link).join("\n");
        },
        label: "Links",
        multiline: true,
        rows: 10,
      },
    ],
    selects: [
      {
        name: "collectionName",
        label: "Collection Name",
        watch: true,
        getValue: (item) => {
          return item?.collectionName;
        },
        options: [
          ...store.getState().collections.collections.map((c) => {
            return {
              value: c.value,
              label: c.name,
            };
          }),
        ],
      },
      // {
      //   name: "language",
      //   label: "Language",
      //   options: lanuageArr,
      // },
      // {
      //   name: "platform",
      //   label: "Platform",
      //   watch: true,
      //   getValue: (item) => {
      //     return item?.profile?.split?.("//")?.[1]?.split?.(".com")?.[0];
      //   },
      //   options: [
      //     "facebook",
      //     "twitter",
      //     "tiktok",
      //     "instagram",
      //     "snapchat",
      //     "youtube",
      //     "pinterest",
      //   ],
      // },
      // {
      //   name: "isPrivate",
      //   label: "Status",
      //   getValue: (item) => {
      //     return item?.isPrivate
      //       ? item.type === "quick link"
      //         ? "Quick Link"
      //         : "Private"
      //       : "Public";
      //   },
      //   options: [
      // {
      //   value: true,
      //   label: "Private",
      // },
      //     {
      //       value: false,
      //       label: "Public",
      //     },
      //   ],
      // },
    ],
    onSubmited: (item) => {
      console.log("item", item);
      console.log("item", item);
      const sentances = item.sentences
        .split("\n")
        .map((s) => ({ sentence: s }));
      const subtitles = item.subtitles
        .split("\n")
        .map((s) => ({ subtitle: s }));
      const links = item.links.split("\n").map((s) => ({ link: s }));
      const sentencesArray = sentances.map((s, i) => ({
        sentence: s.sentence,
        subtitle: subtitles[i]?.subtitle || "",
        link: links[i]?.link || "",
      }));
      console.log("sentencesArray", sentencesArray);

      return {
        ...item,
        // profile: `https://${item.platform}.com/${
        //   item?.platform?.includes("tiktok")
        //     ? "@"
        //     : item?.platform?.includes("snapchat")
        //     ? "add/"
        //     : ""
        // }${item.profile}`,
        sentences: sentencesArray,
      };
    },
    longInputs: [
      {
        name: "sentences",
        type: "array",
        renderValue: (item) => {
          return item?.sentences?.map?.((s) => <div>{s.sentence}</div>);
        },
        label: "Sentences",
        multiline: true,
        rows: 10,
      },
      {
        name: "subtitles",
        type: "array",
        renderValue: (item) => {
          return item?.sentences?.map?.((s) => <div>{s.subtitle}</div>);
        },
        label: "Subtitles",
        multiline: true,
        rows: 10,
      },
      {
        name: "links",
        type: "array",
        renderValue: (item) => {
          return item?.sentences?.map?.((s) => <div>{s.link}</div>);
        },
        label: "Links",
        multiline: true,
        rows: 10,
      },
    ],
    nonEditable: [
      {
        name: "id",
        label: "ID",
      },
      {
        name: "doneCount",
        label: "Done Count",
        fallback: "N/A",
      },
      {
        name: "opened",
        label: "Opened",
        fallback: "N/A",
      },
      {
        name: "startCount",
        label: "Start Count",
        fallback: "N/A",
      },
    ],
    ignoredInputs: ["profile", "platform"],
  },
  // [collections.COLLECTIONS]: {
  //   inputs: [
  //     {
  //       name: "title",
  //       type: "text",
  //       label: "Title",
  //     },
  //     {
  //       name: "description",
  //       type: "text",
  //       label: "Description",
  //       multiline: true,
  //     },
  //     {
  //       name: "link",
  //       type: "link",
  //       label: "Link",
  //     },
  //   ],
  // },
  [collections.PROJECTS]: {
    inputs: [
      {
        name: "title",
        type: "text",
        label: "Title",
      },
      {
        name: "description",
        type: "text",
        label: "Description",
        multiline: true,
      },
      {
        name: "link",
        type: "link",
        label: "Link",
      },
    ],
  },
  [collections.USERS]: {
    inputs: [
      {
        name: "name",
        type: "text",
        label: "Name",
      },
      {
        name: "email",
        type: "text",
        label: "Email",
        readOnly: true,
      },
    ],
    selects: [
      {
        name: "role",
        label: "Role",
        options: ["admin", "user", "collector"],
      },
    ],
    /**
     * @param {import("firebase/app").default.firestore.Query} query
     * @returns {import("firebase/app").default.firestore.Query}
     */
    extra: (query) => {
      return query.where("email", "!=", store.getState().auth.user.email);
    },
  },
};

export { collections, allInputs, individual, plural, disabled };

// get data from store
