import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TO_TRAIN_MEMORY } from "@helpers/lanuages";
import { setSentences } from "@store/sentences/action";

import BlinkAnimatedButton from "@components/BlinkAnimatedButton";
import { useEffect } from "react";

export default function Sentence({
  sentence,
  publicSentence,
  isBigCollection,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [storedSetance, setStoredSentance] = useState({});
  const { id } = sentence;

  useEffect(() => {
    if (publicSentence) {
      const sentances = JSON.parse(localStorage.getItem("sets")) || [];
      const currentSet = sentances.find((set) => set.id === id);
      console.log("Current Set", currentSet);
      if (currentSet) {
        setStoredSentance(currentSet);
      }
    }
  }, [id, publicSentence]);

  const handleRemoveSet = () => {
    const sentances = JSON.parse(localStorage.getItem("sets")) || [];
    const currentSet = sentances.find((set) => set.id === id);
    if (currentSet) {
      const newSentances = sentances.filter((set) => set.id !== id);
      localStorage.setItem("sets", JSON.stringify(newSentances));
      setStoredSentance({});
    }
  };

  return publicSentence ? (
    <div className="mb-4" key={sentence.id}>
      <p className="m-0 max-line-2">
        {sentence.lines || (sentence.sentences || []).length}{" "}
        <b>{sentence.titleDefault || "english sentence"} </b>
        {""}
        {!isBigCollection && (
          <span>
            {sentence?.language?.toLowerCase() ===
            TO_TRAIN_MEMORY.toLowerCase() ? (
              <>{sentence.language || "Unspecified language"}</>
            ) : (
              <>in {sentence.language || "Unspecified language"}</>
            )}
          </span>
        )}
      </p>
      <div>
        <BlinkAnimatedButton
          title={
            storedSetance?.complete > 0 && storedSetance?.complete < 100
              ? "continue"
              : storedSetance?.complete === 100
              ? "reset"
              : "start"
          }
          className="rounded-pill btn-black text-lowercase py-1 px-4 fs-12px my-2"
          pressed={() => {
            if (storedSetance?.complete === 100) {
              handleRemoveSet();
            }
            dispatch(setSentences([]));
            navigate(`/${sentence.id}`);
          }}
        />
      </div>
      {storedSetance?.complete && <span>{storedSetance?.complete}%</span>}
    </div>
  ) : (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        dispatch(setSentences([]));
        navigate(`/${sentence.id}`);
      }}
      className="w-100 d-flex"
      key={sentence.id}
    >
      <p
        className={`${
          isBigCollection ? "text-center w-100" : "text-end  w-75"
        }`}
      >
        {sentence.lines || (sentence.sentences || []).length}{" "}
        <b>{sentence.titleDefault || "english sentence"} </b>
      </p>
      {!isBigCollection && (
        <>
          {sentence?.language?.toLowerCase() ===
          TO_TRAIN_MEMORY.toLowerCase() ? (
            <p className="text-start w-50">
              &nbsp; {sentence.language || "Unspecified language"}
            </p>
          ) : (
            <p className="text-start w-50">
              &nbsp; in {sentence.language || "Unspecified language"}
            </p>
          )}
        </>
      )}
    </div>
  );
}
