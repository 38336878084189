// import { VideoModal } from "@components/VideoModal";
import { blinkAnimation } from "@helpers/index";
import {
  colorSVG,
  // colorSVG,
  countinueSVG,
  faceSVG,
  // infoSVG,
  // loginSVG,
  ourContent,
  // timeSVG,
  useTextSVG,
} from "@pages/Intro/introSVGS";
import alarmClickIcon from "assets/icons/alarm_clock.svg";
// import loginIcon from "assets/icons/login.svg";
// import userActions from "@store/auth/actions";
// import { changeColor } from "@store/color";
import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuButton from "@components/MenuButton";
import { changeColor } from "@store/color";

export const NavbarHeader = () => {
  const { colorArr, color } = useSelector((s) => s.color);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth, user } = useSelector((s) => s.auth);

  const handleNavigation = ({ to }) => {
    navigate(to);
  };
  const [activeUrl, setActiveUrl] = useState("");
  useEffect(() => {
    setActiveUrl(window.location.pathname);
  }, []);
  const [index, setIndex] = useState(() => {
    if (!color) return 0;
    const index = colorArr.findIndex((c) => c.value === color.value);
    return index === -1 ? 0 : index;
  });

  return (
    <>
      <Navbar className="bodyColor p-2 sliding-navbar" sticky="top">
        <div className="d-flex justify-content-center align-items-center gap-4 flex-wrap w-100">
          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Use your text"
          >
            <div
              className={`nav-item rounded-circle pointer-cursor ${
                activeUrl !== "/app" ? "border border-dark " : ""
              }`}
              onClick={async (e) => {
                await blinkAnimation(e);
                handleNavigation({ to: "/app" });
              }}
            >
              {useTextSVG({
                width: activeUrl === "/app" ? "2.5rem" : "2.15rem",
                height: activeUrl === "/app" ? "2.5rem" : "2.15rem",
                background: activeUrl === "/app" ? "black" : "var(--bgColor)",

                color: activeUrl === "/app" ? "var(--bgColor)" : "black",
              })}
            </div>
          </Tooltip>

          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Big Collection"
          >
            <div
              className={`nav-item rounded-circle pointer-cursor ${
                activeUrl !== "/big" ? "border border-dark " : ""
              }`}
              onClick={async (e) => {
                await blinkAnimation(e);
                handleNavigation({ to: "/big" });
              }}
            >
              {countinueSVG({
                width: activeUrl === "/big" ? "2.5rem" : "2.15rem",
                height: activeUrl === "/big" ? "2.5rem" : "2.15rem",
                background: activeUrl === "/big" ? "black" : "var(--bgColor)",
                color: activeUrl === "/big" ? "var(--bgColor)" : "black",
              })}
            </div>
          </Tooltip>

          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Learn with our content"
          >
            <div
              className={`nav-item rounded-circle pointer-cursor ${
                activeUrl !== "/collections" ? "border border-dark " : ""
              }`}
              onClick={async (e) => {
                await blinkAnimation(e);
                handleNavigation({ to: "/collections" });
              }}
            >
              {ourContent({
                width: activeUrl === "/collections" ? "2.5rem" : "2.15rem",
                height: activeUrl === "/collections" ? "2.5rem" : "2.15rem",
                background:
                  activeUrl === "/collections" ? "black" : "var(--bgColor)",

                color:
                  activeUrl === "/collections" ? "var(--bgColor)" : "black",
              })}
            </div>
          </Tooltip>
          <MenuButton size={"72px"} />

          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Go to our other projects"
          >
            <div
              className="nav-item border border-dark rounded-circle pointer-cursor d-flex justify-content-center align-items-center"
              onClick={async (e) => {
                await blinkAnimation(e);
                window.open("https://thetime.pl", "_blank");
                return false;
              }}
              style={{
                width: "2rem",
                height: "2rem",
                padding: ".25rem",
              }}
            >
              {/* {timeSVG({
                width: "2rem",
                height: "2rem",
              })} */}
              <img
                src={alarmClickIcon}
                alt="Alarm Clock"
                width={"24px"}
                height={"24px"}
              />
            </div>
          </Tooltip>
          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title="Change Color"
          >
            <div
              className="nav-item border border-dark rounded-circle pointer-cursor"
              onClick={async (e) => {
                await blinkAnimation(e);
                changeColor(colorArr[(index + 1) % colorArr.length])(dispatch);
                setIndex(index + 1);
              }}
            >
              {colorSVG({
                width: "2rem",
                height: "2rem",
              })}
            </div>
          </Tooltip>

          <Tooltip
            componentsProps={{
              tooltip: {
                className: "bg-dark",
                style: { color: `var(--bgColor)` },
              },
            }}
            arrow
            title={isAuth ? user?.name || "no username" : "Guest"}
          >
            <div
              className="nav-item border border-dark rounded-circle pointer-cursor"
              onClick={async (e) => {
                await blinkAnimation(e);
                if (isAuth) {
                  navigate("/profile");
                } else {
                  navigate("/login");
                }
              }}
            >
              {faceSVG({
                width: "1.9rem",
                height: "1.9rem",
              })}
            </div>
          </Tooltip>
        </div>
      </Navbar>

      {/* <div
        className="position-fixed bottom-0 left-0 p-2 bodyColor w-100"
        style={{
          zIndex: 1000,
        }}
      >
        <NavbarActionButtonSection />
      </div> */}
    </>
  );
};

// const NavbarActionButtonSection = () => {
//   const { colorArr, color } = useSelector((s) => s.color);
//   const dispatch = useDispatch();
//   // const navigate = useNavigate();
//   // const [showModal, setShowModal] = useState(false);

//   const [index, setIndex] = useState(() => {
//     if (!color) return 0;
//     const index = colorArr.findIndex((c) => c.value === color.value);
//     return index === -1 ? 0 : index;
//   });

//   // const handleNavigation = ({ to }) => {
//   //   navigate(to);
//   // };
//   return (
//     <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
//       <Tooltip
//         componentsProps={{
//           tooltip: {
//             className: "bg-dark",
//             style: { color: `var(--bgColor)` },
//           },
//         }}
//         arrow
//         title="Change Color"
//       >
//         <div
//           className="border border-dark rounded-circle pointer-cursor"
//           onClick={async (e) => {
//             await blinkAnimation(e);
//             changeColor(colorArr[(index + 1) % colorArr.length])(dispatch);
//             setIndex(index + 1);
//           }}
//         >
//           {colorSVG({
//             width: "2rem",
//             height: "2rem",
//           })}
//         </div>
//       </Tooltip>

//       {/* <VideoModal showModal={showModal} setShowModal={setShowModal} />
//       <Tooltip
//         componentsProps={{
//           tooltip: {
//             className: "bg-dark",
//             style: { color: `var(--bgColor)` },
//           },
//         }}
//         arrow
//         title="Video Tutorial"
//       >
//         <div
//           className="border border-dark rounded-circle pointer-cursor"
//           onClick={async (e) => {
//             await blinkAnimation(e);
//             setShowModal(true);
//           }}
//         >
//           {infoSVG({
//             width: "2rem",
//             height: "2rem",
//           })}
//         </div>
//       </Tooltip> */}
//       <MenuButton size={"72px"} />
//       <Tooltip
//         componentsProps={{
//           tooltip: {
//             className: "bg-dark",
//             style: { color: `var(--bgColor)` },
//           },
//         }}
//         arrow
//         title="Go to our other projects"
//       >
//         <div
//           className="border border-dark rounded-circle pointer-cursor d-flex justify-content-center align-items-center"
//           onClick={async (e) => {
//             await blinkAnimation(e);
//             window.open("https://thetime.pl", "_blank");
//             return false;
//           }}
//           style={{
//             width: "2rem",
//             height: "2rem",
//             padding: ".25rem",
//           }}
//         >
//           {/* {timeSVG({
//             width: "2rem",
//             height: "2rem",
//           })} */}
//           <img
//             src={alarmClickIcon}
//             alt="Alarm Clock"
//             width={"24px"}
//             height={"24px"}
//           />
//         </div>
//       </Tooltip>
//     </div>
//   );
// };
