import React from "react";
import { getRandomElementFromArray, tips } from "../../helpers";
const text = getRandomElementFromArray(tips);
const StarterTextSection = ({
  readyLearningSession,
  hideReady,
  startLearning,
  StartSession,
  bigSize,
  setBigSize,
  hint,
}) => {
  return (
    <div
      onClick={() => {
        if (window.innerWidth > 600) {
          StartSession({ keyCode: 13 });
        }
      }}
      className="pt-5 text-center mt-4"
    >
      <h1
        hidden={startLearning || hideReady}
        className="mb-4"
        style={{
          fontSize: "1.5rem",
          cursor: "pointer",
        }}
        onClick={readyLearningSession}
      >
        {window.innerWidth < 600 ? "tap" : "press enter"} to start
      </h1>
      {hint && (
        <>
          <p
            className="text-lowercase mt-4"
            hidden={startLearning || hideReady}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              e.currentTarget.innerText = `Tip: ${getRandomElementFromArray(
                tips
              )}`;
            }}
          >
            tip: {text}
          </p>
          <p
            className="text-uppercase cursor-pointer"
            hidden={startLearning || hideReady}
            onClick={(e) => {
              e.stopPropagation();
              setBigSize(!bigSize);
            }}
          >
            Big: {bigSize ? "on" : "off"}{" "}
          </p>
        </>
      )}
    </div>
  );
};
export default StarterTextSection;
