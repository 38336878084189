import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  colorSVG,
  countinueSVG,
  infoSVG,
  loginSVG,
  ourContent,
  timeSVG,
  useLogoSVG,
  useTextSVG,
} from "./introSVGS";
import { VideoModal } from "../../components/VideoModal";
import firebase from "@config/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { changeColor } from "@store/color";
// import FlashSection from "@components/LearnPageComponents/FlashSection";
import { blinkAnimation } from "@helpers/index";
import userActions from "@store/auth/actions";
import { setId, setSentences } from "@store/sentences/action";
import preval from 'preval.macro'

export const Intro = () => {
  const { colorArr, color } = useSelector((s) => s.color);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, user } = useSelector((s) => s.auth);
  const buildDate = preval`module.exports = new Date().toLocaleString();`

  const [showModal, setShowModal] = useState(false);
  const [textTorender, setTextTorender] = useState("");
  const [index, setIndex] = useState(() => {
    if (!color) return 0;
    const index = colorArr.findIndex((c) => c.value === color.value);
    return index === -1 ? 0 : index;
  });
  // const [showFlash, setShowFlash] = useState(true); // State to control flash screen visibility

  const textToRender = (text) => {
    if (text === "") {
      setTextTorender("");
    } else {
      setTextTorender(text);
    }
  };

  const handleBigCollectionButton = async () => {
    const getBigCollectionData = localStorage.getItem("bigCollectionData");
    console.log("getBigCollectionData", getBigCollectionData);
    const bigCollectionData = JSON.parse(getBigCollectionData);

    // if (bigCollectionData) {
    await firebase
      .firestore()
      .collection("sets")
      .doc(
        bigCollectionData != null
          ? bigCollectionData.setId
          : "2BS8EykJxPT2uEVgqGaF"
      )
      .get()
      .then(async (data) => {
        console.log("data", data);
        console.log("data", data.data());
        console.log("id", data.id);

        const mySentencesData = data.data()["sentences"];
        mySentencesData.forEach((e) => {
          e.master = false;
          e.tried = false;
        });

        dispatch(
          setSentences(
            mySentencesData.map((e) => {
              let sentence = e.sentence;
              sentence = sentence
                .replaceAll("‘", "'")
                .replaceAll("’", "'")
                .replaceAll("‛", "'")
                .replaceAll("`", "'")
                .replaceAll("“", '"')
                .replaceAll("”", '"')
                .replaceAll("‟", '"')
                .replaceAll("„", '"');
              return { ...e, sentence };
            })
          )
        );
        if (data.id) {
          dispatch(setId(data.id));
        }
        navigate(`/learning-session`);
      })
      .catch((err) => {
        console.log("Error in fetching set in intro page", err);
        navigate("/big");
      });
    // } else {
    // navigate("/big");
    // }
  };

  // const showFlashScreen = () => {
  //   setShowFlash(true);
  //   setTimeout(() => {
  //     setShowFlash(false); // Hide the flashing screen after 2 seconds
  //   }, 1000);
  // };

  const handleNavigation = ({ to }) => {
    navigate(to);
    // setShowFlash(true);
    // setTimeout(() => {

    // setTimeout(() => {
    // setShowFlash(false);
    //   }, 200);
    // }, 1000);
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowFlash(false); // Hide the flash screen after 2 seconds
  //   }, 1000);

  //   return () => clearTimeout(timeout); // Clear the timeout if component unmounts
  // }, []);

  // when size less than 576px add text to setTextTorender
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    if (width < 576) {
      setTextTorender("by thetime.pl");
    } else {
      setTextTorender("");
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return (
    <div className="dark-bg vh-100 w-100">
      {/* {showFlash && <FlashSection opacity={1} />} */}
      {/* <Logo size={400} color="black" bgColor="var(--bgColor)" /> */}
      <div
        className="options-wrapper h-100 flex-column justify-content-center align-items-center 
      d-flex
      "
      >
        <div className="continue-wrapper w-100 d-flex justify-content-center align-items-center">
          <Link>
            <div
              className="continue"
              style={{ width: "219px", height: "219px", cursor: "pointer" }}
              onMouseEnter={() => textToRender("Play")}
              onMouseLeave={() => textToRender("")}
              onClick={async (e) => {
                await blinkAnimation(e);
                handleBigCollectionButton();
                // handleNavigation({ to: "/big" });
              }}
            >
              {countinueSVG()}
            </div>
          </Link>
        </div>

        <div className="text-content-wrapper mt-4 w-100 d-flex justify-content-center align-items-center">
          <Link>
            <div
              className="use-text me-5"
              onMouseEnter={() => textToRender("Your Content")}
              onMouseLeave={() => textToRender("")}
              onClick={async (e) => {
                await blinkAnimation(e);
                handleNavigation({ to: "/app" });
              }}
            >
              {useTextSVG()}
            </div>
          </Link>
          <Link>
            <div
              className="out-content"
              onMouseEnter={() => textToRender("More Content")}
              onMouseLeave={() => textToRender("")}
              onClick={async (e) => {
                await blinkAnimation(e);
                handleNavigation({ to: "/collections" });
              }}
            >
              {ourContent()}
            </div>
          </Link>
        </div>
        {/* <div className="color-info-time-login-wrapper mt-4 w-100 d-flex justify-content-center align-items-center">
          <Link>
            <div
              className="change-color me-3"
              onClick={async (e) => {
                await blinkAnimation(e);
                // showFlashScreen();
                changeColor(colorArr[(index + 1) % colorArr.length])(dispatch);
                setIndex(index + 1);
                // setTimeout(() => {}, 1000);
              }}
              onMouseEnter={() => textToRender("Change Color")}
              onMouseLeave={() => textToRender("")}
            >
              {colorSVG()}
            </div>
          </Link>
          <VideoModal showModal={showModal} setShowModal={setShowModal} />
          <Link>
            <div
              onClick={async (e) => {
                await blinkAnimation(e);
                setShowModal(true);
                // showFlashScreen();
                // setTimeout(() => {}, 1000);
              }}
              className="info-video me-3"
              onMouseEnter={() => textToRender("Watch the tutorial")}
              onMouseLeave={() => textToRender("")}
            >
              {infoSVG()}
            </div>
          </Link>
          <Link>
            <div
              className="time me-3"
              onClick={async (e) => {
                await blinkAnimation(e);
                window.location.href = "https://thetime.pl";
                return false; // Prevent default link behavior
                // showFlashScreen();
                // setTimeout(() => {}, 1000);
              }}
              onMouseEnter={() => textToRender("Made by thetime.pl")}
              onMouseLeave={() => textToRender("")}
            >
              {timeSVG()}
            </div>
          </Link>
          <div
            className="login"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onMouseEnter={() => textToRender(isAuth ? "log Out" : "log in")}
            onMouseLeave={() => textToRender("")}
            onClick={async (e) => {
              await blinkAnimation(e);
              isAuth
                ? dispatch(
                    userActions.signOut({
                      callback: () => {
                        navigate("/");
                      },
                    })
                  )
                : handleNavigation({ to: "/login" });
            }}
          >
            {loginSVG()}
          </div>
        </div> */}
        <div
          className="login"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            cursor: "pointer",
            transform: `rotate(${isAuth ? "45deg" : "135deg"})`,
          }}
          onMouseEnter={() => textToRender(isAuth ? "Log Out" : "LOG IN")}
          onMouseLeave={() => textToRender("")}
          onClick={async (e) => {
            await blinkAnimation(e);
            isAuth
              ? dispatch(
                  userActions.signOut({
                    callback: () => {
                      navigate("/");
                    },
                  })
                )
              : handleNavigation({ to: "/login" });
          }}
        >
          {loginSVG()}
        </div>
        <button
          className="btn bodyColor text-uppercase fw-bold mt-4 rounded-pill px-5"
          onClick={async (e) => {
            await blinkAnimation(e);
            setShowModal(true);
          }}
        >
          HOW DOES IT WORK?
        </button>
        <VideoModal showModal={showModal} setShowModal={setShowModal} />
      </div>

      {/* <div className="options-wrapper h-100 flex-column justify-content-center align-items-center d-none d-sm-none">
        <Link>
          <div
            className="use-text"
            // onMouseEnter={() => textToRender("Use your text")}
            // onMouseLeave={() => textToRender("")}
            onClick={async (e) => {
              await blinkAnimation(e);
              handleNavigation({ to: "/app" });
            }}
          >
            {useLogoSVG()}
          </div>
        </Link>
      </div> */}

      <div className={`w-100 d-flex justify-content-center align-items-center`}>
        <p
          className={`renderedtext text-center text-lowsercase position-absolute bottom-0`}
          style={{
            opacity: 0.8,
          }}
        >
          {textTorender}
        </p>
      </div>
      {isAuth && user && user.role === "admin" && (
        <div
          className="position-absolute bottom-0 end-0  m-3 text-sm "
          style={{ opacity: 0.8, color: `var(--bgColor)`}}
        >
          <p>
           {buildDate}
          </p>
        </div>
      )}
    </div>
  );
};
