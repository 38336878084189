import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Header } from "@components/Header";
import { Loader } from "@components/loader";
import firebase from "@config/firebaseConfig";
// import { NavbarHeader } from "@components/Header/NavbarHeader";
import { HeaderFooterLayout } from "@components/Layout/HeaderFooterLayout";
const MoreOptions = () => {
  const data = useLoaderData();
  return (
    <Suspense fallback={<Loader isStatic />}>
      <Await resolve={data?.projects}>
        {(projects) => {
          return (
            <HeaderFooterLayout>
              {/* <NavbarHeader /> */}

              <div className="container py-5">
                <div className="row">
                  <div className="col-md-6 mx-auto">
                    <Header
                      title="More stuff"
                      tagline={
                        <>
                          Here are other things you can try out. <br /> Pretty
                          sure you will like them, too.
                        </>
                      }
                    />
                    <div className="mt-5">
                      {projects
                        ?.filter((collection) => collection.title !== undefined)
                        ?.map((collection) => (
                          <Accordion
                            style={{
                              border: "1px solid black",
                            }}
                            className="rounded-5 overflow-hidden bg-transparent mb-3"
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{collection.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                borderTop: "1px solid black",
                              }}
                            >
                              <Typography>{collection.description}</Typography>
                              <div className="mt-3">
                                <Button
                                  component="a"
                                  variant="contained"
                                  href={collection.link}
                                  className="btn btn-black overflow-hidden rounded-pill px-5 py-2"
                                  data-tip="Create a link to this set if you want to share it with someone."
                                  data-title="Visit"
                                  target="_blank"
                                >
                                  Visit
                                </Button>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </HeaderFooterLayout>
          );
        }}
      </Await>
    </Suspense>
  );
};

const getData = async () => {
  const projects = await firebase
    .firestore()
    .collection("projects")

    .get();
  console.log(projects);
  return (projects?.docs || [])?.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};
const loader = () => {
  return defer({
    projects: getData(),
  });
};

export { loader as moreLoader };
export default MoreOptions;
