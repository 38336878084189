import { Button } from "react-bootstrap";
import React from "react";
import { blinkAnimation } from "../helpers";

export default function BlinkAnimatedButton({
  pressed,
  className,
  title,
  ...props
}) {
  return (
    <Button
      onClick={async (e) => {
        await blinkAnimation(e);
        pressed();
      }}
      className={`rounded-pill btn-black text-lowercase mt-2 overflow-hidden ${className}`}
      data-title={title}
      {...props}
      // data-mdb-ripple-duration="0"
    >
      {title}
    </Button>
  );
}
