import { SET_AUTHOR, SET_ID } from "./actionTypes"

let initialState = {
    sentences: [],
    mode: 'focus',
    visible: false,
    id: null,
    isAuthor: false,
}
export default function counter(state, action) {
    switch (action.type) {
        case 'SET_SENTENCES': {
            const sentences = action.payload.sentences;

            // Check if the last sentence ends with "The End"
            const lastSentence = sentences[sentences.length - 1];
            const shouldExcludeLast = lastSentence && lastSentence.subtitle?.endsWith('The End') && lastSentence.sentence?.endsWith('The End');

            // If the last sentence ends with "The End," exclude it
            const filteredSentences = shouldExcludeLast
                ? sentences.slice(0, -1) // Exclude the last sentence
                : sentences;

            return {
                ...state,
                sentences: filteredSentences
            };
        }

        case SET_ID: {
            return {
                ...state,
                id: action.payload.id
            }
        }
        case SET_AUTHOR: {
            return {
                ...state,
                isAuthor: action.payload.isAuthor
            }
        }
        case 'SET_MODE': {
            return {
                ...state,
                mode: action.payload.mode
            }
        }
        case 'SET_VISIBILITY': {
            return {
                ...state,
                visible: action.payload.visible
            }
        }
        default:
            return initialState;
    }
}
