import {
  FormControl as MDBInput,
  Col as MDBCol,
  Row as MDBRow,
  Button,
} from "react-bootstrap";
import React, { useRef, useEffect, useState } from "react";
import { CircleIcon, PlayArrowIcon } from "./TooltipSection";
import { Fade, IconButton, Tooltip } from "@mui/material";
import { getFontSize } from "../../Pages/LearningSession/LearningSession";
import { useNavigate } from "react-router-dom";
import {
  closeSVG,
  faceSVG,
  fullScreenSVG,
  helpSVG,
  translateLanguageSVG,
  translateSVG,
  translateTooltipSVG,
} from "../../components/svgComponents/Svgs";
import { useSelector } from "react-redux";

const usePrevious = (value, defaultValue) => {
  const ref = useRef(defaultValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const CustomTooltip = (props) => (
  <Tooltip
    componentsProps={{
      tooltip: {
        style: {
          color: `var(--bgColor)`,
          background: `var(--text-color)`,
        },
      },
    }}
    {...props}
    title={
      <div
        className="h6 mb-0 p-1"
        style={{
          fontSize: ".75rem",
        }}
      >
        {props.title}
      </div>
    }
  />
);

const AnswerSection = ({
  setLearnState,
  learnState,
  handleAgain,
  inputWord,
  handleKeyDown,
  handleInputWord,
  fontSize,
  sentenceIndex,
  showCountSection,
  sentence,
  setShowCountSection,
  getHELP,
  SkipSentence,
  showTooltip,
  handleFullScreenToggle,
  helpCount,
  helpText,
}) => {
  const { isAuth } = useSelector((s) => s.auth);

  // function maskText(text = "") {
  // return text
  //   ?.split(/\s/)
  //   .map(
  //     (word) =>
  //       word.slice(0, helpCount) +
  //       word.slice(helpCount, word.length).replace(/[a-zA-Z0-9 ]/g, "_")
  //   )
  //   .join(" ");
  // }

  // function maskText(text = "") {
  //   if (!text) return text;

  //   const length = text.length;
  //   const revealCount = Math.ceil(length * Math.min(0.2 * (helpCount + 1), 1));

  //   const revealIndices = new Set();

  //   while (revealIndices.size < revealCount) {
  //     const randomIndex = Math.floor(Math.random() * length);
  //     revealIndices.add(randomIndex);
  //   }

  //   return text
  //     .split("")
  //     .map((char, index) =>
  //       revealIndices.has(index) ? char : char.replace(/[a-zA-Z0-9]/g, "_")
  //     )
  //     .join("");
  // }

  // navigation
  const navigate = useNavigate();
  const ref = useRef(null);
  //methods
  const handleMouseEnter = () => {
    setShowCountSection(false);
  };

  const handleMouseLeave = () => {
    setShowCountSection(true);
  };

  const [showBottomBar, setShowBottomBar] = useState(false);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) && // Check if Ctrl or Cmd key is pressed
        (event.key === "g" || event.key === "g")
      ) {
        window.open(
          `http://translate.google.com?text=${
            inputWord || sentence?.sentence || ""
          }`,
          "_blank"
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="w-100">
      {/* {showCountSection && (
        <div
          className="text-center mb-5 fade-in"
          style={{ marginRight: "42px" }}
        >
          {inputWord.length}/
          {learnState?.sentences?.[sentenceIndex]?.sentence.length}
        </div>
      )} */}
      {learnState.showHelp ? (
        <div
          onClick={() => {
            setLearnState({
              ...learnState,
              showHelp: false,
              help: [],
            });
          }}
          style={{ textAlign: "center" }}
          className="container"
        >
          <div className="position-relative help-field">
            <div
              style={{
                marginTop: "-25px",
                fontSize: `max(min(${getFontSize(
                  learnState.help.reduce((a, b) => a + (b.value || "_"), "")
                )}rem, 6.666666666666667rem), 1.5rem)`,
              }}
            >
              {learnState.help.map((char) => (
                <span
                  style={{
                    backgroundColor: char.color,
                    color: "#FFFFFF",
                    padding: "0px",
                    fontSize: char.fontSize || "inherit",
                  }}
                >
                  {char.value ? char.value : " _ "}
                </span>
              ))}
            </div>

            <span className="target-span">{inputWord} </span>
          </div>
        </div>
      ) : (
        <MDBRow className="justify-content-center">
          <MDBCol
            md="12"
            lg="12"
            xl="12"
            className="text-center"
            style={{ background: `transparent` }}
          >
            <>
              {learnState.count > 2 && (
                <h5
                  className="mb-4"
                  style={{
                    position: "fixed",
                    top: "30%",
                    left: "50%",
                    width: "100%",
                    textAlign: "center",
                    transform: "translate(-50%, -50%)",
                    fontSize: `max(min(${
                      getFontSize(sentence?.sentence) * 0.2
                    }rem, 1.25rem), 1.25rem)`,
                  }}
                >
                  {/* {maskText(sentence?.sentence)} */}
                  {helpText}
                </h5>
              )}
              <MDBInput
                className="text-center h4 my-0 bg-transparent text-dark inputFiled overflow-visible placeholder-styling"
                autoFocus
                // onMouseLeave={handleMouseLeave}
                // onMouseEnter={handleMouseEnter}
                placeholder={isAuth ? "" : "Type what you saw"}
                onKeyDown={(e) => {
                  setShowCountSection(false);
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.stopPropagation();
                    e.preventDefault();
                    handleAgain();
                  } else {
                    handleKeyDown(e);
                  }
                }}
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: `max(min(${fontSize}rem, 6.666666666666667rem), 1.5rem)`,
                  color: "var(--text-color) !important",
                  fontWeight: "500",
                  transition: "all 0.3s ease",
                  zIndex: 1000,
                  // placeh
                }}
                value={inputWord}
                type="text"
                spellCheck={learnState.spellCheck}
                onInput={(e) => {
                  handleInputWord(e);
                  setLearnState((learnState) => {
                    return { ...learnState, hideAll: true };
                  });
                }}
                size="lg"
              ></MDBInput>
            </>
          </MDBCol>
        </MDBRow>
      )}
      {/* {showCountSection && ( */}
      <>
        <section className="position-absolute bottom-0 start-0 w-100 text-center pb-2 d-flex justify-content-center align-items-center">
          {/* {!showBottomBar ? (
            <IconButton
              color="primary"
              className="text-dark"
              onMouseEnter={() => setShowBottomBar(true)}
              style={{
                opacity: 0.5,
                transition: "0.3s ease-in-out",
              }}
            >
              <CircleIcon
                size={48}
                color="inherit"
                fontSize="inherit"
              ></CircleIcon>
            </IconButton>
          ) : ( */}
          <IconButton
            color="primary"
            className="text-dark"
            onMouseEnter={() => setShowBottomBar(true)}
            style={{
              opacity: !showBottomBar ? 0.25 : 0,
              visibility: !showBottomBar ? "visible" : "hidden",
              transition: "1s all ease-in-out",
              position: "absolute",
            }}
          >
            <CircleIcon
              size={48}
              color="inherit"
              fontSize="inherit"
            ></CircleIcon>
          </IconButton>
          <div
            className="d-flex justify-content-center align-items-center gap-4 flex-wrap"
            style={{
              opacity: showBottomBar ? 1 : 0,
              visibility: showBottomBar ? "visible" : "hidden",
              transition: "1s all ease-in-out",
            }}
            onMouseLeave={() => setShowBottomBar(false)}
          >
            <CustomTooltip title="Esc to QUIT">
              <Button
                className="bg-transparent shadow-none text-dark p-0 border-0"
                onClick={() => {
                  navigate(-1);
                  // navigate("/");
                }}
              >
                {closeSVG()}
              </Button>
            </CustomTooltip>

            <CustomTooltip title="Full Screen">
              <Button
                className="bg-transparent shadow-none text-dark p-0 border-0"
                onClick={handleFullScreenToggle}
              >
                {fullScreenSVG()}
              </Button>
            </CustomTooltip>

            <CustomTooltip
              title={
                // flashCount > 2 ? "Help" :
                "Enter/Return to FALSH AGAIN"
              }
            >
              <IconButton
                color="primary"
                className="text-dark p-0"
                onClick={() => {
                  showTooltip(false);
                  setTimeout(() => showTooltip(true), 50);
                  // if (flashCount > 2) {
                  //   handleHelpSentance();
                  // } else {
                  //   handleAgain();
                  // }
                  handleAgain();
                }}
              >
                <CircleIcon size={72} color="inherit" fontSize="inherit">
                  <span
                    className="text-bodyColor"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    {inputWord.length}/
                    {learnState?.sentences?.[sentenceIndex]?.sentence.length}
                  </span>
                </CircleIcon>
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title="Cmd/Ctrl + G to TRANSLATE">
              <Button
                className="bg-transparent shadow-none text-dark p-0 border-0"
                as="a"
                href={`http://translate.google.com?text=${
                  inputWord || sentence?.sentence || ""
                }`}
                target="_blank"
              >
                {translateLanguageSVG({
                  width: "2rem",
                  height: "2rem",
                })}
              </Button>
            </CustomTooltip>

            {/* <IconButton
              color="primary"
              style={{
                width: "2rem",
                height: "2rem",
                fontSize: "1rem",
              }}
              className="text-dark border border-dark"
              data-tip={`Arrow Up for HELP`}
              onClick={() => {
                showTooltip(false);
                setTimeout(() => showTooltip(true), 50);
                getHELP();
              }}
            >
              {learnState.helpcount}
            </IconButton> */}
            <CustomTooltip title="Arrow Down to SKIP">
              <IconButton
                color="primary"
                className="text-dark  p-0"
                onClick={() => {
                  showTooltip(false);
                  setTimeout(() => showTooltip(true), 50);
                  SkipSentence();
                }}
              >
                {faceSVG({
                  width: "2rem",
                  height: "2rem",
                })}
              </IconButton>
            </CustomTooltip>
          </div>
          {/* )} */}
        </section>

        {/* <div className="text-center fade-in" style={{ paddingTop: "250px" }}>
            <IconButton
              style={{ cursor: "pointer", marginRight: "40px" }}
              className="text-dark"
              data-tip={`Help Count ${learnState.helpcount}, Arrow Up for HELP`}
              onClick={getHELP}
            >
              {helpSVG()}
            </IconButton>
            <IconButton
              style={{ cursor: "pointer", marginRight: "40px" }}
              className="text-dark  "
              onClick={() => {
                showTooltip(false);
                setTimeout(() => showTooltip(true), 50);
                handleAgain();
              }}
              data-tip="Enter/Return to FALSH AGAIN"
              onMouseEnter={() => showTooltip(true)}
            >
              <CircleIcon size={80} />
            </IconButton>
            <IconButton
              style={{ cursor: "pointer", marginRight: "40px" }}
              className="text-dark"
              href={`http://translate.google.com?text=${inputWord}`}
              target="_blank"
              data-tip="Cmd/Ctrl + G to TRANSLATE"
              onMouseEnter={() => showTooltip(true)}
            >
              {translateSVG()}
            </IconButton>
          </div>
          <div className="text-center fade-in" style={{ marginRight: "42px" }}>
            <IconButton
              style={{ cursor: "pointer", margin: "0 auto" }}
              className="text-dark"
              data-tip="Arrow Down to SKIP"
              onClick={() => {
                showTooltip(false);
                setTimeout(() => showTooltip(true), 50);
                SkipSentence();
              }}
            >
              <PlayArrowIcon />
            </IconButton>
          </div> */}
      </>
      {/* )} */}
    </div>
  );
};
export default AnswerSection;
