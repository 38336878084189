import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlinkAnimatedButton from "../BlinkAnimatedButton";
import firebase from "@config/firebaseConfig";
import { useMemo } from "react";
import { Header } from "../Header";

export const numbers = [3, 4, 5, 6, 7, 8, 9, 10];

const ReversedTest = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const type = useMemo(
    () => (query.get("type") === "numbers" ? "numbers" : "words"),
    [query]
  );
  const length = useMemo(
    () =>
      query.get("length") && numbers.includes(+query.get("length"))
        ? +query.get("length")
        : numbers[0],
    [query]
  );
  const [bestScore, setBestScore] = useState({});
  const [personalBestScore, setPersonalBestScore] = useState({});

  const fetchBestScore = () => {
    firebase
      .firestore()
      .collection("bestScore")
      // .doc('L6CPy5JDVXWdbcnCajBv')
      .where("type", "==", type)
      .where("length", "==", +length)

      .orderBy("timeTaken")
      .limit(1)
      .get()
      .then((response) => {
        if (response.docs && response.docs.length) {
          response.docs.map((item) =>
            console.log("item:::", item.id, item.data())
          );
          const oldBestScore = { ...bestScore };
          oldBestScore[type] = oldBestScore[type] ?? {};
          oldBestScore[type][length] = {
            ...response.docs[0].data(),
            id: response.docs[0].id,
          };
          setBestScore(oldBestScore);
        }
      });
  };
  useEffect(() => {
    if (!bestScore?.[type]?.[length]) {
      fetchBestScore();
    }
    const personalBestFromLocal = JSON.parse(
      localStorage.getItem("personalBest")
    );
    if (personalBestFromLocal) {
      const oldPersonalBestScore = { ...personalBestScore };
      oldPersonalBestScore[type] = oldPersonalBestScore[type] ?? {};
      oldPersonalBestScore[type][length] =
        personalBestFromLocal?.[type]?.[length] ?? {};
      setPersonalBestScore(oldPersonalBestScore);
    }
  }, [length, type]);
  const navigate = useNavigate();
  const showScore = (e) => {
    return e ? e.toFixed(2) : "??";
  };
  const isMyRecord = () => {
    return (
      personalBestScore?.[type]?.[length]?.id &&
      bestScore?.[type]?.[length]?.id == personalBestScore?.[type]?.[length]?.id
    );
  };
  return (
    <div className="row">
      <div className="col-md-6 mx-auto text-center py-4">
        <Header
            title={<span style={{
            }}>
                {"Reversed".split("").reverse().join("")}
            </span>}
            tagline={<p className="instructionText text-center w-100">
            That’s a tricky game to test your working memory! You will get{" "}
            <b
              onClick={() => {
                navigate(
                  `/reversed?type=${
                    type === "words" ? "numbers" : "words"
                  }&length=${length}`
                );
              }}
            >
              5 {type}
            </b>{" "}
            one by one. Type each of them <b>REVERESED!</b>{" "}
            From back to start.
          </p>}
        />
        <div className="d-flex flex-row justify-content-center align-item-center mt-3">
          <p className="instructionText">How many characters in a {type}?</p>
        </div>
        <div className="numbersSelectBox" style={{ marginInline: "15%" }}>
          {numbers.map((item) => (
            <div
              key={item}
              onClick={() => {
                // setSelectedNumber(item)
                navigate(`/reversed?type=${type}&length=${item}`);
              }}
              style={{ fontWeight: +item == +length ? "bold" : "normal" }}
            >
              {item}
            </div>
          ))}
        </div>
        {/* top Score */}
        <div className="mt-3 numberDesBox">
          top score:&nbsp;&nbsp;{" "}
          <b>{showScore(+bestScore?.[type]?.[length]?.timeTaken)}</b>
          &nbsp;&nbsp; seconds
        </div>
        <div
          className={`mt-3 mb-3 ${
            isMyRecord() ? "numberDesBoxActive" : "numberDesBox"
          }`}
        >
          personal best:&nbsp;&nbsp;{" "}
          <b> {showScore(+personalBestScore?.[type]?.[length]?.timeTaken)}</b>
          &nbsp;&nbsp; seconds
        </div>
        <BlinkAnimatedButton
          title="try yourself!"
          pressed={() =>
            navigate(`/learning-session/reversed/${type}/${length}`)
          }
        />
      </div>
    </div>
  );
};
export default ReversedTest;
