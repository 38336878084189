const {
  GET_COLLECTIONS_DATA_REQUEST,
  GET_COLLECTIONS_DATA_SUCCESS,
  GET_COLLECTIONS_DATA_ERROR,
  ADD_COLLECTION_REQUEST,
  UPDATE_COLLECTIONS_REQUEST,
  UPDATE_COLLECTIONS_ERROR,
  ADD_COLLECTION_ERROR,
  ADD_COLLECTION_SUCCESS,
  UPDATE_COLLECTIONS_SUCCESS,
  GET_PUBLIC_COLLECTIONS_DATA_ERROR,
  GET_PUBLIC_COLLECTIONS_DATA_REQUEST,
  GET_PUBLIC_COLLECTIONS_DATA_SUCCESS,
  My_TARGET_COLLECTION_SETS_REQUEST,
  My_TARGET_COLLECTION_SETS_ERROR,
  My_TARGET_COLLECTION_SETS_SUCCESS,
  UPDATA_TARGET_COLLECTION_SUCCESS,
  My_TARGET_COLLECTION,
  GET_BIG_COLLECTIONS_DATA_SUCCESS,
  GET_BIG_COLLECTIONS_DATA_ERROR,
  GET_BIG_COLLECTIONS_DATA_REQUEST,
} = require("./actionTypes");

const initialState = {
  collections: [],
  loading: false,
  error: null,
  publicCollections: {
    collections: [],
    loading: false,
    error: null,
  },
  bigCollections: {
    collections: [],
    loading: false,
    error: null,
  },
  targetCollection: {
    collectionId: null,
    sets: [],
    loading: false,
    error: null,
  },
};

const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTIONS_DATA_REQUEST:
    case ADD_COLLECTION_REQUEST:
    case UPDATE_COLLECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PUBLIC_COLLECTIONS_DATA_REQUEST:
      return {
        ...state,
        publicCollections: {
          ...state.publicCollections,
          loading: true,
        },
      };

    case GET_BIG_COLLECTIONS_DATA_REQUEST:
      return {
        ...state,
        bigCollections: {
          ...state.bigCollections,
          loading: true,
        },
      };

    case GET_COLLECTIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        collections: action.payload,
      };

    case GET_PUBLIC_COLLECTIONS_DATA_SUCCESS:
      return {
        ...state,
        publicCollections: {
          collections: action.payload,
          loading: false,
        },
      };

    case GET_BIG_COLLECTIONS_DATA_SUCCESS:
      return {
        ...state,
        bigCollections: {
          collections: action.payload,
          loading: false,
        },
      };

    case ADD_COLLECTION_SUCCESS:
      console.log("Adding Collection Payload Data", action.payload);
      return {
        ...state,
        loading: false,
        collections: [...state.collections, action.payload],
      };

    case UPDATE_COLLECTIONS_SUCCESS:
      console.log("Updating Collection Payload Data", action.payload);
      return {
        ...state,
        loading: false,
        collections: state.collections.map((collection) =>
          collection.id === action.payload.id ? action.payload : collection
        ),
      };
    case GET_COLLECTIONS_DATA_ERROR:
    case ADD_COLLECTION_ERROR:
    case UPDATE_COLLECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_PUBLIC_COLLECTIONS_DATA_ERROR:
      return {
        ...state,
        publicCollections: {
          ...state.publicCollections,
          loading: false,
          error: action.payload,
        },
      };

    case GET_BIG_COLLECTIONS_DATA_ERROR:
      return {
        ...state,
        bigCollections: {
          ...state.bigCollections,
          loading: false,
          error: action.payload,
        },
      };

    case My_TARGET_COLLECTION:
      return {
        ...state,
        targetCollection: {
          collectionId: action.payload,
          sets: [],
          loading: false,
          error: null,
        },
      };
    case My_TARGET_COLLECTION_SETS_REQUEST:
      return {
        ...state,
        targetCollection: {
          ...state.targetCollection,
          loading: true,
        },
      };
    case My_TARGET_COLLECTION_SETS_SUCCESS:
      return {
        ...state,
        targetCollection: {
          ...state.targetCollection,
          sets: action.payload,
          loading: false,
          error: null,
        },
      };
    case My_TARGET_COLLECTION_SETS_ERROR:
      return {
        ...state,
        targetCollection: {
          ...state.targetCollection,
          loading: false,
          error: action.payload,
        },
      };

    case UPDATA_TARGET_COLLECTION_SUCCESS:
      return {
        ...state,
        targetCollection: {
          ...state.targetCollection,
          sets: state.targetCollection.sets.map((set) =>
            set.id === action.payload.id ? action.payload : set
          ),
        },
      };

    default:
      return state;
  }
};

export default collectionsReducer;
