export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTIONS_DATA_REQUEST = "GET_COLLECTIONS_DATA_REQUEST";
export const GET_COLLECTIONS_DATA_ERROR = "GET_COLLECTIONS_DATA_ERROR";
export const GET_COLLECTIONS_DATA_SUCCESS = "GET_COLLECTIONS_DATA_SUCCESS";

export const GET_PUBLIC_COLLECTIONS = "GET_PUBLIC_COLLECTIONS";
export const GET_PUBLIC_COLLECTIONS_DATA_REQUEST =
  "GET_PUBLIC_COLLECTIONS_DATA_REQUEST";
export const GET_PUBLIC_COLLECTIONS_DATA_ERROR =
  "GET_PUBLIC_COLLECTIONS_DATA_ERROR";
export const GET_PUBLIC_COLLECTIONS_DATA_SUCCESS =
  "GET_PUBLIC_COLLECTIONS_DATA_SUCCESS";

export const GET_BIG_COLLECTIONS = "GET_BIG_COLLECTIONS";
export const GET_BIG_COLLECTIONS_DATA_REQUEST =
  "GET_BIG_COLLECTIONS_DATA_REQUEST";
export const GET_BIG_COLLECTIONS_DATA_ERROR = "GET_BIG_COLLECTIONS_DATA_ERROR";
export const GET_BIG_COLLECTIONS_DATA_SUCCESS =
  "GET_BIG_COLLECTIONS_DATA_SUCCESS";

export const ADD_COLLECTION = "ADD_COLLECTION";
export const ADD_COLLECTION_REQUEST = "ADD_COLLECTION_REQUEST";
export const ADD_COLLECTION_ERROR = "ADD_COLLECTION_ERROR";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";

export const UPDATE_COLLECTIONS = "UPDATE_COLLECTIONS";
export const UPDATE_COLLECTIONS_REQUEST = "DELETE_COLLECTIONS_REQUEST";
export const UPDATE_COLLECTIONS_ERROR = "DELETE_COLLECTIONS_ERROR";
export const UPDATE_COLLECTIONS_SUCCESS = "DELETE_COLLECTIONS_SUCCESS";

export const My_TARGET_COLLECTION = "My_TARGET_COLLECTION";
export const My_TARGET_COLLECTION_SETS_REQUEST =
  "My_TARGET_COLLECTION_SETS_REQUEST";
export const My_TARGET_COLLECTION_SETS_ERROR =
  "My_TARGET_COLLECTION_SETS_ERROR";
export const My_TARGET_COLLECTION_SETS_SUCCESS =
  "My_TARGET_COLLECTION_SETS_SUCCESS";

export const UPDATA_TARGET_COLLECTION_SUCCESS =
  "UPDATA_TARGET_COLLECTION_SUCCESS";
