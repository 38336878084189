import { toast } from "react-hot-toast";
import * as constants from "./constants";
const initialState = {
  user: {},
  loading: false,
  error: null,
  isAuth: false,
  isAdmin: false,
  isCollector: false,
  uid: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SIGN_IN_REQUEST:
    case constants.SIGN_UP_REQUEST:
    case constants.GET_USER_REQUEST:
    case constants.AUTH_LISTENER_REQUEST:
    case constants.UPDATE_USER_REQUEST:
    case constants.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.SIGN_IN_SUCCESS:
    case constants.SIGN_UP_SUCCESS:
    case constants.GET_USER_SUCCESS:
    case constants.AUTH_LISTENER_SUCCESS:
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuth: true,
        isAdmin: action.payload.role === "admin",
        isCollector: action.payload.role === "collector",
        uid: action.payload.uid,
      };

    case constants.SIGN_IN_FAILURE:
    case constants.SIGN_UP_FAILURE:
    case constants.GET_USER_FAILURE:
    case constants.AUTH_LISTENER_FAILURE:
    case constants.UPDATE_USER_FAILURE:
    case constants.DELETE_ACCOUNT_FAILURE:
      toast.error(action.payload.message);
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuth: false,
        isAdmin: false,
        uid: null,
      };
    case constants.SIGN_OUT:
    case constants.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: {},
        isAuth: false,
        isAdmin: false,
        isCollector: false,
        uid: null,
      };
    default:
      return state;
  }
};

export default userReducer;
